import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import FilterdData from "../FilteredData";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const FilterByStatus = forwardRef(
  ({ data, filterMenu, setFilterMenu }, ref) => {
    const [allMemberData, setAllMemberData] = useState([]);
    const [mileStones, setMileStones] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showFilterTasks, setShowFilterTasks] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const milestoneRef = useRef(null);
    const assigneeRef = useRef(null);
    const taskStatusRef = useRef(null);
    const taskFinishedRef = useRef(null);

    const usersData = useSelector((state) => state.auth.userInfoData);
    const dispatch = useDispatch();

    // Handle page change
    const handlePageChange = (event, value) => {
      setCurrentPage(value);
    };

    useEffect(() => {
      if (data && data.project && data.project._id) {
        const listAllMembersData = async () => {
          const responseListData = await dispatch.people.listAllMembersOnly();
          const getMileStone = await dispatch.projectDetails.getMileStoneList({
            project_id: data.project._id,
          });
          setMileStones(getMileStone);
          if (responseListData && responseListData.input) {
            setAllMemberData(responseListData.input);
          }
        };
        listAllMembersData();
      }
    }, [data, filterMenu]);

    const firstOption = usersData
      ? {
          value: usersData._id,
          label: usersData.last_name
            ? `${usersData.first_name} ${usersData.last_name} (You)`
            : null,
        }
      : null;

    const otherOptions = allMemberData
      .filter((users) => users.id !== usersData._id)
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      }));

    const options = firstOption ? [firstOption, ...otherOptions] : otherOptions;
    const status_options = [
      { value: "Todo", label: "Todo" },
      { value: "In Progress", label: "In Progress" },
      { value: "Production", label: "Production" },
      { value: "QA", label: "QA" },
    ];

    useEffect(() => {
      if (currentPage > 1) {
        applyFilter();
      }
    }, [currentPage]);

    //submit filter function
    const applyFilter = async () => {
      setLoading(true);
      setShowFilterTasks(false);
      const formattedFilterMenu = {
        ...filterMenu,
        milestone_id: filterMenu.milestone_id.length
          ? filterMenu.milestone_id.join(",")
          : "",
        task_status: filterMenu.task_status.length
          ? filterMenu.task_status.join(",")
          : "",
        assigned_to: filterMenu.assigned_to.length
          ? filterMenu.assigned_to.join(",")
          : "",
        page: currentPage - 1,
      };
      try {
        const res = await dispatch.projectDetails.getTasksListFilter(
          formattedFilterMenu
        );
        setFilteredData(res);
      } catch (error) {
        console.error("Error fetching filtered tasks:", error);
      } finally {
        setShowFilterTasks(true);
        setLoading(false);
      }
    };

    function getFullName(id) {
      const user = allMemberData.find((user) => user.id === id);
      return user ? { label: id, value: user.fullName } : "";
    }

    const getMileStoneName = (id) => {
      const milestone = mileStones.find((key) => key._id === id);
      return milestone ? { label: id, value: milestone.title } : "";
    };

    const handleClearAll = () => {
      milestoneRef.current.clearValue();
      assigneeRef.current.clearValue();
      taskStatusRef.current.clearValue();
      document.getElementById("taskFinishedRef").checked = false;
      setFilterMenu({
        milestone_id: [],
        milestone_name: [],
        assigned_to: [],
        assigned_to_name: [],
        task_status: [],
        is_task_finished: false,
        task_due_date: "",
      });
      setShowFilterTasks(false);
    };

    useImperativeHandle(ref, () => ({
      handleClearAll,
    }));

    return (
      <div className="">
        <div className="grid grid-cols-2 gap-x-10 gap-y-3 items-center justify-center">
          <div>
            <div className="flex justify-between"></div>
            <div className="">
              <label className="text-xs font-semibold">Milestone</label>
              <Select
                placeholder="Select Milestone"
                isMulti
                ref={milestoneRef}
                className="w-full text-xs"
                onChange={(options) =>
                  !options
                    ? setFilterMenu({
                        ...filterMenu,
                        milestone_id: [],
                        milestone_name: [],
                      })
                    : setFilterMenu({
                        ...filterMenu,
                        milestone_id: options.map((user) => {
                          return user.value;
                        }),
                        milestone_name: options.map((user) => {
                          return getMileStoneName(user.value);
                        }),
                      })
                }
                defaultValue={
                  filterMenu?.milestone_name &&
                  filterMenu?.milestone_name.map((item) => ({
                    value: item.label,
                    label: item.value,
                  }))
                }
                isClearable={true}
                options={mileStones.map((item, text) => {
                  return {
                    value: item._id,
                    label: item.title,
                  };
                })}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                }}
              />
            </div>
          </div>
          <div className="">
            <label className="text-xs font-semibold">Assign To</label>
            <div className="">
              <Select
                placeholder="Select assignee"
                isMulti
                ref={assigneeRef}
                className="w-full text-xs"
                defaultValue={
                  filterMenu?.assigned_to_name &&
                  filterMenu?.assigned_to_name?.map((item) => ({
                    value: item.label,
                    label: item.value,
                  }))
                }
                options={options}
                onChange={(e) =>
                  setFilterMenu({
                    ...filterMenu,
                    assigned_to: e.map((user) => {
                      return user.value;
                    }),
                    assigned_to_name: e.map((user) => {
                      return getFullName(user.value);
                    }),
                  })
                }
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                }}
              />
            </div>
          </div>

          <div className="">
            <label className="text-xs font-semibold">Status</label>
            <div className="mt-1 mb-3">
              <Select
                placeholder="Select Status"
                isMulti
                ref={taskStatusRef}
                className="w-full text-xs"
                onChange={(e) =>
                  setFilterMenu({
                    ...filterMenu,
                    task_status: e.map((user) => {
                      return user.value;
                    }),
                  })
                }
                defaultValue={
                  filterMenu?.task_status &&
                  filterMenu?.task_status?.map((item) => ({
                    value: item,
                    label: item,
                  }))
                }
                options={status_options.map((option, text) => {
                  return {
                    value: option.value,
                    label: option.label,
                  };
                })}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                }}
              />
            </div>
          </div>

          <div className="">
            <label className="text-xs font-semibold">Due Date</label>
            <div className="border border-[#cccccc] relative rounded-[4px] mt-1 mb-3">
              <div className="flex items-center absolute top-1 ml-3 border-r-2 border-gray-400 pr-5">
                <input
                  id="taskFinishedRef"
                  className="text-sm outline-none checkmark w-4"
                  type="checkbox"
                  defaultChecked={
                    filterMenu.is_task_finished && filterMenu.is_task_finished
                  }
                  onChange={(e) =>
                    setFilterMenu({
                      ...filterMenu,
                      is_task_finished: e.target.checked,
                    })
                  }
                  disabled={filterMenu.task_due_date}
                />
              </div>

              <div className="flex items-center rounded-md ml-16">
                <input
                  className="p-2 w-full text-xs outline-none"
                  type="date"
                  onChange={(e) =>
                    setFilterMenu({
                      ...filterMenu,
                      task_due_date: e.target.value,
                    })
                  }
                  defaultValue={
                    filterMenu.task_due_date ? filterMenu.task_due_date : null
                  }
                  placeholder="Select due date"
                  disabled={filterMenu.is_task_finished}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={applyFilter}
          className={`mt-3 w-28 text-center borderborder-primary-navyBlue bg-primary-navyBlue rounded-md text-tiny font-semibold text-white py-3 px-5 flex items-center justify-center ${
            loading ? "opacity-40" : ""
          }`}
        >
          Apply
        </button>
        <div className="mt-5 ml-2">
          {loading ? (
            <div className="flex justify-center items-center w-full h-20">
              <div className="w-7 h-7 border-2 border-t-transparent border-blue-800 rounded-full animate-spin" />
            </div>
          ) : (
            showFilterTasks &&
            (filteredData?.data?.length > 0 ? (
              filteredData?.data?.map((item) => (
                <div key={item._id}>
                  <FilterdData item={item} setFilteredData={setFilteredData} />
                </div>
              ))
            ) : (
              <p className="text-xs font-bold">No Result Found</p>
            ))
          )}

          {/* Pagination */}
          {showFilterTasks && filteredData?.count > 9 && (
            <div className="w-full flex items-center justify-center mt-10">
              <Stack spacing={2} className="mt-10 flex justify-center">
                <Pagination
                  count={Math.ceil(filteredData.count / 10)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default FilterByStatus;

import React, { useEffect, useState } from "react";
import "./tasks.css";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { sassTrue } from "sass";

function AddTask({
  data,
  taskData,
  setTaskData,
  addTaskCreation,
  getTasksListData,
  isAddTaskShown,
  setAddTaskIsShown,
  milestoneId,
  mileStoneName,
  currentMilestone,
  status,
  onRender,
  setOnRender,
  renderer,
  setRenderer,
}) {
  const [allMemberData, setAllMemberData] = useState([]);
  const [mileStones, setMileStones] = useState([]);
  const usersData = useSelector((state) => state.auth.userInfoData);
  const [estimatedTime, setEstimatedTime] = useState({
    hours: 0,
    minutes: 0,
  });
  const [statusOptions, setStatusOptions] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const statusResponse = await dispatch.trashandStatus.getStatusData();
      const transformedStatuses = statusResponse.data.map((status) => ({
        value: status._id,
        label: status.task_status,
      }));
      setStatusOptions(transformedStatuses);
    };
    fetchData();
  }, [isAddTaskShown]);

  useEffect(() => {
    if (data && data.project && data.project._id) {
      const listAllMembersData = async () => {
        const responseListData = await dispatch.people.listAllMembersOnly();
        const getMileStone = await dispatch.projectDetails.getMileStoneList({
          project_id: data.project._id,
        });
        setMileStones(getMileStone);
        if (responseListData && responseListData.input) {
          setAllMemberData(responseListData.input);
        }
      };
      listAllMembersData();
    }
  }, [data, taskData]);

  const userFirstOption = usersData
    ? {
        value: usersData._id,
        label: usersData.last_name
          ? `${usersData.first_name} ${usersData.last_name} (You)`
          : null,
      }
    : null;

  const userotherOptions = allMemberData
    .filter((users) => users.id !== usersData._id)
    .map((user) => ({
      value: user.id,
      label: user.fullName,
    }));

  const userOptions = userFirstOption
    ? [userFirstOption, ...userotherOptions]
    : userotherOptions;

  function handleStatusChange(e) {
    setAddTasks({ ...addTasks, task_status: e.value });
  }

  const priority_options = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const [addTasks, setAddTasks] = useState({
    task_name: "",
    task_description: "",
    assigned_by: usersData?._id,
    assigned_to: [],
    milestone_id: null,
    task_status: null,
    task_priority: null,
    task_start_date: "",
    task_end_date: "",
  });
  useEffect(() => {
    if (milestoneId) {
      setAddTasks({ ...addTasks, milestone_id: milestoneId });
    }
    if (status === "board" && currentMilestone) {
      setAddTasks({ ...addTasks, milestone_id: currentMilestone });
    }
  }, [currentMilestone, milestoneId, status]);

  const submitTask = async () => {
    setIsLoading(true);
    try {
      const responseData = await addTaskCreation({
        project_id: data.project._id,
        estimate: `${estimatedTime.hours}:${estimatedTime.minutes}`,
        ...addTasks,
      });

      if (responseData && responseData.status === 200) {
        toast.success("Task Created successfully");
        handleClose();
        if (mileStoneName) {
          setRenderer(!renderer);
        } else {
          setOnRender(!onRender);
        }
      } else {
        toast.error(responseData.message);
      }

      setAddTaskIsShown(false);
      setAddTasks({
        task_name: "",
        task_description: "",
        assigned_by: usersData?._id,
        assigned_to: [],
        milestone_id: null,
        task_status: null,
        task_priority: null,
        task_start_date: "",
        task_end_date: "",
      });
      setEstimatedTime({
        hours: 0,
        minutes: 0,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value.trim();
    if (value === "" || isNaN(parseInt(value, 10))) {
      value = "0";
    }

    value = parseInt(value, 10);

    if (fieldName === "hours") {
      value = Math.min(Math.max(value, 0), 23);
    } else if (fieldName === "minutes") {
      value = Math.min(Math.max(value, 0), 59);
    }

    setEstimatedTime((prevState) => ({
      ...prevState,
      [fieldName]: value.toString(),
    }));
  };

  const handleHideAddTask = () => {
    setAddTasks({
      task_name: "",
      task_description: "",
      assigned_by: usersData?._id,
      assigned_to: [],
      milestone_id: null,
      task_status: null,
      task_priority: null,
      task_start_date: "",
      task_end_date: "",
    });
    setEstimatedTime({
      hours: 0,
      minutes: 0,
    });

    // setEstimatedTime({hours:"0",minutes:"0"})
    // setAddTaskIsShown(false);
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isAddTaskShown) {
      setIsVisible(true);
    }
  }, [isAddTaskShown]);

  const handleClose = () => {
    setIsVisible(false);
    handleHideAddTask();

    setTimeout(() => {
      setAddTaskIsShown(false);
    }, 1000);
  };

  return (
    <div>
      <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      <div
        className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-300 text-gray-700 top-0 right-0 border-none w-96 z-50 ${
          isVisible
            ? "translate-x-0"
            : "opacity-0 pointer-events-none translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between p-5 pl-6">
          <h5 className="mb-0 leading-normal font-semibold">Create Tasks</h5>
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleClose()}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <hr className="w-full" />

        <div className="flex-grow p-4 pt-2 pl-6  overflow-y-auto">
          <label className="text-sm font-semibold">Title</label>
          <div className="flex rounded-md border mb-4 mt-1">
            <div className="p-1 pr-0">
              <path
                d="M12.8125 0.531189C13.1231 0.531189 13.375 0.783031 13.375 1.09369V6.5963C13.9721 5.97281 14.7605 5.59369 15.625 5.59369C17.489 5.59369 19 7.35656 19 9.53119C19 11.7058 17.489 13.4687 15.625 13.4687C14.7605 13.4687 13.9721 13.0896 13.375 12.4661V12.9062C13.375 13.2168 13.1231 13.4687 12.8125 13.4687C12.5019 13.4687 12.25 13.2168 12.25 12.9062V1.09369C12.25 0.783031 12.5019 0.531189 12.8125 0.531189ZM15.625 12.3437C16.7112 12.3437 17.875 11.2535 17.875 9.53119C17.875 7.80894 16.7112 6.71869 15.625 6.71869C14.5388 6.71869 13.375 7.80894 13.375 9.53119C13.375 11.2535 14.5388 12.3437 15.625 12.3437ZM6.57614 0.908897C6.49844 0.685472 6.28924 0.534553 6.05272 0.531245C5.81619 0.527938 5.60287 0.672973 5.51896 0.894136L2.45509 8.96869H2.43209V9.02933L1.03674 12.7066C0.926526 12.9971 1.07264 13.3219 1.36309 13.4321C1.65355 13.5423 1.97836 13.3962 2.08856 13.1058L3.23148 10.0937H8.57974L9.62228 13.091C9.72434 13.3844 10.0449 13.5396 10.3384 13.4374C10.6318 13.3354 10.7869 13.0149 10.6848 12.7214L6.57614 0.908897ZM3.65835 8.96869L6.02188 2.73982L8.18845 8.96869H3.65835Z"
                fill="#98A2B2"
                stroke="#98A2B2"
                strokeWidth="0.6"
              />
            </div>

            <input
              className="p-2 w-full text-xs outline-none "
              type="text"
              value={addTasks.task_name}
              onChange={(e) =>
                setAddTasks({ ...addTasks, task_name: e.target.value })
              }
              placeholder="Enter Title"
            />
          </div>

          <label className="text-sm font-semibold">Description</label>
          {/* <div className="mb-5 h-60 common-editor   rounded-xl relative ">
              <ReactQuill
                className="font-xl h-full bg-white rounded-xl border border-secondary-grey-400"
                theme="snow"
                too
                value={addTasks.task_description}
                onChange={(e) =>
                  setAddTasks({ ...addTasks, task_description: e })
                }
              />
            </div> */}
          <div className="flex items-start rounded-md border mb-4 mt-1">
            {/* <div className="p-1 pr-0">
              <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >



                
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3ZM2 7H13C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8C14 8.26522 13.8946 8.51957 13.7071 8.70711C13.5196 8.89464 13.2652 9 13 9H2C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7ZM2 11H8C8.26522 11 8.51957 11.1054 8.70711 11.2929C8.89464 11.4804 9 11.7348 9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11Z"
                      fill="#98A2B2"
                    />
                  </svg>
            </div> */}

            <textarea
              className="p-2 w-full text-xs outline-none"
              type="text"
              value={addTasks.task_description}
              onChange={(e) =>
                setAddTasks({ ...addTasks, task_description: e.target.value })
              }
              placeholder="Enter Description"
            />
          </div>

          <div className="flex justify-between space-x-3 mb-4 mt-4">
            <div className="flex flex-col w-[50%] ">
              <label className="text-sm font-semibold">Assignee</label>
              <div className="mt-1">
                <div className="">
                  <Select
                    placeholder={
                      <div className="flex gap-2 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <path
                            d="M14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27Z"
                            fill="#98A2B2"
                            stroke="#98A2B2"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0046 18.3333C16.9961 18.3333 19.4212 15.9082 19.4212 12.9167C19.4212 9.92512 16.9961 7.5 14.0046 7.5C11.013 7.5 8.58789 9.92512 8.58789 12.9167C8.58789 15.9082 11.013 18.3333 14.0046 18.3333Z"
                            fill="white"
                          />
                          <path
                            d="M14 18.5C9.6 18.5 6.5 21.3333 5.5 23C6 23.5 9.5 26.5 14 26.5C17.5 26.5 21.6667 24.1667 22.5 23C21.5 21.3333 18.4 18.5 14 18.5Z"
                            fill="white"
                          />
                        </svg>

                        <p className="py-[.5rem]">Unassigned</p>
                      </div>
                    }
                    isMulti
                    className="w-full text-xs"
                    options={userOptions}
                    onChange={(e) =>
                      setAddTasks({
                        ...addTasks,
                        assigned_to: e.map((user) => {
                          return user.value;
                        }),
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold">Status</label>
              <div className="mt-1">
                <Select
                  placeholder={
                    <div className="flex gap-2 items-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.3034 1.12229C10.8097 0.503542 9.16594 0.34165 7.58014 0.657084C5.99435 0.972518 4.5377 1.75111 3.39441 2.89441C2.25111 4.0377 1.47252 5.49435 1.15708 7.08014C0.84165 8.66594 1.00354 10.3097 1.62229 11.8034C2.24103 13.2972 3.28885 14.574 4.63322 15.4723C5.97759 16.3705 7.55814 16.85 9.175 16.85H9.475V14.9375H9.175C7.9364 14.9375 6.72561 14.5702 5.69575 13.8821C4.66588 13.194 3.8632 12.2159 3.38921 11.0716C2.91521 9.92724 2.7912 8.66806 3.03284 7.45325C3.27448 6.23845 3.87092 5.12257 4.74675 4.24675C5.62257 3.37092 6.73845 2.77448 7.95325 2.53284C9.16806 2.2912 10.4272 2.41521 11.5716 2.88921C12.7159 3.3632 13.694 4.16588 14.3821 5.19575C15.0702 6.22561 15.4375 7.4364 15.4375 8.675V8.975H17.35V8.675C17.35 7.05814 16.8705 5.47759 15.9723 4.13322C15.074 2.78885 13.7972 1.74103 12.3034 1.12229ZM12.8805 6.97531L11.5309 5.62574L7.8625 9.29417L6.16281 7.59449L4.81324 8.94406L7.8625 11.9933L12.8805 6.97531ZM17.3591 11.3226C16.5848 11.0018 15.7328 10.9179 14.9108 11.0814C14.0888 11.2449 13.3338 11.6485 12.7411 12.2411C12.1485 12.8338 11.7449 13.5888 11.5814 14.4108C11.4179 15.2328 11.5018 16.0848 11.8226 16.8591C12.1433 17.6334 12.6864 18.2952 13.3833 18.7609C14.0801 19.2265 14.8997 19.475 15.7378 19.475C16.8612 19.4737 17.9382 19.0269 18.7326 18.2326C19.5269 17.4382 19.9737 16.3609 19.975 15.2375C19.975 14.3994 19.7265 13.5801 19.2609 12.8833C18.7952 12.1864 18.1334 11.6433 17.3591 11.3226ZM14.4458 13.3043C14.8281 13.0489 15.2774 12.9126 15.7372 12.9125C16.3537 12.9132 16.9447 13.1584 17.3807 13.5943C17.8166 14.0303 18.0618 14.6213 18.0625 15.2378C18.0624 15.6976 17.9261 16.1469 17.6707 16.5292C17.4152 16.9115 17.0521 17.2095 16.6272 17.3855C16.2024 17.5615 15.7349 17.6075 15.2839 17.5178C14.8329 17.4281 14.4186 17.2067 14.0935 16.8815C13.7683 16.5564 13.5469 16.1421 13.4572 15.6911C13.3675 15.2401 13.4135 14.7726 13.5895 14.3478C13.7655 13.9229 14.0635 13.5598 14.4458 13.3043Z"
                            fill="#98A2B2"
                          />
                        </svg>
                      </div>
                      <p className="text-gray-500">Todo</p>
                    </div>
                  }
                  className="w-full text-xs"
                  onChange={handleStatusChange}
                  options={statusOptions}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between space-x-3 mb-4">
            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold">Priority</label>
              <div className="mt-1">
                <Select
                  placeholder={
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M3.17292 4.30943L5.83034 1.65201L8.48776 4.30943C8.75487 4.57654 9.18636 4.57654 9.45347 4.30943C9.72058 4.04232 9.72058 3.61083 9.45347 3.34372L6.30977 0.200013C6.04266 -0.0670986 5.61117 -0.0670986 5.34406 0.200013L2.20035 3.34372C1.93324 3.61083 1.93324 4.04232 2.20035 4.30943C2.46747 4.56969 2.9058 4.57654 3.17292 4.30943Z"
                          fill="#98A2B2"
                        />
                        <path
                          d="M5.09787 0.432617H6.53295V9.2823C6.53295 9.67859 6.2117 9.99985 5.81541 9.99985C5.41912 9.99985 5.09787 9.67859 5.09787 9.2823V0.432617Z"
                          fill="#98A2B2"
                        />
                        <path
                          d="M8.17292 11.6906L10.8303 14.348L13.4878 11.6906C13.7549 11.4235 14.1864 11.4235 14.4535 11.6906C14.7206 11.9577 14.7206 12.3892 14.4535 12.6563L11.3098 15.8C11.0427 16.0671 10.6112 16.0671 10.3441 15.8L7.20035 12.6563C6.93324 12.3892 6.93324 11.9577 7.20035 11.6906C7.46747 11.4303 7.9058 11.4235 8.17292 11.6906Z"
                          fill="#98A2B2"
                        />
                        <path
                          d="M10.0979 15.5674H11.533V6.7177C11.533 6.32141 11.2117 6.00015 10.8154 6.00015C10.4191 6.00015 10.0979 6.32141 10.0979 6.7177V15.5674Z"
                          fill="#98A2B2"
                        />
                      </svg>

                      <p>Select Priority</p>
                    </div>
                  }
                  className="w-full text-xs"
                  onChange={(e) =>
                    setAddTasks({ ...addTasks, task_priority: e.value })
                  }
                  options={priority_options.map((option, text) => {
                    return {
                      value: option.value,
                      label: option.label,
                    };
                  })}
                />
              </div>
            </div>

            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold">Milestone</label>
              <div className="mt-1">
                {mileStoneName != null ? (
                  <Select
                    placeholder="Select Milestone"
                    className="w-full text-xs"
                    onChange={(e) =>
                      setAddTasks({ ...addTasks, milestone_id: e.value })
                    }
                    value={
                      milestoneId && mileStoneName
                        ? {
                            value: milestoneId,
                            label: mileStoneName,
                          }
                        : null
                    }
                    options={[
                      {
                        value: milestoneId,
                        label: mileStoneName,
                      },
                    ]}
                  />
                ) : mileStones?.length > 0 ? (
                  <Select
                    placeholder={
                      <div className="flex gap-2 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M3.17292 4.30943L5.83034 1.65201L8.48776 4.30943C8.75487 4.57654 9.18636 4.57654 9.45347 4.30943C9.72058 4.04232 9.72058 3.61083 9.45347 3.34372L6.30977 0.200013C6.04266 -0.0670986 5.61117 -0.0670986 5.34406 0.200013L2.20035 3.34372C1.93324 3.61083 1.93324 4.04232 2.20035 4.30943C2.46747 4.56969 2.9058 4.57654 3.17292 4.30943Z"
                            fill="#98A2B2"
                          />
                          <path
                            d="M5.09787 0.432617H6.53295V9.2823C6.53295 9.67859 6.2117 9.99985 5.81541 9.99985C5.41912 9.99985 5.09787 9.67859 5.09787 9.2823V0.432617Z"
                            fill="#98A2B2"
                          />
                          <path
                            d="M8.17292 11.6906L10.8303 14.348L13.4878 11.6906C13.7549 11.4235 14.1864 11.4235 14.4535 11.6906C14.7206 11.9577 14.7206 12.3892 14.4535 12.6563L11.3098 15.8C11.0427 16.0671 10.6112 16.0671 10.3441 15.8L7.20035 12.6563C6.93324 12.3892 6.93324 11.9577 7.20035 11.6906C7.46747 11.4303 7.9058 11.4235 8.17292 11.6906Z"
                            fill="#98A2B2"
                          />
                          <path
                            d="M10.0979 15.5674H11.533V6.7177C11.533 6.32141 11.2117 6.00015 10.8154 6.00015C10.4191 6.00015 10.0979 6.32141 10.0979 6.7177V15.5674Z"
                            fill="#98A2B2"
                          />
                        </svg>
                        <p>Select Milestone</p>
                      </div>
                    }
                    className="w-full text-xs"
                    onChange={(e) => {
                      setAddTasks({ ...addTasks, milestone_id: e.value });
                    }}
                    // value={
                    //   status === "board" && currentMilestone
                    //     ? {
                    //         value: currentMilestone,
                    //         label: mileStones.find(
                    //           (item) => item._id === currentMilestone
                    //         ).title,
                    //       }
                    //     : null
                    // }
                    options={[
                      { value: null, label: "None" },
                      ...mileStones.map((item) => {
                        return {
                          value: item._id,
                          label: item.title,
                        };
                      }),
                    ]}
                  />
                ) : (
                  <Select
                    className="w-full text-xs"
                    placeholder="---- No Milestone to select ----"
                    isDisabled={true}
                  />
                )}
              </div>
            </div>
          </div>

          {/* <div className="flex justify-between mb-4">
                <div className="flex flex-col w-[55%]">
                  <label className="text-sm font-semibold mb-2">Status</label>

                  <div>
                    {status_options.map(option => (
                      <>
                        <input type="radio" className="cursor-pointer mr-1 h-[10px]" onChange={handleStatusChange}
                          key={option.value} id={option.value} name="status" value={option.value} />
                        <label className="text-sm mr-2">{option.label}</label>
                      </>

                    ))}
                  </div>

                </div>

                <div className="flex flex-col w-[45%]">
                  <label className="text-sm font-semibold mb-2">Priority</label>

                  <div>
                    {priority_options.map(option => (

                      <>
                        <input type="radio" className="cursor-pointer mr-1 h-[10px]" onChange={handlePriorityChange}
                          key={option.value} id={option.value} name="priority" value={option.value} />
                        <label className="text-sm mr-2">{option.label}</label>
                      </>

                    ))}
                  </div>

                </div>
              </div> */}

          <div className="flex justify-between space-x-3 mb-4">
            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold">Start Date</label>
              <div className="flex rounded-md border mt-1">
                <div className="p-3 pr-0">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8 2.4V0.8C12.8 0.587827 12.7157 0.384344 12.5657 0.234315C12.4157 0.0842854 12.2122 0 12 0C11.7878 0 11.5843 0.0842854 11.4343 0.234315C11.2843 0.384344 11.2 0.587827 11.2 0.8V2.4H4.8V0.8C4.8 0.587827 4.71571 0.384344 4.56569 0.234315C4.41566 0.0842854 4.21217 0 4 0C3.78783 0 3.58434 0.0842854 3.43431 0.234315C3.28429 0.384344 3.2 0.587827 3.2 0.8V2.4H0V15.2H16V2.4H12.8ZM14.4 13.6H1.6V4H14.4V13.6ZM5.6 6.4H4V8H5.6V6.4ZM8.8 6.4H7.2V8H8.8V6.4ZM12 6.4H10.4V8H12V6.4ZM5.6 9.6H4V11.2H5.6V9.6ZM8.8 9.6H7.2V11.2H8.8V9.6ZM12 9.6H10.4V11.2H12V9.6Z"
                      fill="#98A2B2"
                    />
                  </svg>
                </div>

                <input
                  className="p-2 w-full text-xs outline-none "
                  type="date"
                  placeholder="Start Date"
                  value={addTasks.task_start_date}
                  onChange={(e) =>
                    setAddTasks({
                      ...addTasks,
                      task_start_date: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold">Due Date</label>
              <div className="flex rounded-md border mt-1">
                <div className="p-3 pr-0">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8 2.4V0.8C12.8 0.587827 12.7157 0.384344 12.5657 0.234315C12.4157 0.0842854 12.2122 0 12 0C11.7878 0 11.5843 0.0842854 11.4343 0.234315C11.2843 0.384344 11.2 0.587827 11.2 0.8V2.4H4.8V0.8C4.8 0.587827 4.71571 0.384344 4.56569 0.234315C4.41566 0.0842854 4.21217 0 4 0C3.78783 0 3.58434 0.0842854 3.43431 0.234315C3.28429 0.384344 3.2 0.587827 3.2 0.8V2.4H0V15.2H16V2.4H12.8ZM14.4 13.6H1.6V4H14.4V13.6ZM5.6 6.4H4V8H5.6V6.4ZM8.8 6.4H7.2V8H8.8V6.4ZM12 6.4H10.4V8H12V6.4ZM5.6 9.6H4V11.2H5.6V9.6ZM8.8 9.6H7.2V11.2H8.8V9.6ZM12 9.6H10.4V11.2H12V9.6Z"
                      fill="#98A2B2"
                    />
                  </svg>
                </div>

                <input
                  className="p-2 w-full text-xs outline-none "
                  type="date"
                  placeholder="Select Due Date"
                  value={addTasks.task_end_date}
                  onChange={(e) =>
                    setAddTasks({
                      ...addTasks,
                      task_end_date: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[50%] mb-4">
            <label className="text-sm font-semibold mb-2">Estimated Time</label>
            <div className="flex space-x-3">
              <div className="w-20">
                {
                  // timeLogData.time_spent.time >= 0&&
                  <>
                    <input
                      className="h-10 text-center w-20 outline-none border rounded-md text-xs mr-3"
                      type="number"
                      min="1"
                      max="23"
                      maxLength="2"
                      value={estimatedTime.hours}
                      onChange={(e) => handleInputChange(e, "hours")}
                    />

                    {/* {/ {timeLogData.time_spent.hours !== "" && ( /}
                        <p className="text-center text-xs">Hours</p>
                        {/ )} /} */}
                  </>
                }
              </div>
              <div className="w-20">
                {
                  // timeLogData.time_spent.time >= 0&&
                  <>
                    <input
                      className="h-10 text-center w-20 outline-none border rounded-md text-xs mr-3"
                      type="number"
                      min="1"
                      max="12"
                      maxLength="2"
                      // placeholder="Minutes"
                      value={estimatedTime.minutes}
                      onChange={(e) => handleInputChange(e, "minutes")}
                    />
                    {/* {/ {timeLogData.time_spent.minutes !== "" && ( /}
                        <p className="text-center text-xs">Minutes</p>
                        {/ )} /} */}
                  </>
                }
              </div>
            </div>
            {/* {timeLogData.time_spent.time < 0 || checkEndTime === 24 ? (
              <p className="text-gray-400 text-xs">
                Please check the time you have entered
              </p>
            ) : null} */}
          </div>
          <button
            onClick={() => {
              submitTask();
            }}
            disabled={!addTasks.task_name || addTasks.assigned_to.length === 0}
            className={`border border-[#057D54] bg-[#057D54]  rounded-md text-tiny font-semibold text-white py-2 px-5 flex gap-2 items-center ${
              !addTasks.task_name || addTasks.assigned_to.length === 0
                ? "opacity-35"
                : ""
            }`}
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                fill="#ffffff"
              />
            </svg>
            {loading ? "Loading..." : "Create Task"}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapState = (state) => ({
  addTasksResponseData: state.projectDetails.tasksList,
});
const mapDispatch = (dispatch) => ({
  addTaskCreation: dispatch.projectDetails.projectAddTaskCreation,
  getTasksListData: dispatch.projectDetails.getTasksList,
});
export default connect(mapState, mapDispatch)(AddTask);

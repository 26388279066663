import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./tasks.css";
import { toast } from "react-toastify";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function minutesToTime(minutes) {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  return `${hours}:${mins.toString().padStart(2, "0")}`;
}

function timeToMinutes(time) {
  let [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

function TaskListOptions({
  correspondingMilestone,
  setShowEditMilestoneModal,
  setIsMilestoneOptions,
  setRenderer,
  renderer,
  correspondingDetail,
}) {
  const dispatch = useDispatch();
  const [totalTimes, setTotalTimes] = useState({
    totalEstimate: "0:00",
    totalLoggedTime: "0:00",
  });

  const milestoneCount = useSelector(
    (state) => state.projectDetails.mileStoneList.data.milestoneGroupCounts
  );
  const [editMilestone, setEditMilestone] = useState({
    title: correspondingMilestone?.title ? correspondingMilestone.title : "",
  });

  const [isVisible, setIsVisible] = useState(false);

  const updateMilestone = async () => {
    setIsLoading(true);
    try {
      const responseData =
        await dispatch.projectDetails.projectMilestoneUpdataion({
          project_id: correspondingMilestone.project_id,
          milestone_id: correspondingMilestone._id,
          ...editMilestone,
        });

      if (responseData && responseData.success) {
        toast.success("Task updated successfully");
        setRenderer(!renderer);
      } else {
        toast.error(responseData.message);
      }

      setShowEditMilestoneModal(false);
      setIsMilestoneOptions(false);
      // window.location.reload()
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const formattedDate = (dateString) => {
    const date = moment(dateString);
    const today = moment().startOf("day");

    if (date.isSame(today, "day")) {
      return "Today";
    }
    if (date.diff(moment(), "days") <= -2) {
      return date.format("MMMM Do YYYY [at] h:mm A");
    }
    return date.fromNow();
  };

  const lastUpdated =
    correspondingDetail && correspondingDetail.milestone.updatedAt;
  const formattedLastUpdated = lastUpdated
    ? formattedDate(lastUpdated)
    : "No data available";

  useEffect(() => {
    const fetchEstimeLog = () => {
      if (correspondingDetail.tasks.length !== 0) {
        let totalEstimateMinutes = 0;
        let totalLoggedMinutes = 0;

        correspondingDetail.tasks.forEach((task) => {
          totalEstimateMinutes += timeToMinutes(task.estimate);
          totalLoggedMinutes += timeToMinutes(task.task_logged_time);
        });

        setTotalTimes({
          totalEstimate: minutesToTime(totalEstimateMinutes),
          totalLoggedTime: minutesToTime(totalLoggedMinutes),
        });
      }
    };
    fetchEstimeLog();
  }, [correspondingDetail]);

  useEffect(() => {
    if (correspondingDetail) {
      setIsVisible(true);
    }
  }, [correspondingDetail]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShowEditMilestoneModal(false);
      setIsMilestoneOptions(false);
    }, 1000);
  };
  const [loading, setIsLoading] = useState(false);
  return (
    <>
      <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      {
        <div
          className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-500 text-gray-700 top-0 right-0 border-none w-[50rem] z-50 ${
            isVisible ? "translate-x-0" : "pointer-events-none translate-x-full"
          }`}
        >
          <div className="flex items-center justify-between p-5 pl-6">
            <h5 className="mb-0 leading-normal font-semibold text-base">
              Update Milestone
            </h5>

            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  handleClose();
                  // setShowEditMilestoneModal(false);
                  // setIsMilestoneOptions(false);
                }}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <hr className="w-full" />

          <div className="mt-1 flex-grow p-4 pt-2 pl-6 overflow-y-auto">
            <label className="text-sm font-medium text-[#000]">Title</label>
            <div className="mt-1 flex rounded-md border mb-4">
              <input
                className="p-2 w-full text-xs outline-none py-2 "
                type="text"
                value={editMilestone.title}
                onChange={(e) =>
                  setEditMilestone({
                    ...editMilestone,
                    title: e.target.value,
                  })
                }
                placeholder="Enter Title"
              />
            </div>

            <label className="text-sm font-medium text-[#000]">
              Description
            </label>
            <div className="mb-5 rounded-xl relative border mt-1 ">
              <textarea
                className="p-2 w-full text-xs outline-none"
                type="text"
                placeholder="Enter Description"
              />
            </div>

            <div className="justify-between space-x-3 mb-8">
              <div className="flex flex-col">
                <label className="text-sm font-medium mb-4 text-[#000]">
                  Overview
                </label>
                <div>
                  <div className="grid grid-cols-5">
                    <div className="flex items-center gap-1">
                      <label className="text-sm text-[#454545] font-normal px-8 py-2 bg-[#F3F3F3] rounded-lg">
                        Groups
                        <div className="text-center p-2">
                          <label className="text-3xl font-semibold text-[#5F5F5F]">
                            {correspondingDetail.groups.length}
                          </label>
                        </div>
                      </label>
                    </div>

                    <div className="flex items-center gap-1">
                      <label className="text-sm text-[#454545] font-normal px-10 py-2 bg-[#F3F3F3] rounded-lg">
                        Tasks
                        <div className="text-center p-2">
                          <label className="text-3xl font-semibold text-[#5F5F5F]">
                            {correspondingDetail.tasks.length}
                          </label>
                        </div>
                      </label>
                    </div>

                    <div className="flex items-center gap-1 col-span-2 ml-2">
                      <label className="text-sm text-[#454545] font-normal px-5 py-2 bg-[#F3F3F3] rounded-lg">
                        Estimated Time
                        <div className="text-center py-4 ">
                          <label className="text-md font-semibold text-[#5F5F5F] flex gap-1 items-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 14 15"
                              fill="none"
                            >
                              <path
                                d="M7 14.8652C5.14348 14.8652 3.36301 14.1277 2.05025 12.815C0.737498 11.5022 0 9.72175 0 7.86523C0 6.00872 0.737498 4.22824 2.05025 2.91549C3.36301 1.60273 5.14348 0.865234 7 0.865234C8.85651 0.865234 10.637 1.60273 11.9497 2.91549C13.2625 4.22824 14 6.00872 14 7.86523C14 9.72175 13.2625 11.5022 11.9497 12.815C10.637 14.1277 8.85651 14.8652 7 14.8652ZM7 13.4652C8.48521 13.4652 9.90959 12.8752 10.9598 11.825C12.01 10.7748 12.6 9.35045 12.6 7.86523C12.6 6.38002 12.01 4.95564 10.9598 3.90544C9.90959 2.85523 8.48521 2.26523 7 2.26523C5.51479 2.26523 4.09041 2.85523 3.0402 3.90544C1.99 4.95564 1.4 6.38002 1.4 7.86523C1.4 9.35045 1.99 10.7748 3.0402 11.825C4.09041 12.8752 5.51479 13.4652 7 13.4652ZM6.3 8.15223V3.66523H7.7V7.57823L10.465 10.3432L9.478 11.3302L6.3 8.15223Z"
                                fill="#606C80"
                              />
                            </svg>
                            {totalTimes.totalLoggedTime}h/
                            {totalTimes.totalEstimate}h
                          </label>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 mt-6">
                    <label className="text-sm text-gray-400 font-medium flex gap-1 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                      >
                        <path
                          d="M11.5782 7.10564C11.172 9.14133 9.6404 11.0581 7.49134 11.4864C6.44321 11.6956 5.35594 11.5681 4.38434 11.122C3.41275 10.6758 2.60636 9.93389 2.08001 9.00175C1.55365 8.06962 1.33415 6.99481 1.45277 5.93038C1.57139 4.86595 2.02207 3.86615 2.74065 3.07335C4.21453 1.44643 6.70322 0.998578 8.73447 1.81285"
                          stroke="#057D54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.67188 6.2903L6.70312 8.32599L11.5781 3.0332"
                          stroke="#057D54"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p className="text-xs items-center pl-1 font-light text-[#9A9A9A]">
                        Last Updated
                      </p>
                    </label>
                    <label className="text-xs items-center font-light text-[#9A9A9A]">
                      {formattedLastUpdated}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={updateMilestone}
              disabled={loading}
              className={`p-3 px-5  ${
                loading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "border border-[#057D54] bg-[#057D54] rounded-md "
              } text-tiny font-semibold text-white py-3 px-3 flex gap-2 items-center `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M10.7574 3.44595C10.9775 3.24478 11.2704 3.13357 11.5743 3.13577C11.8782 3.13798 12.1693 3.25344 12.3862 3.45778C12.6031 3.66212 12.7288 3.93936 12.7369 4.23101C12.7449 4.52265 12.6346 4.80589 12.4292 5.02095L6.19486 12.5059C6.08766 12.6168 5.95827 12.7058 5.81444 12.7675C5.67061 12.8292 5.51528 12.8625 5.35775 12.8653C5.20022 12.8681 5.04372 12.8404 4.8976 12.7838C4.75149 12.7272 4.61876 12.6429 4.50736 12.5359L0.372982 8.56695C0.257846 8.46395 0.165499 8.33975 0.101449 8.20175C0.0373992 8.06376 0.0029586 7.91479 0.000182375 7.76373C-0.00259385 7.61268 0.0263513 7.46263 0.0852904 7.32255C0.144229 7.18247 0.231955 7.05522 0.343235 6.94839C0.454514 6.84156 0.587067 6.75734 0.732986 6.70076C0.878905 6.64418 1.0352 6.61639 1.19255 6.61906C1.3499 6.62172 1.50507 6.65479 1.64882 6.71628C1.79257 6.77776 1.92195 6.86642 2.02923 6.97695L5.30111 10.1164L10.7277 3.47895L10.7574 3.44595Z"
                  fill="white"
                />
              </svg>
              {loading ? "Loading..." : " Update Milestone "}
            </button>
          </div>
        </div>
      }
    </>
  );
}

export default TaskListOptions;

import moment from "moment";
import React, { useState } from "react";
import Loader from "../Utilities/Loader";

function Notes({
  ProjectData,
  RequirementData,
  editingItem,
  notes,
  setEditingItem,
  AddNote,
  EditNote,
  RemoveNote,
  isNoteAdding,
  isNoteUpdating,
}) {
  const [editingNoteId, setEditingNoteId] = useState("");
  const [openDelete, setOpenDelete] = useState("");
  const [noteUpdatingValue, setNoteUpdatingValue] = useState({
    project_id: ProjectData.project._id,
    requirement_id: editingItem._id,
    note_id: "",
    noteTitle: "",
    noteDescription: "",
  });

  const startNoteEditing = (note) => {
    const data = { ...noteUpdatingValue };
    data["note_id"] = note._id;
    data["noteTitle"] = note.title;
    data["noteDescription"] = note.description;
    setEditingNoteId(note._id);
    setNoteUpdatingValue(data);
  };
  const CancelNoteEditing = () => {
    setEditingNoteId("");
    setNoteUpdatingValue({
      project_id: ProjectData.project._id,
      requirement_id: editingItem._id,
      note_id: "",
      noteTitle: "",
      noteDescription: "",
    });
  };
  const setValue = (field, value) => {
    const data = { ...noteUpdatingValue };
    data[field] = value;
    setNoteUpdatingValue(data);
  };
  const UpdateNote = async () => {
    try {
      await EditNote(noteUpdatingValue);
      setEditingNoteId("");
      setNoteUpdatingValue({
        project_id: ProjectData.project._id,
        requirement_id: editingItem._id,
        note_id: "",
        noteTitle: "",
        noteDescription: "",
      });
    } catch (error) {
      throw error;
    }
  };
  const DeleteNote = async (noteID) => {
    try {
      await RemoveNote({
        project_id: ProjectData.project._id,
        requirement_id: editingItem._id,
        note_id: noteID,
      });
      setEditingNoteId("");
      setNoteUpdatingValue({
        project_id: ProjectData.project._id,
        requirement_id: editingItem._id,
        note_id: "",
        noteTitle: "",
        noteDescription: "",
      });
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
      <div className="mb-5 mt-2 p-5 w-full min-h-48 rounded-md flex justify-start align-left items-center flex-col bg-secondary-grey-100">
        <div className="w-full mb-2 flex justify-between items-center">
          <h2 className="w-3/4 text-base text-black font-semibold">Notes</h2>
          <button
            type="button"
            onClick={() => {
              AddNote(editingItem._id);
            }}
            className="group font-title text-[10px] sm:text-xs flex items-center justify-between hover:bg-[#057D54] text-[#057D54]  hover:text-white border-[#057D54]hover:border-transparent transition duration-300 ease-out rounded-md pr-4 pl-4 py-2"
          >
            <svg
              className="hidden sm:block fill-[#057D54] group-hover:fill-white mr-2"
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              width="17"
              height="17"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m11 11h-7.25c-.414 0-.75.336-.75.75s.336.75.75.75h7.25v7.25c0 .414.336.75.75.75s.75-.336.75-.75v-7.25h7.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-7.25v-7.25c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                fillRule="nonzero"
              />
            </svg>
            Add Note
            {isNoteAdding ? <Loader /> : ""}
          </button>
        </div>

        <>
          {notes.length ? (
            notes.map((note) => {
              return editingNoteId !== note._id ? (
                <div
                  key={`Note_${note._id}`}
                  className="w-full flex justify-between rounded-md bg-white border border-gray-200 p-2 mb-2"
                >
                  <div className="w-full flex flex-col lg:flex-col md:flex-col justify-between px-3">
                    <h2 className="w-full text-sm text-black font-normal">
                      {note.title}
                      {moment(note.createdAt).diff(moment(note.updatedAt)) !==
                      0 ? (
                        ""
                      ) : (
                        <span>
                          <small className="ml-2 text-green-300 text-xs italic">
                            new
                          </small>
                        </span>
                      )}
                    </h2>
                  </div>
                  <div className="group relative flex align-center items-center flex-col">
                    <button type="button">
                      <svg
                        className="fill-secondary-grey-300"
                        width="20"
                        height="20"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                      </svg>
                    </button>
                    <div
                      className={`invisible group-hover:visible flex flex-col justify-center absolute top-5 right-0 w-28 rounded bg-white transition duration-300 ease-out drop-shadow-md z-20`}
                    >
                      <button
                        onClick={() => startNoteEditing(note)}
                        className="w-full p-2 flex justify-start transition items-center duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50 text-title text-semibold"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="fill-secondary-grey-300"
                          width="30"
                          height="17"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" />
                        </svg>
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          setOpenDelete(note._id);
                        }}
                        className="w-full p-2 flex justify-start  transition items-center duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                      >
                        <svg
                          width="30"
                          height="17"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path
                            d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z"
                            fill="red"
                          />
                        </svg>
                        Delete
                      </button>
                    </div>
                  </div>
                  {openDelete === note._id && (
                    <div id={`Note_${note._id}`} className="">
                      <div
                        onClick={() => setOpenDelete(false)}
                        className={` ${
                          openDelete === note._id
                            ? "fixed inset-0 bg-black opacity-20 z-20"
                            : "opacity-0 pointer-events-none"
                        }`}
                      />
                      <div
                        className={`fixed inset-0 flex justify-center items-center z-20 transform transition-all duration-200 ease-out ${
                          openDelete === note._id
                            ? "scale-100 opacity-100 visible"
                            : "scale-95 opacity-0 invisible"
                        }`}
                      >
                        <div className="flex flex-col items-center justify-center p-10 shadow-lg gap-7 bg-white rounded-lg ">
                          <div className="">
                            <p className="">Do you want to delete this note?</p>
                          </div>
                          <div className="flex justify-end items-end gap-8">
                            <button
                              onClick={() => setOpenDelete(false)}
                              className="p-1 px-4 border rounded-md text-sm hover:bg-gray-200"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => DeleteNote(note._id)}
                              className="p-1 px-4 border bg-green-600 text-sm text-white rounded-md hover:bg-green-700"
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div
                    onClick={() => CancelNoteEditing()}
                    className="fixed inset-0 z-20 bg-gray-500/75 transition-opacity"
                    aria-hidden="true"
                  ></div>
                  <div
                    className={`fixed z-50 overflow-y-auto top-1/4 left-[30%] w-[700px] transition-transform duration-500 ease-in-out ${
                      editingNoteId !== note._id
                        ? "translate-y-4 opacity-0 pointer-events-none"
                        : "translate-y-0 opacity-100"
                    }`}
                  >
                    <div className="flex min-h-full items-end justify-center p-10 text-center sm:items-center sm:p-0">
                      <div className="relative rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-10 sm:pb-4">
                          <div className="flex flex-col gap-6 items-start justify-center">
                            <div
                              onClick={() => CancelNoteEditing()}
                              className="absolute z-50 right-5 top-5 cursor-pointer transition-transform duration-500 ease-in-out hover:scale-150 "
                            >
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.30933 10.7482L6.48226 5.85653L11.6552 10.7482M11.6552 0.964844L6.48128 5.85653L1.30933 0.964844"
                                  stroke="#E5EAEF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            <h1 className="text-xl mb-6 font-light">
                              Edit Note
                            </h1>
                            <input
                              value={noteUpdatingValue.noteTitle}
                              onChange={(e) => {
                                setValue("noteTitle", e.target.value);
                              }}
                              type="text"
                              name="noteTitle"
                              placeholder="Enter Title"
                              className="w-full border text-sm p-2 rounded-md text-gray-400"
                            />
                            <textarea
                              className="border w-full rounded-md p-2 text-sm focus:outline-none "
                              placeholder="Description"
                              value={noteUpdatingValue.noteDescription}
                              name="noteDescription"
                              onChange={(e) =>
                                setValue("noteDescription", e.target.value)
                              }
                            />

                            <div className="bg-white py-3 sm:flex items-end justify-end w-full">
                              <button
                                type="button"
                                onClick={() => UpdateNote()}
                                className="inline-flex w-full justify-center items-center gap-2 rounded-md bg-[#057D54] px-10 py-2 text-sm font-semibold text-white shadow-xs hover:bg-green-800 sm:w-auto"
                              >
                                {isNoteUpdating ? <Loader /> : ""}
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bg-secondary-grey-100 text-sm font-medium p-8 mt-10 text-gray-500 text-center">
              <span className="block mb-1">No data</span>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default Notes;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditStatus from "./EditStatus";
import { toast } from "react-toastify";

function TaskStatus({
  setIsEditOpen,
  setIsOption,
  isOption,
  setOnRender,
  onRender,
}) {
  const dispatch = useDispatch();
  const [statuses, setStatuses] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [curretStatus, setCurrentStatus] = useState({});
  const [search, setSearch] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch.trashandStatus.getStatusData();
      setStatuses(response.data);
      setSearch(response.data);
    };
    fetchData();
  }, [isEdit, isReset]);

  const onResetClick = async () => {
    const filteredStatuses = statuses
      .filter(
        (status) =>
          status.task_status !== "Todo" &&
          status.task_status !== "QA" &&
          status.task_status !== "Production" &&
          status.task_status !== "In Progress"
      )
      .map((item) => item._id);
    if (filteredStatuses.length !== 0) {
      const response = await dispatch.trashandStatus.deleteStatusData({
        statusId: filteredStatuses,
      });
      if (response.success) {
        toast.success(response.task);
        setIsReset(!isReset);
      }
    }
  };

  const onSearch = async (value) => {
    const filteredStatuses = statuses.filter((status) =>
      status.task_status.toLowerCase().includes(value.toLowerCase())
    );
    setSearch(filteredStatuses);
  };

  const onSubmit = () => {
    setOnRender(!onRender);
    setIsOption(!isOption);
    setIsEditOpen(false);
  };
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (setIsEditOpen) {
      setIsVisible(true);
    }
  }, [setIsEditOpen]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsEditOpen(false);
      setIsOption(!isOption);
    }, 1000);
  };

  return (
    <div className="">
       <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      <div
        className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-300 text-gray-700 top-0 right-0 border-none  z-50 ${
          isVisible
            ? "translate-x-0"
            : "opacity-0 pointer-events-none translate-x-full"
        }`}
      >
      
      <div className="w-full flex justify-between items-center py-4 border-b-2 px-2">
        <h1 className="text-base font-semibold">Task Status</h1>
        <svg
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => handleClose()}
          className="cursor-pointer"
        >
          <path
            d="M5.98914 6.84377L9.64824 2.3245C9.87558 2.04396 9.87558 1.58878 9.64824 1.30824C9.4209 1.02746 9.05274 1.02746 8.82541 1.30824L5.1663 5.82751L1.50701 1.30824C1.27967 1.02746 0.91151 1.02746 0.684174 1.30824C0.456838 1.58878 0.456838 2.04396 0.684174 2.3245L4.34347 6.84377L0.684174 11.363C0.456838 11.6436 0.456838 12.0988 0.684174 12.3793C0.797842 12.5194 0.946813 12.5896 1.09559 12.5896C1.24437 12.5896 1.39334 12.5194 1.50701 12.3791L5.1663 7.85979L8.82541 12.3791C8.93907 12.5194 9.08805 12.5896 9.23682 12.5896C9.3856 12.5896 9.53457 12.5194 9.64824 12.3791C9.87558 12.0985 9.87558 11.6433 9.64824 11.3628L5.98914 6.84377Z"
            fill="#B4B4B4"
            stroke="#B4B4B4"
            stroke-width="0.7"
          />
        </svg>
      </div>
      {!isEdit ? (
        <>
          {" "}
          <div className="py-4 px-4">
            <h2 className="text-base font-medium pb-3">Search</h2>
            <input
              type="search"
              id="search"
              class="text-[#98A2B2] w-full py-2 px-3 rounded-lg bg-gray-50  border text-sm font-medium"
              placeholder="Search Status.."
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
          <h2 className="py-4 px-4 text-base font-medium pb-5">Status</h2>
          <div className="h-[258px] overflow-y-auto">
            {search?.map((status) => {
              return (
                <div className="py-2 px-4">
                  <div className="flex justify-center items-center gap-3">
                    <input
                      type="checkbox"
                      className="w-[20px] h-[19px] cursor-pointer"
                    />
                    <label
                      class={`${status.bg_color} pr-20 pl-6 py-2 text-sm font-medium rounded-full text-start w-60`}
                    >
                      <p className={`text-${status.text_color}`}>
                        {status.task_status}
                      </p>
                    </label>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => {
                        setIsEdit(true);
                        setCurrentStatus(status);
                      }}
                    >
                      <g clip-path="url(#clip0_354_24716)">
                        <path
                          d="M12.1461 0.145917C12.1925 0.099354 12.2477 0.0624111 12.3084 0.0372047C12.3692 0.0119982 12.4343 -0.000976563 12.5001 -0.000976562C12.5658 -0.000976562 12.6309 0.0119982 12.6917 0.0372047C12.7524 0.0624111 12.8076 0.099354 12.8541 0.145917L15.8541 3.14592C15.9006 3.19236 15.9376 3.24754 15.9628 3.30828C15.988 3.36903 16.0009 3.43415 16.0009 3.49992C16.0009 3.56568 15.988 3.63081 15.9628 3.69155C15.9376 3.7523 15.9006 3.80747 15.8541 3.85392L5.85405 13.8539C5.80607 13.9016 5.74891 13.939 5.68605 13.9639L0.68605 15.9639C0.595186 16.0003 0.495646 16.0092 0.39977 15.9895C0.303893 15.9699 0.215897 15.9225 0.146691 15.8533C0.0774845 15.7841 0.030111 15.6961 0.0104435 15.6002C-0.00922396 15.5043 -0.000320453 15.4048 0.0360502 15.3139L2.03605 10.3139C2.06098 10.2511 2.0984 10.1939 2.14605 10.1459L12.1461 0.145917ZM11.2071 2.49992L13.5001 4.79292L14.7931 3.49992L12.5001 1.20692L11.2071 2.49992ZM12.7931 5.49992L10.5001 3.20692L4.00005 9.70692V9.99992H4.50005C4.63266 9.99992 4.75984 10.0526 4.8536 10.1464C4.94737 10.2401 5.00005 10.3673 5.00005 10.4999V10.9999H5.50005C5.63266 10.9999 5.75984 11.0526 5.8536 11.1464C5.94737 11.2401 6.00005 11.3673 6.00005 11.4999V11.9999H6.29305L12.7931 5.49992ZM3.03205 10.6749L2.92605 10.7809L1.39805 14.6019L5.21905 13.0739L5.32505 12.9679C5.22967 12.9323 5.14744 12.8684 5.08937 12.7847C5.03129 12.7011 5.00013 12.6017 5.00005 12.4999V11.9999H4.50005C4.36744 11.9999 4.24026 11.9472 4.1465 11.8535C4.05273 11.7597 4.00005 11.6325 4.00005 11.4999V10.9999H3.50005C3.39823 10.9998 3.29886 10.9687 3.21523 10.9106C3.1316 10.8525 3.06769 10.7703 3.03205 10.6749Z"
                          fill="#7E7E7E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_354_24716">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="px-4 pt-8">
            <button
              onClick={() => setIsEdit(true)}
              className="px-20 py-1 bg-[#E9E9E9] text-[#686868] rounded-lg"
            >
              Create a new Status
            </button>
          </div>
          <div className="px-4 pt-3">
            <button
              className="border px-[134px] py-1 bg-[#F0F2F599] text-[#686868] rounded-lg"
              onClick={onResetClick}
            >
              Reset
            </button>
          </div>
          <div className="pt-14 px-4">
            <button
              className="flex items-center justify-center px-5 rounded-lg py-2 bg-[#1768E5] text-[#FFFFFF] text-sm font-semibold"
              onClick={onSubmit}
            >
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.2035 7.45474C17.4236 7.25357 17.7165 7.14236 18.0204 7.14456C18.3243 7.14677 18.6154 7.26223 18.8323 7.46657C19.0493 7.67091 19.175 7.94815 19.183 8.2398C19.1911 8.53144 19.0808 8.81468 18.8754 9.02974L12.641 16.5147C12.5338 16.6256 12.4044 16.7145 12.2606 16.7763C12.1168 16.838 11.9614 16.8713 11.8039 16.8741C11.6464 16.8769 11.4899 16.8492 11.3438 16.7926C11.1977 16.736 11.0649 16.6517 10.9535 16.5447L6.81915 12.5757C6.70401 12.4727 6.61167 12.3485 6.54762 12.2105C6.48357 12.0725 6.44913 11.9236 6.44635 11.7725C6.44357 11.6215 6.47252 11.4714 6.53146 11.3313C6.5904 11.1913 6.67812 11.064 6.7894 10.9572C6.90068 10.8504 7.03323 10.7661 7.17915 10.7096C7.32507 10.653 7.48137 10.6252 7.63872 10.6278C7.79606 10.6305 7.95124 10.6636 8.09499 10.7251C8.23874 10.7866 8.36811 10.8752 8.4754 10.9857L11.7473 14.1252L17.1738 7.48774L17.2035 7.45474Z"
                  fill="white"
                />
              </svg>
              <p>Done</p>
            </button>
          </div>
        </>
      ) : (
        <EditStatus
          setIsEdit={setIsEdit}
          curretStatus={curretStatus}
          setCurrentStatus={setCurrentStatus}
        />
      )}
    </div>
    </div>
  );
}

export default TaskStatus;

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "../../components/shared/Header";
import Issues from "../../containers/pages/issues/Issues";
import Member from "../../containers/pages/members/Member";
import People from "../../containers/pages/people/People";
import PasswordReset from "../../containers/pages/profile/Passwordreset";
import Profile from "../../containers/pages/profile/Profile";
import Project from "../../containers/pages/projects/Project";
import ProjectDetails from "../../containers/pages/projects/ProjectDetails";
import People2 from "../../containers/pages/people/People2";
import { useEffect, useState } from "react";

export function Developer() {
  const [shouldHideHeader, setShouldHideHeader] = useState(true);
  const location = useLocation();
  useEffect(() => {
    setShouldHideHeader(location.pathname.startsWith("/projects/"));
  }, [location]);
  return (
    <div className="App">
      {!shouldHideHeader && <Header isDeveloper={true} />}
      <div className={`${!shouldHideHeader ? "mt-20" : ""}`}>
        <Routes>
          <Route path="/projects" element={<Project isDeveloper={true} />} />
          <Route path="/availability" element={<People2 />} />
          <Route path="/people" element={<Member isDeveloper={true} />} />
          <Route path="/projects/:name/:status" element={<ProjectDetails isDeveloper={true} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/change-password" element={<PasswordReset />} />
          <Route path="/" element={<Navigate to="/projects" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Utilities/Loader";
import Technologies from "./Technologies";
import Requirement from "./Requirement";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Member from "./Member";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }], // Dropdown with Normal, H1, H2
    ["bold", "italic", "underline"], // Text styling
    ["link"], // Add links
    [{ list: "ordered" }, { list: "bullet" }], // Ordered and bullet list
    ["clean"], // Remove formatting
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "link",
  "list",
  "bullet",
];

const Overview = ({
  DetailedProjectData,
  projectDescriptionChange,
  isDeveloper,
}) => {
  //     const data =DetailedProjectData.data.project.description;
  //const [editor] = useState(() => withReact(createEditor()));
  const [value, setValue] = useState(
    (DetailedProjectData &&
      DetailedProjectData.project &&
      DetailedProjectData.project.description) ||
      "No data"
  );
  const [visible, setvisibleisible] = useState("hide");
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState("overview");

  const updateContent = async () => {
    try {
      setLoading(true);
      const responseData = await projectDescriptionChange({
        description: value,
        project_id: DetailedProjectData.project._id,
      });
      if (responseData && responseData.success) {
        toast.success("Project description updated");
        setLoading(false);
      } else {
        toast.error(responseData.message);
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      DetailedProjectData &&
      DetailedProjectData.project &&
      DetailedProjectData.project.description
    ) {
      setValue(DetailedProjectData.project.description);
    }
  }, [DetailedProjectData]);

  const tabTitle = [
    {
      id: "1",
      label: "Details",
      status: "overview",
    },
    {
      id: "2",
      label: "Requirements",
      status: "requirement",
    },
    {
      id: "3",
      label: "Technologies",
      status: "technologies",
    },
    {
      id: "4",
      label: "Members",
      status: "members",
    },
  ];

  const tabDetail = (DetailedProjectData, isDeveloper) => {
    switch (status) {
      case "requirement":
        return <Requirement DetailedProjectData={DetailedProjectData} />;
      case "technologies":
        return <Technologies DetailedProjectData={DetailedProjectData} />;
      case "members":
        return <Member data={DetailedProjectData} isDeveloper={isDeveloper} />;
    }
  };

  return (
    <div className="ml-6">
      <h1 className="font-thin text-4xl pb-10">Overview</h1>
      <header className="pb-10">
        {/* {tabTitle && tabTitle.map((item) => (
          <button key={item.id} onClick={() => setStatus(item.status)}
            className={` ${item.status === status ? "text-secondary-blue-400 border-primary-navyBlue" : "border-transparent text-gray-500"}  border-b mr-3 sm:mr-8 font-title text-xs md:text-sm  pb-3 text-title font-normal`}>
            {item.label}
          </button>
        ))} */}
        <div className="flex bg-[#F6F6F6] w-fit rounded-full items-center last:pb-0">
          {tabTitle &&
            tabTitle.map((item) => (
              <button
                key={item.id}
                onClick={() => {
                  setStatus(item.status);
                }}
                className={`px-2 py-2 rounded-full ${
                  status === item.status ? "bg-white h-full w-full" : ""
                } border-4 lg:px-6 border-[#F6F6F6] text-[13px] lg:text-sm text-balck transition duration-300 ease-out w-max ${
                  item.label === "All" ? "px-6 lg:px-10" : ""
                }`}
              >
                {item.label}
              </button>
            ))}
        </div>
      </header>

      {status === "overview" ? (
        <>
          <div className="h-screen">
            <div
            //  className="bg-secondary-grey-100 rounded-xl relative p-6 "
            >
              <div className="flex lg:flex-row justify-between pb-4 items-center absolute right-6 z-10 top-7 cursor-pointer ">
                {/* <div onClick={() => setvisibleisible("show")}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.75241 15.0427L0.00174713 16L0.960414 11.25L4.75241 15.0427ZM1.90308 10.3067L5.69508 14.0993L16.0017 3.79267L12.2091 0L1.90308 10.3067Z"
                      fill="#B6BABD"
                    />
                  </svg>
                </div> */}
              </div>
              {/* <p
                className={`font-base text-tiny font-normal absolute pr-12 ${
                  visible === "show" ? "invisible" : "visible"
                }`}
              >
                {value.replace(/(<([^>]+)>)/gi, "")}{" "}
              </p> */}
              <div
                // className={`${visible === "show" ? "visible" : "invisible"}`}
                className="pb-5"
              >
                {/* <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  modules={modules}
                  formats={formats}
                  placeholder="Start typing..."
                  className="border-none"
                /> */}
                <div className="mb-2 h-40 common-editor   rounded-xl relative ">
                  <ReactQuill
                    className="font-xl h-full bg-white rounded-xl border border-secondary-grey-400"
                    theme="snow"
                    too
                    value={value}
                    onChange={setValue}
                  />
                </div>
              </div>
            </div>
            <button
              disabled={isLoading}
              onClick={() => {
                updateContent();
              }}
              className="mt-4 font-title flex items-center border border-[#057D54] bg-[#057D54] text-white text-sm transition duration-300 ease-out hover:bg-opacity-80 h-8 py-4 px-10 rounded-md font-medium"
            >
              Submit
              {isLoading ? <Loader /> : null}
            </button>
          </div>
        </>
      ) : (
        <div className="">
          <div
            className={` ${status === "requirement" ? "py-0 " : "px-6 py-7"}`}
          >
            {DetailedProjectData
              ? tabDetail(DetailedProjectData, isDeveloper)
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

const mapState = (state) => ({});
const mapDispatch = (dispatch) => ({
  projectDescriptionChange: dispatch.projectDetails.projectDescriptionChange,
});

export default connect(mapState, mapDispatch)(Overview);

import React from "react";
import { Route, Routes } from "react-router-dom";
import { Admin } from "../admin/Admin";
import { Developer } from "../developer/Developer";

const RoleBase = ({ usersession }) => {
  if (
    usersession.userInfo.roles.filter((role) => role.name === "Admin").length >
    0
  ) {
    return (
      <>
        <Routes>
          <Route path="/*" element={<Admin />} />
        </Routes>
      </>
    );
  } else if (
    usersession.userInfo.roles.filter((role) => role.name === "Super admin")
      .length > 0
  ) {
    return (
      <>
        <Routes>
          <Route path="/*" element={<Admin />} />
        </Routes>
      </>
    );
  } else if (
    usersession.userInfo.roles.filter((role) => role.name === "Developer")
      .length > 0
  ) {
    return (
      <div>
        <Routes>
          <Route path="/*" element={<Developer />} />
        </Routes>
      </div>
    );
  } else {
    return <div>User have no roles.</div>;
  }
};

export default RoleBase;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { projectTaskGroupCreationService } from "../../../services/projectDetails";
import { useDispatch } from "react-redux";

export default function GroupModal({
  data,
  setModal,
  mileStoneId,
  renderer,
  setRenderer,
}) {
  const dispatch = useDispatch();
  const [groupTitle, setGroupTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const DetailedProjectData = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );

  const handleGroupCreation = async () => {
    if (!groupTitle.trim()) {
      toast.error("Group name is required!");
      return;
    }

    setLoading(true);

    try {
      const responseData = await projectTaskGroupCreationService({
        project_id: DetailedProjectData.project._id,
        milestone_id: mileStoneId,
        group_name: groupTitle,
      });

      if (responseData && responseData.status === 200) {
        toast.success(responseData?.data?.message);
        setRenderer(!renderer);
        setModal(false);
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (data) {
      setIsVisible(true);
    }
  }, [data]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setModal(false);
    }, 250);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-black opacity-5 z-10`}
        onClick={() => handleClose()}
      />
      <div
        className={`fixed h-fit w-fit inset-0 top-40 z-20 left-1/2 ${
          isVisible ? " animate-slideIn" : "animate-slideOut"
        }`}
      >
        <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
            <div className="bg-white p-4">
              <div className="m-2">
                <div className="flex justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    onClick={() => handleClose()}
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="modal-body relative">
                  <label className="text-sm font-semibold">Group Name</label>
                  <input
                    className="p-3 w-full text-sm outline-none rounded-md border mt-1 mb-5"
                    type="text"
                    placeholder="Enter Group name"
                    onChange={(e) => setGroupTitle(e.target.value)}
                  />

                  <button
                    onClick={handleGroupCreation}
                    disabled={loading}
                    className={`mb-4 p-3 px-5 w-full ${
                      loading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-primary-navyBlue"
                    } text-sm text-white font-semibold rounded-md flex justify-center`}
                  >
                    {loading ? (
                      <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin "></div>
                    ) : (
                      "Create Group"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { months } from "moment/moment";
import React, { useState, useEffect } from "react";
import moment from "moment";

function minutesToTime(minutes) {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  return `${hours}:${mins.toString().padStart(2, "0")}`;
}

function timeToMinutes(time) {
  let [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

function MilestoneDetailsModal({
  setMilestoneDetails,
  correspondingMilestone,
  milestoneDetails,
}) {
  const [totalTimes, setTotalTimes] = useState({
    totalEstimate: "0:00",
    totalLoggedTime: "0:00",
  });
  const [isVisible, setIsVisible] = useState(false);

  const colours = correspondingMilestone.tasks?.length;
  const createdAt = correspondingMilestone?.milestone?.createdAt;
  const startDate = correspondingMilestone.start_date;
  const start_date = new Date(startDate);
  const date = new Date(createdAt);
  const endDate = correspondingMilestone.end_date;
  const updatedAt = correspondingMilestone?.milestone?.updatedAt;
  const tasks = correspondingMilestone.tasks;
  

  useEffect(() => {
    if (correspondingMilestone) {
      setIsVisible(true);
    }
  }, [correspondingMilestone]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setMilestoneDetails(false);
    }, 1000);
  };

  const formattedDatee = correspondingMilestone.createdAt;
  const formattedDate = moment(formattedDatee).format("DD MMM YYYY");
  const formattedTime = moment(formattedDatee).format("hh:mm A");
  const formattedEndDate = moment(endDate).format("DD MMM YYYY");
  const lastUpdated = moment(updatedAt).format("DD MMM YYYY");
  const lastCreated = moment(createdAt).format("DD MMM YYYY");

  const options_start = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedStartDate = date.toLocaleDateString("en-US", options_start);

  useEffect(() => {
    const fetchEstimeLog = () => {
      if (colours !== 0) {
        let totalEstimateMinutes = 0;
        let totalLoggedMinutes = 0;

        tasks.forEach((task) => {
          totalEstimateMinutes += timeToMinutes(task.estimate);
          totalLoggedMinutes += timeToMinutes(task.task_logged_time);
        });

        setTotalTimes({
          totalEstimate: minutesToTime(totalEstimateMinutes),
          totalLoggedTime: minutesToTime(totalLoggedMinutes),
        });
      }
    };
    fetchEstimeLog();
  }, [correspondingMilestone]);

  const statusOptions = [
    { label: "Todo", value: 0 },
    { label: "InProgress", value: 0 },
    { label: "QA", value: 0 },
    { label: "Production", value: 0 },
    { label: "Others", value: 0 },
  ];

  const taskStatusCount = {};
  statusOptions.forEach((option) => {
    taskStatusCount[option.label] = 0;
  });

  tasks.forEach((task) => {
    const status = task.task_status.task_status;
    if (taskStatusCount?.[status] !== undefined) {
      taskStatusCount[status] += 1;
    }
  });

  return (
    <div>
      <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      <div
        className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-500 text-gray-700 top-0 right-0 border-none w-[50rem] z-50 ${
          isVisible ? "translate-x-0" : "pointer-events-none translate-x-full"
        }`}
      >
        <div
          className="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white z-50 bg-clip-padding shadow-lg outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-q pl-2"
          tabIndex="-1"
          id="offcanvasTaskDetails"
          aria-labelledby="offcanvasTaskDetailsLabel"
        >
          <div className="offcanvas-header flex items-center justify-between ">
            <div className="  w-full filter check border-b ">
              <h5
                className="offcanvas-title flex items-center  mb-0 gap-2 p-4 leading-normal font-semibold text-lg text-black"
                id="offcanvasTaskDetailsLabel"
              >
                {correspondingMilestone?.milestone?.title}

                {correspondingMilestone?.milestone?.is_current_milestone && (
                  <button className="bg-[#1E7400] w-[60px] rounded text-white text-[11px]">
                    Current
                  </button>
                )}
              </h5>
            </div>

            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer w-6 h-6 mr-5"
                onClick={() => handleClose()}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="flex justify-between space-x-3 mb-4 p-4">
            <div className="flex flex-col">
              <p className="text-sm  text-[#5F5F5F] font-normal py-5">
                {correspondingMilestone?.milestone?.description}
              </p>
              <label className="text-sm font-semibold mb-4">Overview</label>
              <div className="flex flex-wrap gap-5">
                <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    No. of Groups
                  </label>
                  <label className="text-3xl text-[#5F5F5F] font-semibold pt-1">
                    {correspondingMilestone?.groups?.length}
                  </label>
                </div>
                <div className="flex flex-col text-center items-center gap-1  p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    No. of Tasks
                  </label>
                  <label className="text-3xl text-[#5F5F5F]  font-semibold pt-1">
                    {correspondingMilestone?.tasks?.length}
                  </label>
                </div>
                <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                  <label className=" text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    Estimated Time
                  </label>
                  <label className="p-2 text-md font-semibold text-[#5F5F5F] flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                    </svg>
                    {totalTimes.totalEstimate}h
                  </label>
                </div>
                <div className="flex flex-col text-center items-center gap-1  p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    Logged Time
                  </label>
                  <label className=" p-2 text-md font-semibold text-[#5F5F5F] flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                    </svg>
                    {totalTimes.totalLoggedTime}h
                  </label>
                </div>
                <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    Created At
                  </label>
                  <div className="flex flex-col text-start px-2">
                    <label className="text-xs text-[#5F5F5F] font-semibold inline-block text-center pt-3">
                      {lastCreated}
                    </label>
                    
                  </div>
                </div>
                {correspondingMilestone.start_date && (
                  <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                    <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                      Start Date
                    </label>
                    <label className="text-xs text-[#5F5F5F] font-semibold">
                      {formattedStartDate}
                    </label>
                  </div>
                )}
                {correspondingMilestone.end_date && (
                  <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                    <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                      End Date
                    </label>
                    <label className="text-xs text-[#5F5F5F] font-semibold">
                      {formattedEndDate}
                    </label>
                  </div>
                )}

                <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    Last Update
                  </label>
                  <label className="text-xs text-[#5F5F5F] font-semibold text-center pt-3">
                    {lastUpdated}
                  </label>
                </div>
              </div>
              <label className="text-sm font-semibold mt-4 mb-4">Status</label>
              <div className="flex items-center flex-wrap gap-5">
                {Object.entries(taskStatusCount || {}).map(
                  ([status, count]) => (
                    <div
                      key={status}
                      className={`flex items-center rounded-lg gap-6 py-1 px-4  bg-gray-100
                  ${
                    status === "InProgress"
                      ? "bg-yellow-100 text-[#ca8a04]"
                      : ""
                  }
                  ${status === "QA" ? "bg-green-100 text-[#3cc13c]" : ""}
                  ${
                    status === "Production"
                      ? "bg-violet-100 text-[#3e66fb]"
                      : ""
                  }
                  ${status === "Others" ? "bg-pink-100 text-[#d57272]" : ""}
                  `}
                    >
                      <label className="text-[13px] text-gray-400 font-semibold">
                        {status}
                      </label>
                      <label className="text-xl font-semibold">
                        {count ?? 0}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MilestoneDetailsModal;

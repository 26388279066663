import React, { useState, useEffect } from "react";
import TaskDetails from "./TaskDetails";
import TaskTimeLog from "./TaskTimeLog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./tasks.css";
import EditTask from "./EditTask";
import { useSelector } from "react-redux";
import DeletionModal from "./DeletionModal";

const TaskPriorityConstants = {
  High: {
    bgColor: "bg-orange-100",
    textColor: "text-orange-600",
    text: "High",
    icon: (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99983 0.49999C4.80982 0.49999 4.62064 0.56416 4.46647 0.693336L0.299944 4.70609C-0.0534125 5.00027 -0.101749 5.52614 0.19327 5.8795C0.487456 6.23285 1.01249 6.28036 1.36668 5.98617L5.009 2.41011L8.64422 6.00618C9.00258 6.29453 9.52761 6.23786 9.81596 5.87951C10.1043 5.52115 10.0476 4.99695 9.68929 4.70776L5.52237 0.684168C5.36986 0.56166 5.18485 0.49999 4.99983 0.49999Z"
          fill="#ED4901"
        />
      </svg>
    ),
  },
  Medium: {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
    text: "Medium",
    icon: (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="4.5" width="10" height="2" rx="1" fill="#FDC05E" />
        <rect y="0.5" width="10" height="2" rx="1" fill="#FDC05E" />
      </svg>
    ),
  },
  Low: {
    bgColor: "bg-blue-100",
    textColor: "text-blue-600",
    text: "Low",
    icon: (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99983 6.19023C4.80982 6.19023 4.62064 6.12606 4.46647 5.99688L0.299944 1.98413C-0.0534125 1.68994 -0.101749 1.16407 0.19327 0.810719C0.487456 0.457362 1.01249 0.409859 1.36668 0.704045L5.009 4.2801L8.64422 0.684036C9.00258 0.395684 9.52761 0.452354 9.81596 0.810711C10.1043 1.16907 10.0476 1.69327 9.68929 1.98245L5.52237 6.00605C5.36986 6.12856 5.18485 6.19023 4.99983 6.19023Z"
          fill="#1768E5"
        />
      </svg>
    ),
  },
};
const TaskTimeLabel = ({
  data,
  taskUsedTime,
  taskEstimateTime,
  taskName,
  taskId,
  renderer,
  setRenderer,
  isButtonDisable
}) => {
  const [showTimeLog, setShowTimeLog] = useState(false);
  const [totalTimeSpent, setTotalTimeSpent] = useState(":");
  // const [onRender, setOnRender] = useState(false);

  useEffect(() => {
    if (taskUsedTime && taskUsedTime.length !== 0) {
      let totalMinutes = 0;
      if (Array.isArray(taskUsedTime)) {
        taskUsedTime?.forEach((task) => {
          const [hours, minutes] = task.task_time_spent.split(":").map(Number);
          totalMinutes += hours * 60 + minutes;
        });

        const totalHours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
        setTotalTimeSpent(`${totalHours}:${remainingMinutes}`);
      } else {
        setTotalTimeSpent(taskUsedTime);
      }
    } else {
      setTotalTimeSpent("0:0");
    }
  }, [taskUsedTime]);

  return (
    <>
      <button
        onClick={() => setShowTimeLog(true)}
        className="cursor-pointer text-sm flex items-center gap-1 pt-1 pb-1 pl-2 pr-2 bg-gray-50 border border-gray-100 rounded-full"
        disabled={isButtonDisable}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z"
            fill="#606C80"
          />
        </svg>
        <p className="text-xs font-semibold text-gray-500">
          {`${totalTimeSpent + "h" + "/" + taskEstimateTime + "h"} `}
        </p>
        {showTimeLog && (
          <TaskTimeLog
            data={data}
            showTimeLog={showTimeLog}
            setShowTimeLog={setShowTimeLog}
            taskName={taskName}
            taskId={taskId}
            renderer={renderer}
            setRenderer={setRenderer}
          />
        )}
      </button>
    </>
  );

  // :
  // (
  //   <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
  //     <path d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z" fill="#606C80" />
  //   </svg>
  // );
};

const TodoLabel = (props) => {
  return (
    <div className="pt-1 pb-1 pl-2 pr-2 bg-gray-100 rounded-full">
      <p className="text-xss text-gray-600 font-semibold">{`${props.data} in todo`}</p>
    </div>
  );
};

const TaskPriority = (props) => {
  return (
    <div
      className={`py-0.5 pl-2 pr-2 ${
        TaskPriorityConstants[props?.priority]?.bgColor
      } rounded-full`}
    >
      <p
        className={`text-xss ${
          TaskPriorityConstants[props?.priority]?.textColor
        } font-semibold flex items-center gap-1`}
      >
        {TaskPriorityConstants[props?.priority]?.icon}
        {TaskPriorityConstants[props?.priority]?.text}
      </p>
    </div>
  );
};

const StatusLabel = (props) => {
  return (
    <div
      className={`pt-1 pb-1 pl-2 pr-2 ${props?.status?.bg_color} rounded-full`}
    >
      <p
        className={`text-[10px] font-semibold text-${props?.status?.text_color}`}
      >
        {props?.status?.task_status}
      </p>
    </div>
  );
};

const TasksListComponent = ({
  data,
  item,
  renderer,
  setRenderer,
  taskData,
  setTaskData,
  handle,
  taskTime,
  isTrashed,
  selectedItems,
  setSelectedItems,
  isAddTaskShown,
  setAddTaskIsShown,
  isButtonDisable,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [updateTaskButton, setUpdateTaskButton] = useState(false);
  const [isTaskDetailsShown, setIsTaskDetailsShown] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showDeletionmodal, setShowDeletionModal] = useState(false);
  const [correspondingTask, setCorrespondingTask] = useState({});
  const [taskId, setTaskId] = useState("");

  const taskEstimateTime = useSelector(
    (state) => state.projectDetails.tasksList
  );

  const handleCheckboxChange = (event) => {
    const id = event.target.id;
    if (event.target.checked) {
      setSelectedItems((prevState) => [...prevState, id]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((itemId) => itemId !== id)
      );
    }
  };

  const onDetailClick = (item) => {
    if (item?.status_name !== "DELETE") {
      setIsTaskDetailsShown(!isTaskDetailsShown);
    }
  };

  return (
    <>
      <div
        className={` p-1.5 pl-4  pr-4 bg-white mb-3 border w-full rounded-lg flex justify-around items-center gap-3 shadow-200 `}
        key={item?._id}
      >
        {!isTrashed ? (
          <div className={`text-bold ${handle} cursor-grabbing `}>
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="2.8" height="2.8" fill="#C7CED9" />
              <rect y="5.60004" width="2.8" height="2.8" fill="#C7CED9" />
              <rect y="11.2" width="2.8" height="2.8" fill="#C7CED9" />
              <rect x="5.59811" width="2.8" height="2.8" fill="#C7CED9" />
              <rect
                x="5.59811"
                y="5.60004"
                width="2.8"
                height="2.8"
                fill="#C7CED9"
              />
              <rect
                x="5.59811"
                y="11.2"
                width="2.8"
                height="2.8"
                fill="#C7CED9"
              />
            </svg>
          </div>
        ) : (
          <input
            type="checkbox"
            value="item1"
            checked={selectedItems.includes(item._id)}
            onChange={(event) => handleCheckboxChange(event)}
            id={item._id}
            className={`rounded-full  ${
              isButtonDisable
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-gray-100"
            }`}
            disabled={isButtonDisable}
          />
        )}

        {/* <div className="flex items-center">
          <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 checkbox-round" />
        </div> */}

        <button
          onClick={() => {
            onDetailClick(item);
          }}
          className={`grow text-tiny text-gray-800 font-semibold text-left ${
            item.status_name === "DELETE" ? "cursor-not-allowed" : ""
          }`}
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasTaskDetails"
          aria-controls="offcanvasRight"
        >
          {item?.task_name}
        </button>

        <StatusLabel status={item?.task_status} />
        <TaskPriority priority={item?.task_priority} />

        {/* <div className="text-sm">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2 2.6V1.2C11.2 1.01435 11.1263 0.836301 10.995 0.705025C10.8637 0.57375 10.6857 0.5 10.5 0.5C10.3143 0.5 10.1363 0.57375 10.005 0.705025C9.87375 0.836301 9.8 1.01435 9.8 1.2V2.6H4.2V1.2C4.2 1.01435 4.12625 0.836301 3.99497 0.705025C3.8637 0.57375 3.68565 0.5 3.5 0.5C3.31435 0.5 3.1363 0.57375 3.00503 0.705025C2.87375 0.836301 2.8 1.01435 2.8 1.2V2.6H0V13.8H14V2.6H11.2ZM12.6 12.4H1.4V4H12.6V12.4ZM4.9 6.1H3.5V7.5H4.9V6.1ZM7.7 6.1H6.3V7.5H7.7V6.1ZM10.5 6.1H9.1V7.5H10.5V6.1ZM4.9 8.9H3.5V10.3H4.9V8.9ZM7.7 8.9H6.3V10.3H7.7V8.9ZM10.5 8.9H9.1V10.3H10.5V8.9Z"
              fill="#606C80"
            />
          </svg>
        </div> */}
        <TaskTimeLabel
          data={data}
          taskUsedTime={item.timelogs}
          taskEstimateTime={item?.estimate}
          taskName={item?.task_name}
          taskId={item?._id}
          renderer={renderer}
          setRenderer={setRenderer}
          isButtonDisable={isButtonDisable}
        />

        <div className="text-sm">
          <div className="text-white text-[10px] flex items-center justify-center bg-primary-navyBlue border border-primary-navyBlue w-6 h-6 min-w-[24px] rounded-full cursor-pointer uppercase">
            {item?.assigned_by?.first_name &&
              item?.assigned_by?.first_name?.substring(0, 1)}
            {item?.assigned_by?.last_name
              ? item?.assigned_by?.last_name?.substring(0, 1)
              : item?.assigned_by?.first_name?.substring(1, 2)}
          </div>
        </div>

        <div className="flex justify-center">
          <div>
            <div className="dropdown relative">
              {!isTrashed && !isButtonDisable && (
                <button
                  className={`dropdown-toggle transition duration-150 ease-in-out flex items-center whitespace-nowrap`}
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                  onClick={() => setUpdateTaskButton(!updateTaskButton)}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  
                >
                  <svg
                    width="14"
                    height="4"
                    viewBox="0 0 14 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {isShown ? (
                      <path
                        d="M1.75 0.25C0.7875 0.25 0 1.0375 0 2C0 2.9625 0.7875 3.75 1.75 3.75C2.7125 3.75 3.5 2.9625 3.5 2C3.5 1.0375 2.7125 0.25 1.75 0.25ZM12.25 0.25C11.2875 0.25 10.5 1.0375 10.5 2C10.5 2.9625 11.2875 3.75 12.25 3.75C13.2125 3.75 14 2.9625 14 2C14 1.0375 13.2125 0.25 12.25 0.25ZM7 0.25C6.0375 0.25 5.25 1.0375 5.25 2C5.25 2.9625 6.0375 3.75 7 3.75C7.9625 3.75 8.75 2.9625 8.75 2C8.75 1.0375 7.9625 0.25 7 0.25Z"
                        fill="#1768E5"
                      />
                    ) : (
                      <path
                        d="M1.75 0.25C0.7875 0.25 0 1.0375 0 2C0 2.9625 0.7875 3.75 1.75 3.75C2.7125 3.75 3.5 2.9625 3.5 2C3.5 1.0375 2.7125 0.25 1.75 0.25ZM12.25 0.25C11.2875 0.25 10.5 1.0375 10.5 2C10.5 2.9625 11.2875 3.75 12.25 3.75C13.2125 3.75 14 2.9625 14 2C14 1.0375 13.2125 0.25 12.25 0.25ZM7 0.25C6.0375 0.25 5.25 1.0375 5.25 2C5.25 2.9625 6.0375 3.75 7 3.75C7.9625 3.75 8.75 2.9625 8.75 2C8.75 1.0375 7.9625 0.25 7 0.25Z"
                        fill="#C7CED9"
                      />
                    )}
                  </svg>
                </button>
              )}

              {updateTaskButton && (
                <div>
                  <div
                    className="fixed inset-0 z-10"
                    onClick={() => setUpdateTaskButton(!updateTaskButton)}
                  ></div>
                  <ul
                    className={`dropdown-menu min-w-max absolute -left-24 top-7 bg-white text-base z-40 py-1 list-none text-left rounded-xl shadow-2xl mt-2 bg-clip-padding border 
                      transform transition-all duration-500 ease-in-out
                      ${
                        updateTaskButton
                          ? "opacity-100 scale-100 translate-y-0"
                          : "opacity-0 scale-95 -translate-y-3 pointer-events-none"
                      }`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <button
                        className="dropdown-item text-sm py-2 px-4 pr-14  font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 border-b flex gap-2"
                        onClick={() => {
                          setShowEditTaskModal(true);
                          setUpdateTaskButton(false);
                          setCorrespondingTask(item);
                        }}
                        type="button"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.9"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.497 6.13598L15.4426 7.1948L12.7973 4.54465L13.8517 3.48584C14.0782 3.26973 14.433 3.26973 14.6595 3.48584L16.497 5.32853C16.6033 5.4355 16.663 5.58062 16.663 5.73194C16.663 5.88327 16.6033 6.02839 16.497 6.13536V6.13598ZM4.40652 13.6191L6.38581 15.6052L3.6296 16.3829L4.40652 13.6216V13.6191ZM17.4404 4.38451L15.603 2.5412C14.8502 1.8196 13.6673 1.8196 12.9146 2.5412L3.5977 11.8707C3.51652 11.9514 3.4571 12.0516 3.42505 12.1618L2.02536 17.1648C1.95982 17.3969 2.02342 17.6466 2.19184 17.8183C2.37579 17.9659 2.61347 18.0278 2.84544 17.9884L7.83993 16.5801C7.9512 16.5494 8.05299 16.4909 8.1359 16.4101L17.4404 7.08058C17.7985 6.72459 18 6.23911 18 5.73255C18 5.22598 17.7985 4.7405 17.4404 4.38451Z"
                            fill="#A3A8AE"
                          />
                        </svg>
                        Edit
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item text-sm py-2 px-4 pr-14 font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 flex gap-2 "
                        type="button"
                        onClick={() => {
                          setShowDeletionModal(true);
                          setUpdateTaskButton(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.9033 16.8037H2.8962C2.19382 16.8037 1.70215 16.2418 1.70215 15.6097V5.07394H12.0974V15.5394C12.0974 16.2418 11.5355 16.8037 10.9033 16.8037Z"
                            stroke="#A3A8AE"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.8 4.65238V3.38809C12.8 2.89643 12.3786 2.475 11.8869 2.475H9.2881L9.00714 1.42143C8.86667 1.14048 8.65595 1 8.30476 1H5.49524C5.21429 1 4.93333 1.14048 4.79286 1.42143L4.51191 2.40476H1.9131C1.42143 2.40476 1 2.82619 1 3.31786V4.65238C1 4.86309 1.21071 5.07381 1.42143 5.07381H12.3786C12.6595 5.07381 12.8 4.86309 12.8 4.65238Z"
                            stroke="#A3A8AE"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.65234 7.6021V14.2747"
                            stroke="#A3A8AE"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.8999 7.60216V14.2748"
                            stroke="#A3A8AE"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.14771 7.60216V14.2748"
                            stroke="#A3A8AE"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <TaskDetails
        data={data}
        task={item}
        isTaskDetailsShown={isTaskDetailsShown}
        setIsTaskDetailsShown={setIsTaskDetailsShown}
      />
      {showEditTaskModal && (
        <EditTask
          showEditTaskModal={showEditTaskModal}
          setShowEditTaskModal={setShowEditTaskModal}
          correspondingTask={correspondingTask}
          taskData={taskData}
          setTaskData={setTaskData}
          setRenderer= {setRenderer}
          renderer={renderer}
        />
      )}
      {showDeletionmodal && (
        <DeletionModal
          renderer={renderer}
          setRenderer={setRenderer}
          item={item}
          setShowDeletionModal={setShowDeletionModal}
        />
      )}
    </>
  );
};

export { TasksListComponent, TaskTimeLabel };

import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import SmallModalLayout from "../../components/Common/SmallModalLayout";
import MemberAvailability from "./MemberAvailability";
import Select from "react-select";
import Loader from "../Utilities/Loader";
import { toast } from "react-toastify";
import TechnologyModalLayout from "../Common/TechnologyModalLayout";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PermissionsModal from "./PermissionsModal";

const Member = ({ data, isDeveloper }) => {
  const dispatch = useDispatch();

  const [open, SetOpenModal] = useState(false);
  const [errorOpen, SetErrorOpenModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [allMemberData, setAllMemberData] = useState([]);
  const [invitedMember, setInvitedMember] = useState("");
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [isToggleLoading, setToggleLoading] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(null);
  const [memberData, setMemberData] = useState([]);

  const membersListData = useSelector(
    (state) => state.projectDetails.membersList
  );
  const usersData = useSelector((state) => state.auth.userInfoData);

  const getMembersListData = async ({ project_id }) => {
    try {
      await dispatch.projectDetails.getMembersList({ project_id });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (data && data.project && data.project._id) {
      getMembersListData({ project_id: data.project._id });
      const listAllMembersData = async () => {
        const responseListData = await dispatch.people.listAllMembers();
        if (responseListData && responseListData.data) {
          setAllMemberData(responseListData.data);
        }
      };
      if (isDeveloper) {
        const userId = usersData?._id;
        let members = membersListData?.data;

        if (Array.isArray(members) && members.length > 0) {
          const userIndex = members.findIndex(
            (member) => member?.user_id?._id === userId
          );

          if (userIndex !== -1) {
            const [userObject] = members.splice(userIndex, 1); 
            if (userObject) {
              members.unshift(userObject);
            }
          }
          setMemberData([...members]); 
        } else {
          setMemberData([]); 
        }
      } else {
        setMemberData(
          Array.isArray(membersListData?.data) ? membersListData.data : []
        );
      }
      listAllMembersData();
    }
  }, [data, isDeveloper]);

  const handleModal = () => {
    SetOpenModal(!open);
  };

  const handleErrorModal = () => {
    SetErrorOpenModal(!errorOpen);
  };

  const updateStatus = async (item, ind) => {
    try {
      setToggleLoading(item.user_id._id);
      let status = item.status === "active" ? "deleted" : "active";
      await dispatch.projects.inviteMember([
        { user_id: item.user_id._id, project_id: data.project._id, status },
      ]);
      await getMembersListData({ project_id: data.project._id });
      setToggleLoading(false);
    } catch (err) {
      //toast.error(err.error);
      setToggleLoading(false);
      SetErrorOpenModal(true);
    }
  };

  const InviteMember = async () => {
    try {
      if (invitedMember && invitedMember.value) {
        setUpdateLoading(true);
        const responseData = await dispatch.projects.inviteMember([
          {
            user_id: invitedMember.value,
            project_id: data.project._id,
            status: "active",
            invitation: true,
          },
        ]);
        await getMembersListData({ project_id: data.project._id });
        setUpdateLoading(false);
        if (responseData.data.success) {
          toast.success("Member Invited successfully");
        }
      }
    } catch (err) {
      toast.error(err.error);
      setUpdateLoading(false);
    }
  };

  const handleButtonClick = (skypeId) => {
    if (skypeId !== "NA") {
      window.location.href = `skype:${skypeId}?chat`;
    } else {
      toast.error("Add Skype Name First!!");
    }
  };

  return (
    <>
      <div className="flex items-center lg:flex-row justify-between">
        <h2 className="text-xl text-black font-semibold mb-5">Team Members</h2>
        {!isDeveloper ? (
          <div className="flex mr-10">
            <div className="mb-8 relative ml-2">
              <Select
                className="w-80 medium-selectbox appearance-none focus:outline-none focus:border-primary-navyBluew transition delay-150 duration-150 ease-linear text-xs md:text-base border rounded-3xl placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                name="member"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderRadius: "100px",
                  }),
                }}
                value={invitedMember}
                isSearchable={true}
                isClearable={true}
                classNamePrefix="dropdownSelect"
                options={allMemberData.map((project) => ({
                  label: `${project.first_name} (${
                    project.designation && project.designation.name
                  })`,
                  value: project._id,
                }))}
                placeholder="Select Member"
                onChange={(e) => {
                  setInvitedMember(e);
                }}
              />
            </div>

            <button
              disabled={isUpdateLoading}
              onClick={() => {
                InviteMember();
              }}
              className="ml-2 font-title flex items-center gap-2 bg-white text-[#1D1D1D] text-xs md:text-tiny font-medium transition duration-300 border ease-out hover:bg-opacity-80 px-5 rounded-3xl h-[38px] hover:bg-slate-200"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.14181 2.2915V8.70817M3.10742 5.49984H9.1762"
                  stroke="#545454"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Invite Member
            </button>
            {isUpdateLoading ? <Loader /> : null}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3  2xl:grid-cols-3 gap-6">
        {memberData && memberData.length ? (
          memberData.map((item, index) => (
            <div
              key={index}
              className={`${
                item.status === "active" ? "" : ""
              } border border-200 rounded-2xl p-4 relative shadow-300`}
            >
              <div className="flex items-center justify-start border-b px-4 py-4 gap-5">
                <div>
                  {" "}
                  <img
                    src={`https://i.pravatar.cc/100?img=${item?.user_id?._id}`}
                    alt={item?.user_id?.first_name}
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div>
                  <h5>
                    <span className="capitalize text-base font-semibold text-primary">
                      {item && item.user_id && item.user_id.first_name}&nbsp;
                      {item && item.user_id && item.user_id.last_name}
                    </span>
                  </h5>
                  <p className="text-[#545454] text-sm">
                    {item?.user_id?.designation?.name}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center gap-6 py-8 px-4 border-b">
                <div className="flex flex-col items-start justify-center gap-2">
                  <p className="text-[#1D1D1D] text-xs opacity-70">Email</p>
                  <p className="text-[#1D1D1D] text-sm font-semibold">
                    {item?.user_id?.email}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center gap-2">
                  <p className="text-[#1D1D1D] text-xs opacity-70">Skills</p>
                  <div className="grid grid-cols-4 gap-2">
                    {item?.user_id?.skills &&
                      item?.user_id?.skills?.map((skill, index) => (
                        <button className="bg-[#F2FAF4] text-xs py-1 px-2 text-green-700">
                          {skill.name}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
              {usersData?._id === item.user_id?._id || !isDeveloper ? (
                <div className="flex items-center justify-between w-full pt-2">
                  <button className="flex items-center justify-center gap-2 px-3 py-2 text-black border border-[#E5EAEF] text-xs rounded-md hover:bg-gray-200">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.1971 3.83635C15.2644 3.9385 15.2944 4.06074 15.282 4.18244C15.2696 4.30413 15.2156 4.41782 15.1291 4.50431L8.61739 11.0153C8.55077 11.0819 8.46765 11.1296 8.37655 11.1534L5.66434 11.8618C5.57469 11.8852 5.48048 11.8847 5.39106 11.8604C5.30165 11.8361 5.22014 11.7889 5.15462 11.7234C5.08911 11.6579 5.04187 11.5763 5.01759 11.4869C4.99331 11.3975 4.99284 11.3033 5.01622 11.2136L5.72455 8.50214C5.74573 8.42094 5.78469 8.34546 5.83859 8.28114L12.3744 1.7496C12.474 1.65012 12.609 1.59424 12.7498 1.59424C12.8906 1.59424 13.0256 1.65012 13.1252 1.7496L15.1291 3.75277C15.1538 3.77886 15.1766 3.80681 15.1971 3.83635ZM14.0021 4.12819L12.7498 2.87656L6.71622 8.91014L6.27351 10.6052L7.96855 10.1625L14.0021 4.12819Z"
                        fill="#545454"
                      />
                      <path
                        d="M13.9125 12.1551C14.1062 10.5004 14.168 8.83288 14.0974 7.16839C14.0958 7.12916 14.1023 7.09001 14.1165 7.05343C14.1308 7.01685 14.1525 6.98363 14.1803 6.95589L14.8773 6.25889C14.8963 6.23973 14.9205 6.22649 14.9469 6.22074C14.9733 6.21499 15.0008 6.21699 15.0261 6.22649C15.0514 6.23599 15.0734 6.25259 15.0894 6.2743C15.1055 6.296 15.115 6.32189 15.1167 6.34885C15.2475 8.32598 15.1978 10.3109 14.968 12.279C14.8008 13.7113 13.6505 14.834 12.2246 14.9933C9.7492 15.2673 7.25114 15.2673 4.77576 14.9933C3.35059 14.834 2.19955 13.7113 2.03238 12.279C1.73921 9.76823 1.73921 7.23187 2.03238 4.7211C2.19955 3.28885 3.34988 2.16614 4.77576 2.00676C6.65457 1.79925 8.54743 1.74879 10.4346 1.85589C10.4616 1.85783 10.4875 1.86747 10.5092 1.88366C10.5309 1.89986 10.5475 1.92194 10.5571 1.94728C10.5666 1.97262 10.5687 2.00016 10.5631 2.02665C10.5574 2.05313 10.5443 2.07745 10.5253 2.09672L9.82192 2.79939C9.79443 2.8269 9.76156 2.84846 9.72536 2.86272C9.68917 2.87698 9.65043 2.88363 9.61155 2.88226C8.03675 2.82832 6.46011 2.88869 4.89405 3.06289C4.43642 3.11354 4.00923 3.31698 3.68149 3.64036C3.35375 3.96373 3.14459 4.38815 3.0878 4.84505C2.80365 7.27344 2.80365 9.72667 3.0878 12.1551C3.14459 12.612 3.35375 13.0364 3.68149 13.3597C4.00923 13.6831 4.43642 13.8866 4.89405 13.9372C7.27051 14.2028 9.72984 14.2028 12.107 13.9372C12.5646 13.8866 12.9918 13.6831 13.3196 13.3597C13.6473 13.0364 13.8558 12.612 13.9125 12.1551Z"
                        fill="#545454"
                      />
                    </svg>
                    Set Availability
                  </button>
                  <button
                    className="flex items-center justify-center gap-2 px-3 py-2 border border-[#E5EAEF] text-xs rounded-md hover:bg-gray-200 text-gray-900"
                    onClick={() =>
                      setOpenPermissions(
                        openPermissions === index ? null : index
                      )
                    }
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="opacity-50"
                    >
                      <path
                        d="M3.95833 7.7915V4.95817C3.95833 4.01886 4.33147 3.11803 4.99566 2.45383C5.65986 1.78964 6.56069 1.4165 7.5 1.4165C8.43931 1.4165 9.34015 1.78964 10.0043 2.45383C10.6685 3.11803 11.0417 4.01886 11.0417 4.95817V7.7915M2.54167 7.7915H12.4583C13.2407 7.7915 13.875 8.42577 13.875 9.20817V14.1665C13.875 14.9489 13.2407 15.5832 12.4583 15.5832H2.54167C1.75926 15.5832 1.125 14.9489 1.125 14.1665V9.20817C1.125 8.42577 1.75926 7.7915 2.54167 7.7915Z"
                        stroke="#1E1E1E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Permissions
                  </button>
                </div>
              ) : (
                <button
                  disabled={isUpdateLoading}
                  onClick={() => {
                    handleButtonClick(item?.user_id?.skypeId);
                  }}
                  className="mt-2 ml-2 font-title flex items-center gap-2 bg-white text-[#1D1D1D] text-xs md:text-tiny font-medium transition duration-300 border ease-out hover:bg-opacity-80 px-4 rounded-md h-[38px] hover:bg-slate-200"
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 12 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.14181 2.2915V8.70817M3.10742 5.49984H9.1762"
                      stroke="#545454"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Send Message
                </button>
              )}

              {openPermissions === index && (
                <PermissionsModal
                  user={item}
                  isDeveloper={isDeveloper}
                  setOpenPermissions={setOpenPermissions}
                />
              )}
            </div>
          ))
        ) : (
          <div className="text-sm font-medium text-left py-3 text-gray-500">
            No data
          </div>
        )}
      </div>

      {/* Modal component */}
      <SmallModalLayout closeModal={handleModal} open={open}>
        <MemberAvailability selectedMember={selectedMember} data={data} />
      </SmallModalLayout>

      <TechnologyModalLayout closeModal={handleErrorModal} open={errorOpen}>
        <div className="text-center p-8">
          <p className="text-sm text-primary">
            Please remove all allocated dates for the user and then deactivate
            the user.
          </p>
        </div>
      </TechnologyModalLayout>
    </>
  );
};

export default Member;

import React, { useEffect, useRef, useState } from "react";
import FilterByStatus from "./FilterByStatus";
import FilterByTime from "./FilterByTime";

export default function Filter(props) {
  const filterRef = useRef();

  const [status, setStatus] = useState("status");
  const [openFilter, setOpenFilter] = useState(false);
  const {
    data,
    setShowFilterTasks,
    filterMenu,
    setFilterMenu,
    setTimeFilterMenu,
    timeFilterMenu,
  } = props;

  useEffect(() => {
    if (openFilter === false) {
      setOpenFilter(true);
    }
  }, []);

  const switchTaskView = () => {
    switch (status) {
      case "status":
        return (
          <FilterByStatus
            data={data}
            setShowFilterTasks={setShowFilterTasks}
            filterMenu={filterMenu}
            setFilterMenu={setFilterMenu}
            ref={filterRef}
          />
        );
      case "time":
        return (
          <FilterByTime
            data={data}
            setTimeFilterMenu={setTimeFilterMenu}
            timeFilterMenu={timeFilterMenu}
            ref={filterRef}
          />
        );
      default:
        return null;
    }
  };

  const handleParentClear = () => {
    if (filterRef.current) {
      filterRef.current.handleClearAll();
    }
  };

  return (
    <div>
      <div
        className={`flex flex-col overflow-visible w-full bg-white outline-none text-gray-700 top-0 right-0 border-none`}
        tabIndex="-1"
      >
        <div className="flex items-center justify-between pb-4 pr-4">
          <header className="px-6 border-b flex grow justify-between">
            <div>
              <button
                onClick={() => setStatus("status")}
                className={`
             ${
               status == "status"
                 ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                 : "text-gray-500 border-white border-b-2"
             }
               mr-8 font-title text-sm  pb-3 font-normal`}
              >
                By Task
              </button>
              <button
                onClick={() => setStatus("time")}
                className={`
             ${
               status == "time"
                 ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                 : "text-gray-500 border-white border-b-2"
             }
              mr-8 font-title text-sm  pb-3 font-normal`}
              >
                By Time
              </button>
            </div>

            <div className="cursor-pointer">
              <button className="text-xs font-bold" onClick={handleParentClear}>
                Clear Filter
              </button>
            </div>
          </header>
        </div>
        <div className="flex-grow p-4 pt-2 overflow-y-auto">
          {switchTaskView()}
        </div>
      </div>
    </div>
  );
}

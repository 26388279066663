import React, { useEffect, useState } from "react";
import "./tasks.css";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EditTask({
  editTaskResponseData,
  updateTaskCreation,
  showEditTaskModal,
  setShowEditTaskModal,
  milestoneId,
  mileStoneName,
  correspondingTask,
  taskData,
  setTaskData,
  getTasksListData,
  renderer,
  setRenderer
}) {
  const [allMemberData, setAllMemberData] = useState([]);
  const [mileStones, setMileStones] = useState([]);
  const [milestoneOptions, setMilestoneOptions] = useState([]);
  const [defaultMilestone, setDefaultMilestone] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const usersData = useSelector((state) => state.auth.userInfoData);
  const data = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const statusResponse = await dispatch.trashandStatus.getStatusData();
      const transformedStatuses = statusResponse.data.map((status) => ({
        value: status._id,
        label: status.task_status,
      }));
      setStatusOptions(transformedStatuses);
    };
    fetchData();
  }, [correspondingTask]);

  useEffect(() => {
    if (data && data.project && data.project._id) {
      const listAllMembersData = async () => {
        const responseListData = await dispatch.people.listAllMembersOnly();
        const getMileStone = await dispatch.projectDetails.getMileStoneList({
          project_id: data.project._id,
        });
        setMileStones(getMileStone);
        if (responseListData && responseListData.input) {
          setAllMemberData(responseListData.input);
        }
      };
      listAllMembersData();
    }
  }, [data, taskData]);

  useEffect(() => {
    if (mileStones && mileStones.length > 0) {
      const options = mileStones?.map((item) => ({
        value: item._id,
        label: item.title,
      }));
      setMilestoneOptions(options);
      const defaultMilestone = options.filter(
        (item) => item.value === correspondingTask.milestone_id
      );
      setDefaultMilestone(defaultMilestone);
    }
  }, [mileStones]);

  const firstOption = usersData
    ? {
        value: usersData._id,
        label: usersData.last_name
          ? `${usersData.first_name} ${usersData.last_name} (You)`
          : null,
      }
    : null;

  const otherOptions = allMemberData
    .filter((users) => users.id !== usersData._id)
    .map((user) => ({
      value: user.id,
      label: user.fullName,
    }));

  const options = firstOption ? [firstOption, ...otherOptions] : otherOptions;

  function handleStatusChange(e) {
    setEditTasks({ ...editTask, task_status: e.value });
  }

  const priority_options = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  console.log(correspondingTask, "corresponding task");

  const [editTask, setEditTasks] = useState({
    task_name: correspondingTask?.task_name ? correspondingTask.task_name : "",
    task_description: correspondingTask?.task_description
      ? correspondingTask.task_description
      : "",
    assigned_by: usersData?._id,
    assigned_to: correspondingTask?.assigned_to
      ? correspondingTask.assigned_to?.map((user) => user._id)
      : [],
    milestone_id: correspondingTask?.milestone_id
      ? correspondingTask.milestone_id._id
      : null,
    task_status: correspondingTask?.task_status
      ? correspondingTask?.task_status.task_status
      : null,
    task_priority: correspondingTask?.task_priority
      ? correspondingTask.task_priority
      : null,
    task_start_date: correspondingTask?.task_start_date
      ? moment(correspondingTask?.task_start_date).format("YYYY-MM-DD")
      : "",
    task_end_date: correspondingTask?.task_end_date
      ? moment(correspondingTask?.task_end_date).format("YYYY-MM-DD")
      : "",
    // estimate: 200
  });
  const [loading, setIsLoading] = useState(false);

  const updateTask = async () => {
    setIsLoading(true);
    try {
      const responseData = await updateTaskCreation({
        project_id: correspondingTask.project_id,
        task_id: correspondingTask._id,
        ...editTask,
        task_status:
          correspondingTask?.task_status?.task_status === editTask?.task_status
            ? correspondingTask?.task_status?._id
            : editTask.task_status,
      });
      if (responseData && responseData.success) {
        toast.success("Task updated successfully");
        const res = await getTasksListData({ project_id: data.project._id });
        setTaskData(res.data);
      setRenderer(!renderer);
      } else {
        toast.error(responseData.message);
      }
      setShowEditTaskModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (correspondingTask) {
      setIsVisible(true);
    }
  }, [correspondingTask]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShowEditTaskModal(false);
    }, 1000);
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      {
        <div
          className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-500 text-gray-700 top-0 right-0 border-none w-[50rem] z-50 ${
            isVisible ? "translate-x-0" : "pointer-events-none translate-x-full"
          }`}
        >
          <div className="flex items-center justify-between p-4 pl-6">
            <h5 className="mb-0 leading-normal font-semibold">Update Tasks</h5>

            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleClose()}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <hr className="w-full" />

          <div className="flex-grow p-4 pt-2 pl-6 overflow-y-auto">
            <label className="text-sm font-semibold">Title</label>
            <div className="flex rounded-md border mb-4">
              <div className="p-3 pr-0">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8125 0.531189C13.1231 0.531189 13.375 0.783031 13.375 1.09369V6.5963C13.9721 5.97281 14.7605 5.59369 15.625 5.59369C17.489 5.59369 19 7.35656 19 9.53119C19 11.7058 17.489 13.4687 15.625 13.4687C14.7605 13.4687 13.9721 13.0896 13.375 12.4661V12.9062C13.375 13.2168 13.1231 13.4687 12.8125 13.4687C12.5019 13.4687 12.25 13.2168 12.25 12.9062V1.09369C12.25 0.783031 12.5019 0.531189 12.8125 0.531189ZM15.625 12.3437C16.7112 12.3437 17.875 11.2535 17.875 9.53119C17.875 7.80894 16.7112 6.71869 15.625 6.71869C14.5388 6.71869 13.375 7.80894 13.375 9.53119C13.375 11.2535 14.5388 12.3437 15.625 12.3437ZM6.57614 0.908897C6.49844 0.685472 6.28924 0.534553 6.05272 0.531245C5.81619 0.527938 5.60287 0.672973 5.51896 0.894136L2.45509 8.96869H2.43209V9.02933L1.03674 12.7066C0.926526 12.9971 1.07264 13.3219 1.36309 13.4321C1.65355 13.5423 1.97836 13.3962 2.08856 13.1058L3.23148 10.0937H8.57974L9.62228 13.091C9.72434 13.3844 10.0449 13.5396 10.3384 13.4374C10.6318 13.3354 10.7869 13.0149 10.6848 12.7214L6.57614 0.908897ZM3.65835 8.96869L6.02188 2.73982L8.18845 8.96869H3.65835Z"
                    fill="#98A2B2"
                    stroke="#98A2B2"
                    strokeWidth="0.6"
                  />
                </svg>
              </div>

              <input
                className="p-2 w-full text-xs outline-none "
                type="text"
                value={editTask.task_name}
                onChange={(e) =>
                  setEditTasks({ ...editTask, task_name: e.target.value })
                }
                placeholder="Enter Title"
              />
            </div>

            <label className="text-sm font-semibold">Description</label>
            <div className="mb-5 h-60 common-editor   rounded-xl relative ">
              <ReactQuill
                className="font-xl h-full bg-white rounded-xl border border-secondary-grey-400"
                theme="snow"
                value={editTask.task_description}
                onChange={(content) => {
                  setEditTasks({ ...editTask, task_description: content });
                }}
              />
            </div>
            {/* <div className="flex items-start rounded-md border mb-4">
                            <div className="p-3 pr-0">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3ZM2 7H13C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8C14 8.26522 13.8946 8.51957 13.7071 8.70711C13.5196 8.89464 13.2652 9 13 9H2C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7ZM2 11H8C8.26522 11 8.51957 11.1054 8.70711 11.2929C8.89464 11.4804 9 11.7348 9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11Z"
                                        fill="#98A2B2"
                                    />
                                </svg>
                            </div>

                            <textarea
                                className="p-2 w-full text-xs outline-none"
                                type="text"
                                value={editTask.task_description}
                                onChange={(e) => setEditTasks({ ...editTask, task_description: e.target.value })}
                                placeholder="Enter Description"
                            />
                        </div> */}

            <div className="flex justify-between space-x-3 mb-4">
              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Assignee</label>
                <div className="">
                  <div className="">
                    <Select
                      placeholder="Select assignee"
                      isMulti
                      className="w-full text-xs"
                      defaultValue={
                        correspondingTask?.assigned_to &&
                        correspondingTask?.assigned_to?.map((user) => {
                          return {
                            value: user._id,
                            label: user.last_name
                              ? user.first_name + " " + user.last_name
                              : user.first_name,
                          };
                        })
                      }
                      options={options}
                      onChange={(e) =>
                        setEditTasks({
                          ...editTask,
                          assigned_to: e.map((user) => {
                            return user.value;
                          }),
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Milestone</label>
                <div className="">
                  <Select
                    placeholder="Select Milestone"
                    className="w-full text-xs"
                    onChange={(e) =>
                      setEditTasks({ ...editTask, milestone_id: e.value })
                    }
                    value={defaultMilestone ?? null}
                    options={milestoneOptions}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between space-x-3 mb-4">
              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Status</label>
                <div className="">
                  <Select
                    placeholder="Select Status"
                    className="w-full text-xs"
                    onChange={handleStatusChange}
                    defaultValue={{
                      value: editTask.task_status,
                      label: editTask.task_status,
                    }}
                    options={statusOptions}
                  />
                </div>
              </div>

              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Priority</label>
                <div className="">
                  <Select
                    placeholder="Select Priority"
                    className="w-full text-xs"
                    onChange={(e) =>
                      setEditTasks({ ...editTask, task_priority: e.value })
                    }
                    defaultValue={
                      editTask.task_priority !== null
                        ? {
                            value: editTask.task_priority,
                            label: editTask.task_priority,
                          }
                        : null
                    }
                    options={priority_options.map((option, text) => {
                      return {
                        value: option.value,
                        label: option.label,
                      };
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between space-x-3 mb-4">
              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Start Date</label>
                <div className="flex rounded-md border">
                  <div className="p-3 pr-0">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8 2.4V0.8C12.8 0.587827 12.7157 0.384344 12.5657 0.234315C12.4157 0.0842854 12.2122 0 12 0C11.7878 0 11.5843 0.0842854 11.4343 0.234315C11.2843 0.384344 11.2 0.587827 11.2 0.8V2.4H4.8V0.8C4.8 0.587827 4.71571 0.384344 4.56569 0.234315C4.41566 0.0842854 4.21217 0 4 0C3.78783 0 3.58434 0.0842854 3.43431 0.234315C3.28429 0.384344 3.2 0.587827 3.2 0.8V2.4H0V15.2H16V2.4H12.8ZM14.4 13.6H1.6V4H14.4V13.6ZM5.6 6.4H4V8H5.6V6.4ZM8.8 6.4H7.2V8H8.8V6.4ZM12 6.4H10.4V8H12V6.4ZM5.6 9.6H4V11.2H5.6V9.6ZM8.8 9.6H7.2V11.2H8.8V9.6ZM12 9.6H10.4V11.2H12V9.6Z"
                        fill="#98A2B2"
                      />
                    </svg>
                  </div>

                  <input
                    className="p-2 w-full text-xs outline-none "
                    type="date"
                    placeholder="Start Date"
                    value={editTask.task_start_date}
                    onChange={(e) =>
                      setEditTasks({
                        ...editTask,
                        task_start_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col w-[50%]">
                <label className="text-sm font-semibold">Due Date</label>
                <div className="flex rounded-md border">
                  <div className="p-3 pr-0">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8 2.4V0.8C12.8 0.587827 12.7157 0.384344 12.5657 0.234315C12.4157 0.0842854 12.2122 0 12 0C11.7878 0 11.5843 0.0842854 11.4343 0.234315C11.2843 0.384344 11.2 0.587827 11.2 0.8V2.4H4.8V0.8C4.8 0.587827 4.71571 0.384344 4.56569 0.234315C4.41566 0.0842854 4.21217 0 4 0C3.78783 0 3.58434 0.0842854 3.43431 0.234315C3.28429 0.384344 3.2 0.587827 3.2 0.8V2.4H0V15.2H16V2.4H12.8ZM14.4 13.6H1.6V4H14.4V13.6ZM5.6 6.4H4V8H5.6V6.4ZM8.8 6.4H7.2V8H8.8V6.4ZM12 6.4H10.4V8H12V6.4ZM5.6 9.6H4V11.2H5.6V9.6ZM8.8 9.6H7.2V11.2H8.8V9.6ZM12 9.6H10.4V11.2H12V9.6Z"
                        fill="#98A2B2"
                      />
                    </svg>
                  </div>

                  <input
                    className="p-2 w-full text-xs outline-none "
                    type="date"
                    placeholder="Select Due Date"
                    value={editTask.task_end_date}
                    onChange={(e) =>
                      setEditTasks({
                        ...editTask,
                        task_end_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <button
              onClick={updateTask}
              disabled={loading}
              className={`p-3 px-5  ${
                loading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-primary-navyBlue"
              } text-sm text-white font-semibold rounded-md flex justify-center`}
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="m-1"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                  fill="#ffffff"
                />
              </svg>
              {loading ? "Loading..." : "Update Task"}
            </button>
          </div>
        </div>
      }
    </>
  );
}

const mapState = (state) => ({
  editTaskResponseData: state.projectDetails.tasksList,
});
const mapDispatch = (dispatch) => ({
  updateTaskCreation: dispatch.projectDetails.projectTaskUpdataion,
  getTasksListData: dispatch.projectDetails.getTasksList,
});
export default connect(mapState, mapDispatch)(EditTask);

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import TaskTimeLog from "./TaskTimeLog";
import DeletionModal from "./DeletionModal";

const UpdateOptions = ({ data, updated, setUpdated, setShowModal, task }) => {
  const containerRef = useRef(null);
  const [showEditTimeLogModal, setShowEditTimeLogModal] = useState(false);
  const [showDeleteTimeLogModal, setShowDeleteTimeLogModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowModal(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef}>
      <ul
        className="dropdown-menu min-w-max absolute bg-white text-base z-40 float-left py-1 list-none text-left rounded-xl shadow-2xl mt-1 m-0 bg-clip-padding border-none"
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <button
            className="dropdown-item text-sm py-2 px-4 pr-14  font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 border-b flex gap-2"
            onClick={() => {
              setShowEditTimeLogModal(true);
              setShowModal(null);
            }}
            type="button"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.497 6.13598L15.4426 7.1948L12.7973 4.54465L13.8517 3.48584C14.0782 3.26973 14.433 3.26973 14.6595 3.48584L16.497 5.32853C16.6033 5.4355 16.663 5.58062 16.663 5.73194C16.663 5.88327 16.6033 6.02839 16.497 6.13536V6.13598ZM4.40652 13.6191L6.38581 15.6052L3.6296 16.3829L4.40652 13.6216V13.6191ZM17.4404 4.38451L15.603 2.5412C14.8502 1.8196 13.6673 1.8196 12.9146 2.5412L3.5977 11.8707C3.51652 11.9514 3.4571 12.0516 3.42505 12.1618L2.02536 17.1648C1.95982 17.3969 2.02342 17.6466 2.19184 17.8183C2.37579 17.9659 2.61347 18.0278 2.84544 17.9884L7.83993 16.5801C7.9512 16.5494 8.05299 16.4909 8.1359 16.4101L17.4404 7.08058C17.7985 6.72459 18 6.23911 18 5.73255C18 5.22598 17.7985 4.7405 17.4404 4.38451Z"
                fill="#A3A8AE"
              />
            </svg>
            Edit
          </button>
        </li>

        <li>
          <button
            className="dropdown-item text-sm py-2 px-4 pr-14 font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 flex gap-2 "
            type="button"
            onClick={() => {
              setShowDeleteTimeLogModal(true);
            }}
          >
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9033 16.8037H2.8962C2.19382 16.8037 1.70215 16.2418 1.70215 15.6097V5.07394H12.0974V15.5394C12.0974 16.2418 11.5355 16.8037 10.9033 16.8037Z"
                stroke="#A3A8AE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.8 4.65238V3.38809C12.8 2.89643 12.3786 2.475 11.8869 2.475H9.2881L9.00714 1.42143C8.86667 1.14048 8.65595 1 8.30476 1H5.49524C5.21429 1 4.93333 1.14048 4.79286 1.42143L4.51191 2.40476H1.9131C1.42143 2.40476 1 2.82619 1 3.31786V4.65238C1 4.86309 1.21071 5.07381 1.42143 5.07381H12.3786C12.6595 5.07381 12.8 4.86309 12.8 4.65238Z"
                stroke="#A3A8AE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.65234 7.6021V14.2747"
                stroke="#A3A8AE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.8999 7.60216V14.2748"
                stroke="#A3A8AE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.14771 7.60216V14.2748"
                stroke="#A3A8AE"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Delete
          </button>
        </li>
        {showEditTimeLogModal && (
          <TaskTimeLog
            updated={updated}
            setUpdated={setUpdated}
            data={data}
            setShowTimeLog={setShowEditTimeLogModal}
            taskName={task.task_name}
            task={task}
          />
        )}
        {showDeleteTimeLogModal && (
          <DeletionModal
            updated={updated}
            setUpdated={setUpdated}
            deleteTaskTimelog={task}
            setShowDeletionModal={setShowDeleteTimeLogModal}
          />
        )}
      </ul>
    </div>
  );
};

function TimeLogs({ data, task, logAdded }) {
  const dispatch = useDispatch();

  const [toggleView, setToggleView] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [taskLogs, setTaskLogs] = useState([]);
  const [paginationCount, setPaginationCount] = useState(5);
  const [isShown, setIsShown] = useState({});
  const [showModal, setShowModal] = useState(null);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const getTimeLogs = await dispatch.time.getTaskTimeLog({
        project_id: data?.project?.id,
        task_id: task?._id,
      });
      setTaskLogs(getTimeLogs?.data?.data);
      setShowModal(null);
    };
    fetchData();
  }, [data, task, updated, logAdded]);

  const toggleLogsView = () => {
    setToggleView(!toggleView);
  };

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  const handleLoadMore = () => {
    setPaginationCount(paginationCount + 5);
  };

  return (
    <div className="w-full bg-[#F7F8FA] py-2 px-2 rounded-md">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 7.287V2.8H7.7V6.713L10.465 9.478L9.478 10.465L6.3 7.287Z"
              fill="#606C80"
            />
          </svg>
          <p className="text-sm font-semibold">
            TimeLogs <span className="text-blue-600">{taskLogs?.length}</span>
          </p>
        </div>
        <div className="flex items-center pb-1">
          <svg
            width="37"
            height="22"
            viewBox="0 0 37 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: toggleView ? "none" : "inline-block" }}
            onClick={toggleLogsView}
          >
            <path d="M22.5 11L14.5 5L14.5 17L22.5 11Z" fill="#656A6F" />
          </svg>
          <svg
            width="45"
            height="18"
            viewBox="0 0 45 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleLogsView}
            style={{ display: toggleView ? "inline-block" : "none" }}
          >
            <path d="M22.5 13L28.5 5L16.5 5L22.5 13Z" fill="#656A6F" />
          </svg>
        </div>
      </div>
      {toggleView && (
        <div>
          {taskLogs?.length != 0 ? (
            <div className="pt-10 flex justify-center items-center flex-col gap-2">
              <table className="table-fixed border-t-2 border-b-2 w-full max-w-[90%]">
                <thead>
                  <tr className="text-sm font-medium opacity-85 text-[#838383] border-b-2 border-t-2">
                    <th className="text-left py-3">Date</th>
                    <th className="text-left py-3">Who</th>
                    <th className="text-left py-3 w-[250px]">Description</th>
                    <th className="text-left py-3">Start</th>
                    <th className="text-left py-3">End</th>
                    <th className="text-left py-3">Billable</th>
                    <th className="text-left py-3">Billed</th>
                    <th className="text-left py-3">Time</th>
                  </tr>
                </thead>
                {taskLogs?.slice(0, paginationCount).map((task) => {
                  return (
                    <tbody>
                      <tr className="text-xs font-medium">
                        <td className="py-2">
                          {" "}
                          {moment(task?.createdAt).format("M/D/YY")}
                        </td>
                        <td className="py-2">
                          {task?.user_data?.first_name}{" "}
                          {task?.user_data?.last_name}
                        </td>
                        <td className="py-2">
                          <div
                            className={`p-2 pl-0 ${expanded ? "" : "truncate"}`}
                            onClick={() => toggleDescription()}
                            style={{ maxWidth: "300px" }}
                          >
                            {task?.task_description}
                          </div>
                        </td>
                        <td className="py-2">{task?.task_start_time}</td>
                        <td className="py-2">{task?.task_end_time}</td>
                        <td className="py-2 px-5">
                          {task.isBillable ? (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_489_3189)">
                                <path
                                  d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                                  fill="#06BD23"
                                />
                                <path
                                  d="M10.97 4.97028L10.95 4.99228L7.477 9.41728L5.384 7.32328C5.24183 7.1908 5.05378 7.11868 4.85948 7.12211C4.66518 7.12553 4.47979 7.20425 4.34238 7.34166C4.20497 7.47907 4.12626 7.66446 4.12283 7.85876C4.1194 8.05306 4.19152 8.24111 4.324 8.38328L6.97 11.0303C7.04128 11.1014 7.12617 11.1575 7.21959 11.1951C7.31301 11.2328 7.41305 11.2512 7.51375 11.2493C7.61445 11.2475 7.71374 11.2253 7.8057 11.1843C7.89766 11.1432 7.98041 11.084 8.049 11.0103L12.041 6.02028C12.1769 5.87761 12.2513 5.68718 12.2479 5.49015C12.2445 5.29312 12.1638 5.10533 12.0231 4.96737C11.8823 4.82942 11.693 4.75239 11.4959 4.75293C11.2989 4.75348 11.11 4.83155 10.97 4.97028Z"
                                  fill="#06BD23"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_489_3189">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_489_3201)">
                                <path
                                  d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                                  fill="#898888"
                                />
                                <path
                                  d="M4.64601 4.64592C4.69245 4.59935 4.74763 4.56241 4.80837 4.5372C4.86912 4.512 4.93424 4.49902 5.00001 4.49902C5.06578 4.49902 5.1309 4.512 5.19164 4.5372C5.25239 4.56241 5.30756 4.59935 5.35401 4.64592L8.00001 7.29292L10.646 4.64592C10.6925 4.59943 10.7477 4.56255 10.8084 4.53739C10.8692 4.51223 10.9343 4.49929 11 4.49929C11.0658 4.49929 11.1309 4.51223 11.1916 4.53739C11.2523 4.56255 11.3075 4.59943 11.354 4.64592C11.4005 4.6924 11.4374 4.74759 11.4625 4.80833C11.4877 4.86907 11.5006 4.93417 11.5006 4.99992C11.5006 5.06566 11.4877 5.13076 11.4625 5.1915C11.4374 5.25224 11.4005 5.30743 11.354 5.35392L8.70701 7.99992L11.354 10.6459C11.4005 10.6924 11.4374 10.7476 11.4625 10.8083C11.4877 10.8691 11.5006 10.9342 11.5006 10.9999C11.5006 11.0657 11.4877 11.1308 11.4625 11.1915C11.4374 11.2522 11.4005 11.3074 11.354 11.3539C11.3075 11.4004 11.2523 11.4373 11.1916 11.4624C11.1309 11.4876 11.0658 11.5005 11 11.5005C10.9343 11.5005 10.8692 11.4876 10.8084 11.4624C10.7477 11.4373 10.6925 11.4004 10.646 11.3539L8.00001 8.70692L5.35401 11.3539C5.30752 11.4004 5.25233 11.4373 5.19159 11.4624C5.13085 11.4876 5.06575 11.5005 5.00001 11.5005C4.93426 11.5005 4.86916 11.4876 4.80842 11.4624C4.74769 11.4373 4.6925 11.4004 4.64601 11.3539C4.59952 11.3074 4.56264 11.2522 4.53749 11.1915C4.51233 11.1308 4.49938 11.0657 4.49938 10.9999C4.49938 10.9342 4.51233 10.8691 4.53749 10.8083C4.56264 10.7476 4.59952 10.6924 4.64601 10.6459L7.29301 7.99992L4.64601 5.35392C4.59945 5.30747 4.5625 5.2523 4.5373 5.19155C4.51209 5.13081 4.49911 5.06568 4.49911 4.99992C4.49911 4.93415 4.51209 4.86903 4.5373 4.80828C4.5625 4.74754 4.59945 4.69236 4.64601 4.64592Z"
                                  fill="#898888"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_489_3201">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </td>
                        <td className="py-2 px-4">
                          {task.isBilled ? (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_489_3189)">
                                <path
                                  d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                                  fill="#06BD23"
                                />
                                <path
                                  d="M10.97 4.97028L10.95 4.99228L7.477 9.41728L5.384 7.32328C5.24183 7.1908 5.05378 7.11868 4.85948 7.12211C4.66518 7.12553 4.47979 7.20425 4.34238 7.34166C4.20497 7.47907 4.12626 7.66446 4.12283 7.85876C4.1194 8.05306 4.19152 8.24111 4.324 8.38328L6.97 11.0303C7.04128 11.1014 7.12617 11.1575 7.21959 11.1951C7.31301 11.2328 7.41305 11.2512 7.51375 11.2493C7.61445 11.2475 7.71374 11.2253 7.8057 11.1843C7.89766 11.1432 7.98041 11.084 8.049 11.0103L12.041 6.02028C12.1769 5.87761 12.2513 5.68718 12.2479 5.49015C12.2445 5.29312 12.1638 5.10533 12.0231 4.96737C11.8823 4.82942 11.693 4.75239 11.4959 4.75293C11.2989 4.75348 11.11 4.83155 10.97 4.97028Z"
                                  fill="#06BD23"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_489_3189">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_489_3201)">
                                <path
                                  d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                                  fill="#898888"
                                />
                                <path
                                  d="M4.64601 4.64592C4.69245 4.59935 4.74763 4.56241 4.80837 4.5372C4.86912 4.512 4.93424 4.49902 5.00001 4.49902C5.06578 4.49902 5.1309 4.512 5.19164 4.5372C5.25239 4.56241 5.30756 4.59935 5.35401 4.64592L8.00001 7.29292L10.646 4.64592C10.6925 4.59943 10.7477 4.56255 10.8084 4.53739C10.8692 4.51223 10.9343 4.49929 11 4.49929C11.0658 4.49929 11.1309 4.51223 11.1916 4.53739C11.2523 4.56255 11.3075 4.59943 11.354 4.64592C11.4005 4.6924 11.4374 4.74759 11.4625 4.80833C11.4877 4.86907 11.5006 4.93417 11.5006 4.99992C11.5006 5.06566 11.4877 5.13076 11.4625 5.1915C11.4374 5.25224 11.4005 5.30743 11.354 5.35392L8.70701 7.99992L11.354 10.6459C11.4005 10.6924 11.4374 10.7476 11.4625 10.8083C11.4877 10.8691 11.5006 10.9342 11.5006 10.9999C11.5006 11.0657 11.4877 11.1308 11.4625 11.1915C11.4374 11.2522 11.4005 11.3074 11.354 11.3539C11.3075 11.4004 11.2523 11.4373 11.1916 11.4624C11.1309 11.4876 11.0658 11.5005 11 11.5005C10.9343 11.5005 10.8692 11.4876 10.8084 11.4624C10.7477 11.4373 10.6925 11.4004 10.646 11.3539L8.00001 8.70692L5.35401 11.3539C5.30752 11.4004 5.25233 11.4373 5.19159 11.4624C5.13085 11.4876 5.06575 11.5005 5.00001 11.5005C4.93426 11.5005 4.86916 11.4876 4.80842 11.4624C4.74769 11.4373 4.6925 11.4004 4.64601 11.3539C4.59952 11.3074 4.56264 11.2522 4.53749 11.1915C4.51233 11.1308 4.49938 11.0657 4.49938 10.9999C4.49938 10.9342 4.51233 10.8691 4.53749 10.8083C4.56264 10.7476 4.59952 10.6924 4.64601 10.6459L7.29301 7.99992L4.64601 5.35392C4.59945 5.30747 4.5625 5.2523 4.5373 5.19155C4.51209 5.13081 4.49911 5.06568 4.49911 4.99992C4.49911 4.93415 4.51209 4.86903 4.5373 4.80828C4.5625 4.74754 4.59945 4.69236 4.64601 4.64592Z"
                                  fill="#898888"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_489_3201">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </td>
                        <td className="py-2">{task?.task_time_spent}</td>
                        <td className="py-2">
                          <button
                            className="dropdown-toggle p-4 transition  duration-150 ease-in-out flex items-center whitespace-nowrap"
                            onMouseEnter={() => setIsShown(task?._id)}
                            onMouseOut={() => setIsShown()}
                            onClick={() => setShowModal(task?._id)}
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              width="4"
                              height="15"
                              viewBox="0 0 4 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              {isShown === task?._id ? (
                                <path
                                  d="M3.52465 1.74982C3.51082 0.787419 2.71209 0.0113121 1.74969 0.0251381C0.787291 0.0389641 0.011185 0.837695 0.0250109 1.8001C0.0388369 2.7625 0.837568 3.5386 1.79997 3.52478C2.76237 3.51095 3.53848 2.71222 3.52465 1.74982ZM3.67548 12.2487C3.66165 11.2863 2.86292 10.5102 1.90052 10.5241C0.93812 10.5379 0.162014 11.3366 0.17584 12.299C0.189666 13.2614 0.988396 14.0375 1.9508 14.0237C2.9132 14.0099 3.6893 13.2111 3.67548 12.2487ZM3.60006 6.99928C3.58624 6.03688 2.78751 5.26077 1.82511 5.2746C0.862706 5.28842 0.0865993 6.08715 0.100425 7.04955C0.114251 8.01195 0.912982 8.78806 1.87538 8.77424C2.83778 8.76041 3.61389 7.96168 3.60006 6.99928Z"
                                  fill="#1768E5"
                                />
                              ) : (
                                <path
                                  d="M3.52465 1.74982C3.51082 0.787419 2.71209 0.0113121 1.74969 0.0251381C0.787291 0.0389641 0.011185 0.837695 0.0250109 1.8001C0.0388369 2.7625 0.837568 3.5386 1.79997 3.52478C2.76237 3.51095 3.53848 2.71222 3.52465 1.74982ZM3.67548 12.2487C3.66165 11.2863 2.86292 10.5102 1.90052 10.5241C0.93812 10.5379 0.162014 11.3366 0.17584 12.299C0.189666 13.2614 0.988396 14.0375 1.9508 14.0237C2.9132 14.0099 3.6893 13.2111 3.67548 12.2487ZM3.60006 6.99928C3.58624 6.03688 2.78751 5.26077 1.82511 5.2746C0.862706 5.28842 0.0865993 6.08715 0.100425 7.04955C0.114251 8.01195 0.912982 8.78806 1.87538 8.77424C2.83778 8.76041 3.61389 7.96168 3.60006 6.99928Z"
                                  fill="#C7CED9"
                                />
                              )}
                            </svg>
                            {showModal === task?._id && (
                              <div className="relative">
                                <UpdateOptions
                                  setShowModal={setShowModal}
                                  setUpdated={setUpdated}
                                  updated={updated}
                                  task={task}
                                  data={data}
                                />
                              </div>
                            )}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          ) : (
            <p className="text-sm font-medium opacity-55 text-center">
              No Logs to show
            </p>
          )}
          {paginationCount < taskLogs?.length && (
            <p
              onClick={() => handleLoadMore()}
              className="pl-11 mt-3 font-bold text-xs cursor-pointer flex items-center gap-1"
            >
              <span className="text-[#5D97EF]">Load more</span>
              <span>{taskLogs?.length} tasks</span>
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default TimeLogs;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { EditTaskTimelog } from "../../../services/time";

export default function TaskTimeLog({
  data,
  task,
  updated,
  setUpdated,
  showTimeLog,
  setShowTimeLog,
  taskName,
  taskId,
  renderer,
  setRenderer,
}) {
  const usersData = useSelector((state) => state.auth.userInfoData);
  const dispatch = useDispatch();
  const [allMemberData, setAllMemberData] = useState([]);
  const [showStartTime, setShowStartTime] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);

  const [timeLogData, setTimeLogData] = useState({
    time_assignee: "",
    date: new Date().toISOString().substr(0, 10),
    task_start_time: "",
    time_spent: {
      hours: "",
      minutes: "",
      time: "",
    },
    task_time_spent: "",
    task_end_time: "",
    isBillable: false,
    task_description: "",
  });

  const hrs = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const mints = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  const [selectedStartHr, setSelectedStartHr] = useState(
    task
      ? parseInt(moment(task.task_start_time, "HH:mm").format("h"))
      : parseInt(moment().format("h"))
  );

  const [selectedStartMints, setSelectedStartMints] = useState(
    task
      ? parseInt(moment(task.task_start_time, "HH:mm").format("mm"))
      : parseInt(moment().format("mm"))
  );
  const [selectedStartTimePeriod, setSelectedStartTimePeriod] = useState(
    task
      ? moment(task.task_start_time, "HH:mm").hour() >= 12
        ? "PM"
        : "AM"
      : moment().format("A")
  );

  const [selectedEndHr, setSelectedEndHr] = useState(
    parseInt(moment().format("h"))
  );
  const [selectedEndMints, setSelectedEndMints] = useState(
    parseInt(moment().format("m"))
  );
  const [selectedEndTimePeriod, setSelectedEndTimePeriod] = useState(
    moment().format("A")
  );
  const [checkEndTime, setCheckEndTime] = useState(null);

  useEffect(() => {
    if (data && data.project && data.project._id) {
      const fetchData = async () => {
        const getAllMembers = await dispatch.people.listAllMembersOnly();
        if (getAllMembers && getAllMembers.input) {
          setAllMemberData(getAllMembers.input);
        }
      };
      fetchData();
    }
  }, [data]);

  useEffect(() => {
    let strTime = parseInt(selectedStartHr);
    let endTime = parseInt(selectedEndHr);

    if (selectedStartTimePeriod === "PM") {
      strTime += 12;
    }

    if (selectedEndTimePeriod === "AM" && endTime === 12) {
      endTime = 24;
    }

    if (selectedEndTimePeriod === "PM" && endTime !== 12) {
      endTime += 12;
    }

    setCheckEndTime(endTime);

    const time1 = moment(`${strTime}:${selectedStartMints}`, "HH:mm");
    let time2 = moment(`${endTime}:${selectedEndMints}`, "HH:mm");
    const duration1 = moment.duration(time2.diff(time1));
    const hr = duration1.hours();

    if (time2.isBefore(time1)) {
      time2.add(1, "day");
    }

    const duration = moment.duration(time2.diff(time1));
    const hours = duration.hours();
    const minutes = duration.minutes();

    setTimeLogData({
      ...timeLogData,
      task_start_time: `${strTime}:${selectedStartMints}`,
      task_end_time: `${endTime}:${selectedEndMints}`,
      time_spent: {
        ...timeLogData.time_spent,
        hours: hours,
        minutes: minutes,
        time: hr,
      },
      task_time_spent: `${hours}:${minutes}`,
    });
  }, [
    selectedStartHr,
    selectedEndHr,
    selectedStartMints,
    selectedEndMints,
    selectedStartTimePeriod,
    selectedEndTimePeriod,
  ]);

  useEffect(() => {
    let hours = 0;
    let minutes = 0;

    hours = parseInt(timeLogData.time_spent.hours);
    minutes = parseInt(timeLogData.time_spent.minutes);

    const startTime = moment(timeLogData.task_start_time, "HH:mm");
    const timeSpent = moment.duration({ hours, minutes });

    const endTime = startTime.clone().add(timeSpent);
    const formattedEndTime = endTime.format("HH:mm");

    setSelectedEndHr(parseInt(endTime.format("h")));
    setSelectedEndMints(parseInt(endTime.format("mm")));

    setTimeLogData({
      ...timeLogData,
      task_end_time: formattedEndTime,
    });

    if (parseInt(endTime.format("HH")) > 11) {
      setSelectedEndTimePeriod("PM");
    } else {
      setSelectedEndTimePeriod("AM");
    }
  }, [
    task,
    timeLogData.task_start_time,
    timeLogData.time_spent.hours,
    timeLogData.time_spent.minutes,
  ]);

  useEffect(() => {
    if (task) {
      setTimeLogData((prevState) => ({
        ...prevState,
        date: task.date,
        task_description: task.task_description,
        task_start_time: task.task_start_time,
        isBillable: task.isBillable,
        time_spent: {
          hours: parseInt(task.task_time_spent.split(":")[0]),
          minutes: parseInt(task.task_time_spent.split(":")[1]),
          time: "",
        },
      }));
    }
  }, [task]);

  const submitTimeLog = async () => {
    if (task) {
      const responseData = await EditTaskTimelog({
        timeLog_id: task._id,
        ...timeLogData,
      });
      if (responseData && responseData.status === 200) {
        toast.success("Time Log Updated successfully");
        setUpdated(!updated);
      } else {
        toast.error(responseData.message);
      }
    } else {
      const responseData = await dispatch.time.AddLogTime({
        project_id: data ? data.project._id : null,
        task_id: taskId ? taskId : null,
        user_id: usersData ? usersData._id : null,
        ...timeLogData,
      });
      toast.success("Time Log Added successfully");
      setRenderer(!renderer);
      // if (responseData && responseData.status === 200) {
      // } else {
      //   toast.error(responseData.message);
      // }
    }

    setShowTimeLog(false);
    setTimeLogData({
      time_assignee: "",
      date: "",
      task_start_time: "",
      time_spent: {
        hours: "",
        minutes: "",
      },
      task_time_spent: "",
      task_end_time: "",
      isBillable: false,
      task_description: "",
    });
  };

  const userFirstOption = usersData
    ? {
        value: usersData._id,
        label: usersData.last_name
          ? `${usersData.first_name} ${usersData.last_name} (You)`
          : null,
      }
    : null;

  const userotherOptions = allMemberData
    .filter((users) => users.id !== usersData._id)
    .map((user) => ({
      value: user.id,
      label: user.fullName,
    }));

  const userOptions = userFirstOption
    ? [userFirstOption, ...userotherOptions]
    : userotherOptions;

  const handleEndTimeHr = (time) => {
    let compareTime1 = parseInt(selectedStartHr);
    let compareTime2 = parseInt(time);

    if (selectedStartTimePeriod === "PM") {
      compareTime1 += 12;
    }

    if (selectedEndTimePeriod === "AM" && compareTime2 === 12) {
      compareTime2 = 24;
    }

    if (selectedEndTimePeriod === "PM" && compareTime2 !== 12) {
      compareTime2 += 12;
    }
    if (compareTime2 === 24) {
      setSelectedEndHr(selectedStartHr);
      setSelectedEndTimePeriod("PM");
    }
    if (compareTime1 <= compareTime2) {
      setSelectedEndHr(time);
    } else {
      setSelectedEndHr(selectedStartHr);
    }
  };

  const handleEndTimeMints = (time) => {
    let compareTime1 = parseInt(selectedStartHr);
    let compareTime2 = parseInt(selectedEndHr);

    if (selectedStartTimePeriod === "PM") {
      compareTime1 += 12;
    }

    if (selectedEndTimePeriod === "AM" && compareTime2 === 12) {
      compareTime2 = 24;
    }

    if (selectedEndTimePeriod === "PM" && compareTime2 !== 12) {
      compareTime2 += 12;
    }

    if (compareTime1 < compareTime2) {
      setSelectedEndMints(time);
    } else if (compareTime1 === compareTime2) {
      if (selectedStartMints <= time) {
        setSelectedEndMints(time);
      } else {
        setSelectedEndMints(selectedStartMints);
      }
    }
  };

  const handleEndTimePeriod = (period) => {
    let compareTime1 = parseInt(selectedStartHr);
    let compareTime2 = parseInt(selectedEndHr);

    if (selectedStartTimePeriod === "PM") {
      compareTime1 += 12;
    }

    if (period === "AM" && compareTime2 === 12) {
      compareTime2 = 24;
    }

    if (period === "PM" && compareTime2 !== 12) {
      compareTime2 += 12;
    }

    if (compareTime2 === 24) {
      setSelectedEndTimePeriod("PM");
    }
    if (compareTime1 <= compareTime2) {
      setSelectedEndTimePeriod(period);
    } else {
      setSelectedEndTimePeriod(selectedStartTimePeriod);
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (data) {
      setIsVisible(true);
    }
  }, [data]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShowTimeLog(false);
      if (updated) {
        setUpdated(false);
      }
    }, 1000);
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      <div
        className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-500 text-gray-700 top-0 right-0 border-none w-[50rem] z-50 ${
          isVisible ? "translate-x-0" : "pointer-events-none translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between p-4 border-b">
          <h5 className="mb-0 leading-normal font-semibold pl-2">{taskName}</h5>
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              onClick={() => handleClose()}
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>

        <div className="flex flex-col p-4 overflow-y-auto pl-6 w-full">
          <div className="flex items-center justify-between space-x-3 mb-4">
            <div className="flex flex-col w-[50%]">
              <label className="text-sm text-start font-semibold mb-2">
                Time Assignee
              </label>
              <div className="">
                <Select
                  placeholder="Select assignee"
                  isMulti
                  className="w-full text-xs"
                  defaultValue={
                    task
                      ? {
                          value: task?.user_data?._id,
                          label: `${task?.user_data?.first_name} ${task?.user_data?.last_name}`,
                        }
                      : null
                  }
                  options={userOptions}
                  onChange={(e) =>
                    setTimeLogData({
                      ...timeLogData,
                      time_assignee: e.map((user) => {
                        return user.value;
                      }),
                    })
                  }
                />
              </div>
            </div>

            <div className="flex flex-col w-[50%]">
              <label className="text-sm text-start font-semibold mb-2">Date</label>
              <div className="flex border rounded-md w-full">
                <input
                  className="h-10 p-2 w-full text-xs outline-none"
                  type="date"
                  placeholder="Select date"
                  value={timeLogData.date}
                  onChange={(e) =>
                    setTimeLogData({ ...timeLogData, date: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between space-x-3 mb-4">
            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold mb-2 text-start">Start Time</label>
              <div className="rounded-md border w-full custom-time-input relative">
                <div
                  className="absolute pt-[10px] pl-2"
                  onClick={() => {
                    setShowStartTime(!showStartTime);
                    setShowEndTime(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#808080"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>

                <div className="flex pl-7">
                  <div className="flex items-center">
                    <input
                      type="number"
                      maxLength="2"
                      min="1"
                      max="12"
                      value={
                        selectedStartHr === 10 ||
                        selectedStartHr === 11 ||
                        selectedStartHr === 12
                          ? `${selectedStartHr}`
                          : `0${selectedStartHr}`
                      }
                      className="h-10 pl-2 w-6 text-xs outline-none"
                      onChange={(e) => {
                        const inputValue = parseInt(e.target.value);
                        if (
                          !isNaN(inputValue) &&
                          inputValue >= 1 &&
                          inputValue <= 12
                        ) {
                          setSelectedStartHr(inputValue);
                        }
                      }}
                    />
                    <p className="text-xs pl-1 pr-1">:</p>
                  </div>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    maxLength="2"
                    value={
                      selectedStartMints < 10
                        ? `0${selectedStartMints}`
                        : `${selectedStartMints}`
                    }
                    className="h-10 w-5 text-xs outline-none"
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);
                      if (
                        !isNaN(inputValue) &&
                        inputValue >= 1 &&
                        inputValue <= 59
                      ) {
                        setSelectedStartMints(inputValue);
                      }
                    }}
                  />
                  <input
                    type="text"
                    value={selectedStartTimePeriod}
                    readOnly
                    className="h-10 w-5  text-xs outline-none"
                  />
                </div>

                {/* start time select */}
                {showStartTime && (
                  <div className="absolute h-full pb-3 pt-3 flex flex-col items-start z-50 w-full ">
                    <div className="bg-white shadow-md p-5">
                      <p className="pb-2 font-semibold">Hour</p>

                      <div className="flex justify-center space-x-4 border-b pb-2">
                        {hrs.map((time) => {
                          return (
                            <p
                              key={time}
                              className={` text-sm cursor-pointer ${
                                selectedStartHr === time
                                  ? "text-white bg-blue-500  w-[22px] h-[22px] rounded-full flex justify-center items-center"
                                  : ""
                              }`}
                              onClick={() => {
                                setSelectedStartHr(time);
                                setSelectedEndHr(time);
                              }}
                            >
                              {time}
                            </p>
                          );
                        })}
                      </div>

                      <p className="pb-2 pt-2 font-semibold">Minutes</p>

                      <div className="flex space-x-4 border-b pb-2">
                        {mints.map((time) => {
                          return (
                            <p
                              key={time}
                              className={`text-sm cursor-pointer ${
                                selectedStartMints === time
                                  ? "text-white bg-blue-500 w-[22px] h-[22px] rounded-full flex justify-center items-center"
                                  : ""
                              }`}
                              onClick={() => {
                                setSelectedStartMints(time);
                                setSelectedEndMints(time);
                              }}
                            >
                              {time}
                            </p>
                          );
                        })}
                      </div>

                      <div className="flex justify-center space-x-4 pt-2">
                        <p
                          className={`text-sm cursor-pointer ${
                            selectedStartTimePeriod === "AM"
                              ? "text-white bg-blue-500 w-[22px] h-[22px] text-xs  rounded-full flex justify-center items-center"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedStartTimePeriod("AM");
                            setSelectedEndTimePeriod("AM");
                          }}
                        >
                          AM
                        </p>
                        <p
                          className={`text-sm cursor-pointer ${
                            selectedStartTimePeriod === "PM"
                              ? "text-white bg-blue-500 w-[22px] h-[22px] text-xs rounded-full flex justify-center items-center"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedStartTimePeriod("PM");
                            setSelectedEndTimePeriod("PM");
                          }}
                        >
                          PM
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col w-[50%]">
              <label className="text-sm font-semibold mb-2 text-start">End Time</label>
              <div className="flex w-full rounded-md border custom-time-input relative">
                <div
                  className="absolute pt-[10px] pl-2"
                  onClick={() => {
                    setShowStartTime(false);
                    setShowEndTime(!showEndTime);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#808080"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>

                <div className="flex pl-7">
                  <div className="flex items-center">
                    <input
                      type="number"
                      min="1"
                      max="12"
                      maxLength="2"
                      value={
                        selectedEndHr === 10 ||
                        selectedEndHr === 11 ||
                        selectedEndHr === 12
                          ? `${selectedEndHr}`
                          : `0${selectedEndHr}`
                      }
                      className="h-10 pl-2 w-6 text-xs outline-none"
                      onChange={(e) => {
                        const inputValue = parseInt(e.target.value);
                        if (
                          !isNaN(inputValue) &&
                          inputValue >= 1 &&
                          inputValue <= 12
                        ) {
                          setSelectedEndHr(inputValue);
                        }
                      }}
                    />
                    <p className="text-xs pl-1 pr-1">:</p>
                  </div>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    maxLength="2"
                    value={
                      selectedEndMints < 10
                        ? `0${selectedEndMints}`
                        : `${selectedEndMints}`
                    }
                    className="h-10 w-5 text-xs outline-none"
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);
                      if (
                        !isNaN(inputValue) &&
                        inputValue >= 0 &&
                        inputValue <= 59
                      ) {
                        setSelectedEndMints(inputValue);
                      }
                    }}
                  />
                  <input
                    type="text"
                    readOnly
                    value={selectedEndTimePeriod}
                    className="h-10 w-5  text-xs outline-none"
                  />
                </div>

                {/* end time select*/}
                {showEndTime && (
                  <div className=" absolute h-full pb-3 pt-3  flex flex-col items-end z-50 w-full top-8">
                    <div className="bg-white shadow-md p-5">
                      <p className="pb-2 font-semibold">Hour</p>

                      <div className="flex justify-center space-x-4 border-b pb-2">
                        {hrs.map((time) => {
                          return (
                            <p
                              key={time}
                              className={`text-sm cursor-pointer ${
                                (timeLogData.time_spent.time >= 0 ||
                                  checkEndTime === 24) &&
                                selectedEndHr === time
                                  ? "text-white bg-blue-500 w-[22px] h-[22px] rounded-full flex justify-center items-center"
                                  : null
                              } `}
                              onClick={() => handleEndTimeHr(time)}
                            >
                              {time}
                            </p>
                          );
                        })}
                      </div>

                      <p className="pb-2 pt-2 font-semibold">Minutes</p>

                      <div className="flex space-x-4 border-b pb-2">
                        {mints.map((time) => {
                          return (
                            <p
                              key={time}
                              className={`text-sm cursor-pointer ${
                                (timeLogData.time_spent.time >= 0 ||
                                  checkEndTime === 24) &&
                                selectedEndMints === time
                                  ? "text-white bg-blue-500 w-[22px] h-[22px] rounded-full flex justify-center items-center"
                                  : null
                              }`}
                              onClick={() => handleEndTimeMints(time)}
                            >
                              {time}
                            </p>
                          );
                        })}
                      </div>

                      <div className="flex justify-center space-x-4 pt-2">
                        <p
                          className={`text-sm cursor-pointer ${
                            (timeLogData.time_spent.time >= 0 ||
                              checkEndTime === 24) &&
                            selectedEndTimePeriod === "AM"
                              ? "text-white bg-blue-500 w-[22px] h-[22px] text-xs  rounded-full flex justify-center items-center"
                              : null
                          }`}
                          onClick={() => handleEndTimePeriod("AM")}
                        >
                          AM
                        </p>
                        <p
                          className={`text-sm cursor-pointer ${
                            (timeLogData.time_spent.time >= 0 ||
                              checkEndTime === 24) &&
                            selectedEndTimePeriod === "PM"
                              ? "text-white bg-blue-500 w-[22px] h-[22px] text-xs rounded-full flex justify-center items-center"
                              : null
                          }`}
                          onClick={() => handleEndTimePeriod("PM")}
                        >
                          PM
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {timeLogData.time_spent.time < 0 || checkEndTime === 24 ? (
                  <p className="absolute top-12 text-xs text-gray-400">
                    {moment().add(1, "day").format("D-MM-YYYY")}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between space-x-3">
            <div className="flex flex-col w-[50%] mb-4">
              <label className="text-sm font-semibold mb-2 text-start">Time Spent</label>
              <div className="flex space-x-3">
                <div className="w-20">
                  {
                    // timeLogData.time_spent.time >= 0&&
                    <>
                      <input
                        className="h-10 text-center w-20 outline-none border rounded-md text-xs mr-3"
                        type="number"
                        min="1"
                        max="23"
                        maxLength="2"
                        value={timeLogData.time_spent.hours}
                        onChange={(e) => {
                          let inputValue = e.target.value.trim();
                          if (
                            inputValue.length > 1 &&
                            inputValue.startsWith("0")
                          ) {
                            inputValue = inputValue.substring(1);
                          }
                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 23
                          ) {
                            setTimeLogData({
                              ...timeLogData,
                              time_spent: {
                                ...timeLogData.time_spent,
                                hours: inputValue,
                              },
                            });
                          }
                        }}
                      />

                      {timeLogData.time_spent.hours !== "" && (
                        <p className="text-center text-xs">Hours</p>
                      )}
                    </>
                  }
                </div>
                <div className="w-20">
                  {
                    <>
                      <input
                        className="h-10 text-center w-20 outline-none border rounded-md text-xs mr-3"
                        type="number"
                        min="1"
                        max="12"
                        maxLength="2"
                        placeholder="Minutes"
                        value={timeLogData.time_spent.minutes}
                        onChange={(e) => {
                          let inputValue = e.target.value.trim();
                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 59
                          ) {
                            setTimeLogData({
                              ...timeLogData,
                              time_spent: {
                                ...timeLogData.time_spent,
                                minutes: inputValue,
                              },
                            });
                          }
                        }}
                      />
                      {timeLogData.time_spent.minutes !== "" && (
                        <p className="text-center text-xs">Minutes</p>
                      )}
                    </>
                  }
                </div>
              </div>
              {timeLogData.time_spent.time < 0 || checkEndTime === 24 ? (
                <p className="text-gray-400 text-xs">
                  Please check the time you have entered
                </p>
              ) : null}
            </div>

            <div className="flex flex-col items-start w-[50%]">
              <label className="text-sm font-semibold mb-4 ">Billable</label>
              <div className="w-full items-start flex">
                <input
                  className="text-sm outline-none checkmark"
                  type="checkbox"
                  checked={timeLogData.isBillable}
                  onChange={(e) =>
                    setTimeLogData({
                      ...timeLogData,
                      isBillable: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <label className="text-sm font-semibold text-start">Description</label>
          <div className="flex items-start rounded-md border mt-1 mb-5 w-full">
            <div className="p-3 pr-0">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3ZM2 7H13C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8C14 8.26522 13.8946 8.51957 13.7071 8.70711C13.5196 8.89464 13.2652 9 13 9H2C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7ZM2 11H8C8.26522 11 8.51957 11.1054 8.70711 11.2929C8.89464 11.4804 9 11.7348 9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11Z"
                  fill="#98A2B2"
                />
              </svg>
            </div>

            <textarea
              className="p-2 w-full text-sm outline-none"
              type="text"
              placeholder="Enter task description"
              value={timeLogData.task_description}
              onChange={(e) =>
                setTimeLogData({
                  ...timeLogData,
                  task_description: e.target.value,
                })
              }
            />
          </div>
          {(timeLogData.time_spent.time <= 0 &&
            timeLogData.time_spent.minutes <= 0 &&
            timeLogData.startTime === timeLogData.endTime) ||
          checkEndTime === 24 ? (
            <button
              disabled
              className="border border-primary-navyBlue bg-primary-navyBlue rounded-md text-tiny font-semibold opacity-50 text-white py-3 px-5 flex gap-2 items-center w-32"
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                  fill="#ffffff"
                />
              </svg>
              Log Time
            </button>
          ) : (
            <button
              onClick={submitTimeLog}
              className="border border-primary-navyBlue bg-primary-navyBlue rounded-md text-tiny font-semibold text-white py-3 px-5 flex gap-2 items-center w-32"
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                  fill="#ffffff"
                />
              </svg>
              Log Time
            </button>
          )}
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import Overview from "../../../components/ProjectDetails/Overview";
import Requirement from "../../../components/ProjectDetails/Requirement";
import Issues from "../../../components/ProjectDetails/Issues";
import Roadmap from "../../../components/ProjectDetails/Roadmap";
import Member from "../../../components/ProjectDetails/Member";
import MemberAvailability from "../../../components/ProjectDetails/MemberAvailability";
import Technologies from "../../../components/ProjectDetails/Technologies";
import { toast } from "react-toastify";
import Loader from "../../../components/Utilities/Loader";
import ModalLayout from "../../../components/Common/ModalLayout";
import ProjectCreate from "./ProjectCreate";
import ProjectFiles from "../../../components/ProjectDetails/ProjectDocuments";
import { useSelector, useDispatch } from "react-redux";
import Tasks from "../../../components/ProjectDetails/Task/Tasks";
import AddResources from "./AddResources";
import "../../../css/styles.css";
import Select from "react-select";
import { MobileHeader } from "../../../components/ProjectDetails/Layout/MobileHeader";
import Header from "../../../components/shared/Header";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "9999px",
    borderColor: "#E5EAEF",
    paddingLeft: "10px",
    fontSize: "13px",
    border: "1px solid #D1D5DB",
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "10px",
  }),
};

const ProjectDetails = ({ isDeveloper }) => {
  const location = useLocation();
  const [projectStatus, setProjectStatus] = useState("");
  const [status, setStatus] = useState(location.pathname.split("/")[3]);
  const [isLoading, setLoading] = useState(false);
  const [open, SetOpenModal] = useState(false);
  const [mobStatusView, setMobStatusView] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [statusCount, setStatusCount] = useState({});
  const [toggle, setToggle] = useState("task");
  const [milestoneStatusCount, setMilestoneStatusCount] = useState({});
  const DetailedProjectData = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );
  // const taskList = useSelector((state) => state.projectDetails.tasksList)
  // const {mileStoneList}=useSelector((state)=>state.projectDetails)
  const usersData = useSelector((state) => state.auth.userInfoData);

  const options = [{ value: "Milestones", label: "Milestones" }];
  const [isAddTaskShown, setAddTaskIsShown] = useState(false);
  const dispatch = useDispatch();
  const [tabStatus, setTabStatus] = useState("");
  const [changeStatus, setChangeStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [milestoneOptions, setMilestoneOptions] = useState("");
  const logOut = dispatch.auth.logOut;
  const [openMenu, setOpenMenu] = useState(false);
  const [permissionData, setPermissionData] = useState({});
  const [tabTitle, setTabTitle] = useState([
    {
      id: "1",
      label: "Overview",
      status: "overview",
    },
    // {
    //     id: '2',
    //     label: 'Requirement',
    //     status: 'requirement'

    // },
    // {
    //     id: '3',
    //     label: 'Technologies',
    //     status: 'technologies'
    // },
    {
      id: "6",
      label: "Files",
      status: "files",
    },
    // {
    //   id: "7",
    //   label: "Member",
    //   status: "member",
    // },
    {
      id: "8",
      label: "Tasks",
      status: "tasks",
    },
  ]);

  useEffect(() => {
    const fetchData = () => {
      if (!usersData?.roles.find((item) => item.name === "Admin")) {
        setLoading(true);
        if (permissionData && Object.keys(permissionData).length > 0) {
          const isViewMilestoneAllowed = permissionData?.permissions?.find(
            (p) => p.action === "View-Milestone-And-Tasks"
          )?.allowed;
          const updatedTabTitle = isViewMilestoneAllowed
            ? tabTitle
            : tabTitle.filter((item) => item.label !== "Tasks");

          setTabTitle(updatedTabTitle);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [permissionData]);

  useEffect(() => {
    setTabStatus(location.pathname.split("/")[3]);
  }, [location]);

  const nextStep = (stepComplete) => {
    if (!stepComplete) {
      setStepOne(false);
    } else {
      setStepOne(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (!usersData?.roles.find((item) => item.name === "Admin")) {
          const getPermissions = await dispatch.permissions.getUserPermissions({
            user_id: usersData?._id,
            project_id: DetailedProjectData?.project?._id,
          });
          setPermissionData(getPermissions?.data);
        }

        const getTasks = await dispatch.projectDetails.getTasksList({
          project_id: DetailedProjectData?.project?._id,
        });

        const getMileStone = await dispatch.projectDetails.getMileStoneList({
          project_id: DetailedProjectData?.project?._id,
        });

        setMilestoneOptions(getMileStone);

        const taskStatusCounts = {};
        const taskStatusCountMilestone = {};

        if (selectedOption !== "") {
          let res1 = getTasks?.data?.filter(
            (task) => task?.milestone_id?._id === selectedOption
          );
          res1?.map((task) => {
            const taskStatus = task?.task_status.task_status;
            if (taskStatusCountMilestone[taskStatus]) {
              taskStatusCountMilestone[taskStatus]++;
            } else {
              taskStatusCountMilestone[taskStatus] = 1;
            }
          });
        }
        setMilestoneStatusCount(taskStatusCountMilestone);

        getTasks?.data?.map((task) => {
          const taskStatus = task.task_status.task_status;
          if (taskStatusCounts[taskStatus]) {
            taskStatusCounts[taskStatus]++;
          } else {
            taskStatusCounts[taskStatus] = 1;
          }
        });
        setStatusCount(taskStatusCounts);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [DetailedProjectData, selectedOption]);

  const params = useParams();
  const projectname =
    DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.name;

  const handleModal = () => {
    SetOpenModal(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (params.name) {
        try {
          await dispatch.projectDetails.getProjectDetailes({
            projectName: params.name,
          });
        } catch (err) {
          console.log("err", err);
        }
      }
    };
    fetchData();
  }, [params.name]);

  useEffect(() => {
    return () => {
      dispatch.projectDetails.updateProjectDetailsData({});
    };
  }, []);

  const tabDetail = (DetailedProjectData, isDeveloper) => {
    switch (tabStatus) {
      case "overview":
        return (
          <Overview
            DetailedProjectData={DetailedProjectData}
            isDeveloper={isDeveloper}
          />
        );
      // case "requirement":
      //     return <Requirement DetailedProjectData={DetailedProjectData} />
      // case "technologies":
      //     return <Technologies DetailedProjectData={DetailedProjectData} />
      case "files":
        return <ProjectFiles DetailedProjectData={DetailedProjectData} />;
      case "member":
        return <Member isDeveloper={isDeveloper} data={DetailedProjectData} usersData={usersData} />;
      case "memberavailability":
        return <MemberAvailability data={DetailedProjectData} />;
      case "tasks":
        return (
          <Tasks
            data={DetailedProjectData}
            isAddTaskShown={isAddTaskShown}
            setAddTaskIsShown={setAddTaskIsShown}
          />
        );
      default:
        return (
          <Overview
            DetailedProjectData={DetailedProjectData}
            isDeveloper={isDeveloper}
          />
        );
    }
  };

  const updateProjectStatus = async () => {
    try {
      setLoading(true);
      const responseData = await dispatch.projectDetails.projectStatusChange({
        project_id: DetailedProjectData.project._id,
        status: projectStatus,
      });
      if (responseData && responseData.success) {
        toast.success("Project status updated");
      } else {
        toast.error(responseData.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.error);
    }
  };

  const servertechnologies =
    DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.technologies &&
    DetailedProjectData.project.technologies.server.length > 0 &&
    DetailedProjectData.project.technologies.server.map(
      (servertech, serverind) => {
        return (
          <span
            key={serverind}
            className="border text-tiny items-center p-2 px-3 font-medium mr-1  mb-1 bg-white rounded text-secondary-grey-200 inline-block"
          >
            {servertech && servertech.techId && servertech.techId.name}
          </span>
        );
      }
    );

  const clienttechnologies =
    DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.technologies &&
    DetailedProjectData.project.technologies.client.length > 0 &&
    DetailedProjectData.project.technologies.client.map(
      (clienttech, clientind) => {
        return (
          <span
            key={clientind}
            className="border text-tiny items-center p-2 px-3 font-medium mr-1 mb-1 bg-white rounded text-secondary-grey-200 inline-block"
          >
            {clienttech && clienttech.techId && clienttech.techId.name}
          </span>
        );
      }
    );

  return (
    <div>
      {isLoading ? (
        <div
          className="flex items-center justify-center h-screen bg-secondary-blue-300"
        >
          <div className="init-loader">
            Loading
            <span></span>
          </div>
        </div>
      ) : (
        <div>
          <Header
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            projectHeader={true}
            projectname={projectname}
            tabTitle={tabTitle}
            status={status}
            setStatus={setStatus}
            isDeveloper={isDeveloper}
          />
          <div className="pt-16">
            {/* <div className={`flex flex-col lg:flex-row ${isAddTaskShown ? 'bg-gray-200 opacity-50 disabled:':null}`}> */}
            <div className={`flex flex-col lg:flex-row `}>
              <div
                className={`bg-white rounded mb-5 lg:mb-0  ${
                  !["files", "requirement", "technologies"].includes(status)
                    ? "md:w-full"
                    : "md:w-full  w-full"
                } `}
              >
                <div className="pt-0">
                  <div
                    className={` ${
                      status === "requirement" ? "py-0 " : "px-6 py-7"
                    }`}
                  >
                    {DetailedProjectData
                      ? tabDetail(DetailedProjectData, isDeveloper)
                      : null}
                  </div>
                </div>
              </div>

              {!["files", "requirement", "technologies"].includes(status) ? (
                <div className=" mt-10 px-6 ">
                  <div className="border rounded-lg h-auto px-2 py-4 bg-[#F9F9F9]">
                    <div className="flex items-center justify-between pb-4 space-x-2 px-3 cursor-pointer gap-10">
                      <p className="text-2xl !font-light">Status</p>
                      <div className="bg-[#F6F6F6] flex items-center justify-center gap-1 rounded-full p-1">
                        <p
                          className={`rounded-full px-3 py-2 text-xs ${
                            toggle === "task"
                              ? "bg-white text-[#6E6E6E]"
                              : "bg-[#F6F6F6] "
                          }`}
                          onClick={() => setToggle("task")}
                        >
                          Tasks
                        </p>
                        <p
                          className={`rounded-full text-xs py-2 px-3 ${
                            toggle === "milestone"
                              ? "bg-white text-[#6E6E6E]"
                              : "bg-[#F6F6F6]"
                          }`}
                          onClick={() => setToggle("milestone")}
                        >
                          Milestones
                        </p>
                      </div>
                    </div>
                    {toggle === "milestone" && (
                      <div className="w-full px-4 pb-4 rounded-full">
                        <Select
                          placeholder="Select Status"
                          styles={customStyles}
                          defaultValue={options.find(
                            (option) => option.value === "Tasks"
                          )}
                          options={options}
                          onChange={(e) => setChangeStatus(e.value)}
                        />
                      </div>
                    )}

                    <div className="pt-4 pl-5 self-start">
                      <div className="flex  justify-left gap-2 mb-3 flex-col space-y-5">
                        {changeStatus === "Milestones" && (
                          <div className="w-full pr-5 ">
                            <Select
                              placeholder="Select Milestone"
                              className=" text-[11px] "
                              defaultValue={options.find(
                                (option) => option.value === "Tasks"
                              )}
                              options={milestoneOptions?.map((item) => ({
                                value: item._id,
                                label: item.title,
                              }))}
                              onChange={(e) => setSelectedOption(e.value)}
                            />
                          </div>
                        )}
                        <div className="flex gap-2 items-center">
                          <p className="text-sm !font-thin">
                            Todo(
                            {selectedOption != ""
                              ? milestoneStatusCount?.Todo ?? 0
                              : statusCount?.Todo ?? 0}
                            )
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pt-2 pl-5 self-start">
                      <div className="flex items-center justify-left gap-2 mb-3">
                        <p className="text-sm !font-thin ">
                          In Progress(
                          {selectedOption !== ""
                            ? milestoneStatusCount?.InProgress ?? 0
                            : statusCount?.InProgress ?? 0}
                          )
                        </p>
                      </div>
                    </div>
                    <div className="pt-2 pl-5 self-start">
                      <div className="flex items-center justify-left gap-2 mb-3">
                        <p className="text-sm !font-thin ">
                          QA(
                          {selectedOption != ""
                            ? milestoneStatusCount?.QA ?? 0
                            : statusCount?.QA ?? 0}
                          )
                        </p>
                      </div>
                    </div>
                    <div className="pt-2 pl-5 self-start">
                      <div className="flex items-center justify-left gap-2 mb-3">
                        <p className="text-sm !font-thin ">
                          Production(
                          {selectedOption != ""
                            ? milestoneStatusCount?.Production ?? 0
                            : statusCount.Production ?? 0}
                          )
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {openMenu && (
            <MobileHeader
              tabTitle={tabTitle}
              setOpenMenu={setOpenMenu}
              projectName
            />
          )}

          <ModalLayout closeModal={handleModal} open={open}>
            <ProjectCreate
              closeModal={handleModal}
              projectInfoEdit={
                DetailedProjectData && DetailedProjectData.project
              }
            />
          </ModalLayout>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;

import moment from "moment";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import TimeLogFilterData from "../TimeLogFilterData";

const FilterByTime = forwardRef(
  ({ data, setTimeFilterMenu, timeFilterMenu }, ref) => {
    const [allMemberData, setAllMemberData] = useState([]);
    const [mileStones, setMileStones] = useState([]);
    const [showCustomDate, setShowCustomDate] = useState(false);
    const usersData = useSelector((state) => state.auth.userInfoData);
    const [dateSelected, setDateSelected] = useState("");
    const [timeLogFilteredData, setTimeLogFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const userRef = useRef(null);
    const dateRef = useRef(null);
    const billableRef = useRef(null);
    const invoiceRef = useRef(null);

    useEffect(() => {
      if (data && data.project && data.project._id) {
        if (timeFilterMenu.dateRange === "Custom date range") {
          setShowCustomDate(true);
        }
        const listAllMembersData = async () => {
          const responseListData = await dispatch.people.listAllMembersOnly();
          const getMileStone = await dispatch.projectDetails.getMileStoneList({
            project_id: data.project._id,
          });
          setMileStones(getMileStone);
          if (responseListData && responseListData.input) {
            setAllMemberData(responseListData.input);
          }
        };
        listAllMembersData();
      }
    }, [data, timeFilterMenu]);

    const firstOption = usersData
      ? {
          value: usersData._id,
          label: usersData.last_name
            ? `${usersData.first_name} ${usersData.last_name} (You)`
            : null,
        }
      : null;

    const otherOptions = allMemberData
      .filter((users) => users.id !== usersData._id)
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      }));

    const options = firstOption ? [firstOption, ...otherOptions] : otherOptions;
    const status_options = [
      { value: "Todo", label: "Todo" },
      { value: "In Progress", label: "In Progress" },
      { value: "Production", label: "Production" },
      { value: "QA", label: "QA" },
    ];

    function getFullName(id) {
      const user = allMemberData.find((user) => user.id === id);
      return user ? { label: id, value: user.fullName } : "";
    }

    const applyFilter = async () => {
      setLoading(true);
      try {
        const res = await dispatch.projectDetails.getTasksListTimeLogFilter({
          ...timeFilterMenu,
        });
        setTimeLogFilteredData(res);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const handleClearAll = () => {
      userRef.current.clearValue();
      dateRef.current.clearValue();
      billableRef.current.clearValue();
      invoiceRef.current.clearValue();
      setTimeFilterMenu({
        user_id: "",
        user_name: [],
        start_date: "",
        end_date: "",
        is_bilable: false,
      });
      setShowCustomDate(false);
      setTimeLogFilteredData(null);
    };

    useImperativeHandle(ref, () => ({
      handleClearAll,
    }));

    const getStartDate = async (date) => {
      setDateSelected(date);
      let start_date;
      let end_date;
      let dateRange;

      if (date === "Today") {
        setShowCustomDate(false);
        start_date = moment().format("YYYY-MM-DD");
        end_date = moment().format("YYYY-MM-DD");
      } else if (date === "Yesterday") {
        setShowCustomDate(false);
        start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        end_date = start_date;
      } else if (date === "This Week") {
        setShowCustomDate(false);
        start_date = moment().clone().startOf("week").format("YYYY-MM-DD");
        end_date = moment().clone().endOf("week").format("YYYY-MM-DD");
      } else if (date === "Last Week") {
        setShowCustomDate(false);
        start_date = moment()
          .clone()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD");
        end_date = moment()
          .clone()
          .subtract(1, "week")
          .endOf("week")
          .format("YYYY-MM-DD");
      } else if (date === "This Month") {
        setShowCustomDate(false);
        start_date = moment().clone().startOf("month").format("YYYY-MM-DD");
        end_date = moment().clone().endOf("month").format("YYYY-MM-DD");
      } else if (date === "Last Month") {
        setShowCustomDate(false);
        const firstDayOfCurrentMonth = moment().clone().startOf("month");
        const lastDayOfLastMonth = firstDayOfCurrentMonth
          .clone()
          .subtract(1, "day");
        start_date = lastDayOfLastMonth
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        end_date = lastDayOfLastMonth.format("YYYY-MM-DD");
      } else if (date === "Custom date range") {
        setShowCustomDate(true);
      }

      setTimeFilterMenu({
        ...timeFilterMenu,
        start_date: start_date,
        end_date: end_date,
        dateRange: date,
      });
    };

    return (
      <>
        <div className="grid grid-cols-2 gap-x-10 gap-y-2 items justify-center">
          <div className="">
            <label className="text-xs font-semibold">People</label>
            <Select
              placeholder="Select user"
              isMulti
              ref={userRef}
              className="w-full text-xs"
              defaultValue={
                timeFilterMenu?.user_name &&
                timeFilterMenu?.user_name?.map((item) => ({
                  value: item.label,
                  label: item.value,
                }))
              }
              options={options}
              onChange={(e) =>
                setTimeFilterMenu({
                  ...timeFilterMenu,
                  user_id: e.map((user) => {
                    return user.value;
                  }),
                  user_name: e.map((user) => {
                    return getFullName(user.value);
                  }),
                })
              }
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
            />
          </div>

          <div className="">
            <label className="text-xs font-semibold">Date</label>
            <div className="">
              <Select
                className="w-full text-xs"
                placeholder="Select date range"
                options={[
                  { value: "Today", label: "Today" },
                  { value: "Yesterday", label: "Yesterday" },
                  { value: "This Week", label: "This Week (Sun - Sat)" },
                  { value: "Last Week", label: "Last Week (Sun - Sat)" },
                  { value: "This Month", label: "This Month" },
                  { value: "Last Month", label: "Last Month" },
                  { value: "Custom date range", label: "Custom date range" },
                ]}
                onChange={(e) => {
                  !e
                    ? setTimeFilterMenu({
                        ...timeFilterMenu,
                        start_date: "",
                        end_date: "",
                      })
                    : getStartDate(e.value);
                }}
                defaultValue={
                  timeFilterMenu.dateRange
                    ? {
                        value: timeFilterMenu.dateRange,
                        label: timeFilterMenu.dateRange,
                      }
                    : null
                }
                ref={dateRef}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                }}
              />
            </div>
            {showCustomDate && (
              <div className="flex justify-between space-x-3">
                <div className="w-[50%]">
                  <label className="text-xs font-semibold">Start Date</label>
                  <div className="flex items-center rounded-md border mt-1 mb-3">
                    <input
                      className="w-full h-9 pl-2 pr-2 text-xs outline-none "
                      type="date"
                      placeholder="Anytime"
                      onChange={(e) =>
                        setTimeFilterMenu({
                          ...timeFilterMenu,
                          start_date: e.target.value,
                        })
                      }
                      defaultValue={
                        timeFilterMenu.start_date
                          ? timeFilterMenu.start_date
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="w-[50%]">
                  <label className="text-xs font-semibold">End Date</label>
                  <div className="flex items-center rounded-md border mt-1 mb-3">
                    <input
                      className="w-full h-9 pl-2 pr-2 text-xs outline-none "
                      type="date"
                      placeholder="Anytime"
                      onChange={(e) =>
                        setTimeFilterMenu({
                          ...timeFilterMenu,
                          end_date: e.target.value,
                        })
                      }
                      defaultValue={
                        timeFilterMenu.end_date ? timeFilterMenu.end_date : null
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between space-x-3">
            <div className="w-full">
              <label className="text-xs font-semibold">Billable</label>
              <div className="mt-1 mb-3">
                <Select
                  className="w-full text-xs"
                  options={[
                    { value: "Billable", label: "Billable" },
                    { value: "Non billable", label: "Non billable" },
                  ]}
                  ref={billableRef}
                  defaultValue={
                    timeFilterMenu?.is_bilable === ""
                      ? null
                      : timeFilterMenu?.is_bilable === true
                      ? { value: "Billable", label: "Billable" }
                      : { value: " Non Billable", label: " Non Billable" }
                  }
                  onChange={(e) =>
                    setTimeFilterMenu({
                      ...timeFilterMenu,
                      is_bilable: e?.value === "Billable" ? true : false,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="">
            <label className="text-xs font-semibold">Invoice</label>
            <div className="mt-1 mb-3">
              <Select
                className="w-full text-xs"
                options={[
                  { value: "Invoice", label: "Invoice" },
                  { value: "Non Invoiced", label: "Non Invoiced" },
                ]}
                ref={invoiceRef}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-3">
          <button
            onClick={applyFilter}
            className={`w-28 borderborder-primary-navyBlue bg-primary-navyBlue rounded-md text-tiny font-semibold text-white py-3 px-5 ${
              loading ? "opacity-35" : ""
            }`}
          >
            Apply
          </button>
        </div>
        <div>
          {loading ? (
            <div className="flex justify-center items-center w-full h-20">
              <div className="w-7 h-7 border-2 border-t-transparent border-blue-800 rounded-full animate-spin" />
            </div>
          ) : (
            timeLogFilteredData?.taskWiseTimeSpent && (
              <div className="mt-10 pt-3 pb-3 rounded-xl text-xs bg-gray-100 pl-2 pr-2">
                <p className="font-bold mb-2">Filtered Totals</p>

                <div className="flex items-center justify-between">
                  <p>
                    <span className="font-bold pr-1">Logged:</span>
                    {timeLogFilteredData?.taskWiseTimeSpent[0]?.TimeSpent}
                  </p>
                  <p>
                    <span className="font-bold pr-1">Non-billable:</span>
                    {timeLogFilteredData?.taskWiseTimeSpent[0]?.TimeSpent}
                  </p>
                  <p>
                    <span className="font-bold pr-1">Billable:</span>
                    {timeLogFilteredData?.taskWiseTimeSpent[0]?.TimeSpent}
                  </p>
                </div>
              </div>
            )
          )}

          {timeLogFilteredData?.data?.length > 0 &&
            timeLogFilteredData?.data.map((item, index) => (
              <React.Fragment key={item._id}>
                {index === 0 ||
                item.date !== timeLogFilteredData?.data[index - 1]?.date ? (
                  <div className="py-4" key={item.date}>
                    <div className="py-4 font-bold">
                      {moment(item?.date).format("dddd, DD MMMM")}
                    </div>
                    <table className="w-full text-sm text-left rtl:text-right ">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Project
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Who
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Description
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Start
                          </th>
                          <th scope="col" className="px-6 py-3">
                            End
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Billable
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Billed
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Time Spent
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeLogFilteredData?.data
                          .filter((filterItem) => filterItem.date === item.date)
                          .map((filteredItem) => (
                            <TimeLogFilterData
                              key={filteredItem._id}
                              data={data}
                              item={filteredItem}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
        </div>
      </>
    );
  }
);

export default FilterByTime;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TasksListComponent } from "../TasksListComponent";
import { toast } from "react-toastify";

function TrashedTasks({
  setIsTrashOpen,
  setOnRender,
  isTrashOpen,
  onRender,
  isButtonDisable,
}) {
  const dispatch = useDispatch();
  const [trashedTasks, setTrashTasks] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const DetailedProjectData = useSelector(
    (state) => state.projectDetails.getDetailedProjectData
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch.trashandStatus.getTrashedData();
      setTrashTasks(response.trash.task);
    };
    fetchData();
  }, [onSuccess, isTrashOpen]);

  const onSelectAll = () => {
    setAllSelected(true);
    const ids = trashedTasks.map((obj) => obj._id);
    setSelectedItems(ids);
  };

  const onUnselectAll = () => {
    setAllSelected(false);
    setSelectedItems([]);
  };

  const onDeletePermanent = async () => {
    setDeleteLoading(true);
    try {
      const response = await dispatch.trashandStatus.deleteTrashedData({
        trashId: selectedItems,
      });
      if (response.success) {
        toast.success(response.task);
        setOnSuccess(!onSuccess);
        setSelectedItems([]);
      } else {
        toast.error(response.task);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const onRestore = async () => {
    setRestoreLoading(true);
    try {
      const response = await dispatch.trashandStatus.restoreTrashedData({
        trashId: selectedItems,
        project_id: DetailedProjectData?.project?._id,
      });
      if (response.success) {
        toast.success(response.task);
        setSelectedItems([]);
        setOnRender(!onRender);
        setOnSuccess(!onSuccess);
      } else {
        toast.error(response.task);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRestoreLoading(false);
    }
  };

  return (
    <div>
      <div
        className={`fixed inset-0 bg-gray-500 opacity-5 ${
          isTrashOpen ? "" : "hidden"
        } `}
        onClick={() => setIsTrashOpen(false)}
      />
      <div
        className={`fixed bottom-0 flex flex-col max-w-full bg-white bg-clip-padding shadow-2xl outline-none duration-500 text-gray-700 top-0 right-0 border-none w-96 z-50 ${
          isTrashOpen
            ? "translate-x-0"
            : "opacity-0 pointer-events-none translate-x-full"
        }`}
      >
        <div
          className={`flex w-full justify-between py-5 px-6 border-b-[1px] ${
            isButtonDisable ? "mb-10" : ""
          } `}
        >
          <h5 className="text-base font-medium">Trash</h5>
          <svg
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={() => {
              setIsTrashOpen(false);
            }}
          >
            <path
              d="M10.1529 6.74077L16.2696 2.2257C16.6496 1.94543 16.6496 1.49066 16.2696 1.21039C15.8896 0.929871 15.2741 0.929871 14.8941 1.21039L8.77746 5.72545L2.66048 1.21039C2.28046 0.929871 1.66504 0.929871 1.28502 1.21039C0.904995 1.49066 0.904995 1.94543 1.28502 2.2257L7.40199 6.74077L1.28502 11.2558C0.904995 11.5361 0.904995 11.9909 1.28502 12.2711C1.47503 12.4112 1.72405 12.4813 1.97275 12.4813C2.22145 12.4813 2.47047 12.4112 2.66048 12.2709L8.77746 7.75584L14.8941 12.2709C15.0841 12.4112 15.3332 12.4813 15.5819 12.4813C15.8306 12.4813 16.0796 12.4112 16.2696 12.2709C16.6496 11.9906 16.6496 11.5359 16.2696 11.2556L10.1529 6.74077Z"
              fill="#B4B4B4"
              stroke="#B4B4B4"
              stroke-width="0.7"
            />
          </svg>
        </div>
        {!isButtonDisable && (
          <div className="flex w-full justify-between items-center py-5 pr-10 pl-6">
            {allSelected ? (
              <h4
                className="text-[#5381CB] text-sm font-semibold cursor-pointer hover:underline"
                onClick={onUnselectAll}
              >
                Unselect All
              </h4>
            ) : (
              <h4
                className={`text-[#5381CB] text-sm font-semibold cursor-pointer hover:underline ${
                  trashedTasks.length == 0 ? "opacity-20" : "opacity-100"
                }`}
                onClick={trashedTasks.length == 0 ? null : onSelectAll}
              >
                Select All
              </h4>
            )}
            <div className="flex gap-5">
              <button
                className={`bg-[#28A745] cursor-pointer flex px-3 py-2 rounded-md items-center justify-center gap-2 text-white text-xs font-medium ${
                  selectedItems.length == 0 ? "opacity-20" : "opacity-100"
                }`}
                onClick={selectedItems.length === 0 ? null : onRestore}
              >
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.37222 12.3574C1.39191 12.6668 1.53064 12.9572 1.76017 13.1695C1.98971 13.3818 2.2928 13.5 2.60774 13.5H8.94782C9.26276 13.5 9.56585 13.3818 9.79538 13.1695C10.0249 12.9572 10.1636 12.6668 10.1833 12.3574L10.7302 3.75001H0.825397L1.37222 12.3574ZM3.18036 7.89376L5.48631 5.49942C5.5235 5.45989 5.56858 5.42834 5.61874 5.40677C5.6689 5.3852 5.72304 5.37406 5.77778 5.37406C5.83251 5.37406 5.88666 5.3852 5.93682 5.40677C5.98697 5.42834 6.03206 5.45989 6.06925 5.49942L8.3752 7.89376C8.63571 8.1629 8.45 8.62501 8.08373 8.62501H6.60317V11.4688C6.60317 11.5765 6.55969 11.6798 6.4823 11.756C6.4049 11.8322 6.29993 11.875 6.19048 11.875H5.36508C5.25562 11.875 5.15065 11.8322 5.07326 11.756C4.99586 11.6798 4.95238 11.5765 4.95238 11.4688V8.62501H3.47183C3.10298 8.62501 2.91984 8.1629 3.18036 7.89376ZM11.1429 1.31251H8.04762L7.80516 0.837701C7.7538 0.736193 7.67468 0.650806 7.57671 0.591148C7.47874 0.531489 7.3658 0.499924 7.2506 0.500006H4.30238C4.18743 0.499511 4.07466 0.530933 3.97702 0.590665C3.87939 0.650397 3.80084 0.736018 3.7504 0.837701L3.50794 1.31251H0.412698C0.303244 1.31251 0.198273 1.35531 0.120877 1.43149C0.0434806 1.50768 0 1.61101 0 1.71876L0 2.53126C0 2.639 0.0434806 2.74233 0.120877 2.81852C0.198273 2.8947 0.303244 2.93751 0.412698 2.93751H11.1429C11.2523 2.93751 11.3573 2.8947 11.4347 2.81852C11.5121 2.74233 11.5556 2.639 11.5556 2.53126V1.71876C11.5556 1.61101 11.5121 1.50768 11.4347 1.43149C11.3573 1.35531 11.2523 1.31251 11.1429 1.31251Z"
                    fill="white"
                  />
                </svg>
                {restoreLoading ? "Loading.." : "Restore"}
              </button>
              <button
                className={`flex items-center  cursor-pointer ${
                  deleteLoading ? "justify-start" : "justify-center"
                } gap-2 text-[#656A6F] text-xs font-medium px-3 py-2 w-44 rounded-md border-[#DC3545] border ${
                  selectedItems.length == 0 ? "opacity-20" : "opacity-100"
                }`}
                onClick={selectedItems.length === 0 ? null : onDeletePermanent}
              >
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.30376 3.94941L7.12782 4.3125H4.78196C4.34944 4.3125 4 4.67559 4 5.125C4 5.57441 4.34944 5.9375 4.78196 5.9375H14.1654C14.5979 5.9375 14.9474 5.57441 14.9474 5.125C14.9474 4.67559 14.5979 4.3125 14.1654 4.3125H11.8196L11.6436 3.94941C11.5117 3.67266 11.2404 3.5 10.9447 3.5H8.00263C7.70696 3.5 7.43571 3.67266 7.30376 3.94941ZM14.1654 6.75H4.78196L5.3 15.3574C5.3391 15.9998 5.85226 16.5 6.47049 16.5H12.4769C13.0951 16.5 13.6083 15.9998 13.6474 15.3574L14.1654 6.75Z"
                    fill="#656A6F"
                  />
                </svg>
                {deleteLoading ? "Loading..." : "Delete Permanently"}
              </button>
            </div>
          </div>
        )}

        {trashedTasks.length !== 0 ? (
          trashedTasks.map((task) => (
            <div className="flex justify-between px-10 items-center w-full gap-4">
              <div className="w-3/4">
                <TasksListComponent
                  item={task}
                  isTrashed={true}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  isButtonDisable={isButtonDisable}
                />
              </div>
              <svg
                width="21"
                height="16"
                viewBox="0 0 21 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8554 6.22187L12.9174 0.230642C12.3101 -0.293839 11.3529 0.131934 11.3529 0.946664V4.10234C5.02095 4.17483 0 5.44388 0 11.4446C0 13.8666 1.56028 16.266 3.28497 17.5205C3.82317 17.9119 4.59021 17.4206 4.39177 16.786C2.60431 11.0696 5.23957 9.55206 11.3529 9.46411V12.9297C11.3529 13.7457 12.3108 14.1696 12.9174 13.6457L19.8554 7.65391C20.2918 7.27698 20.2924 6.59931 19.8554 6.22187Z"
                  fill="#9E9E9E"
                />
              </svg>
              <p className="px-2 whitespace-nowrap w-1/5">
                {task.milestone_id.length !== 0
                  ? task?.milestone_id[0]?.title
                  : "null"}
              </p>
            </div>
          ))
        ) : (
          <p className="text-center py-16 font-medium opacity-75">
            No Trashed Tasks
          </p>
        )}
      </div>
    </div>
  );
}

export default TrashedTasks;

import React, { useState } from "react";
import EditMilestone from "./EditMilestone";
import DeletionModal from "./DeletionModal";
import EditGroup from "./EditGroup";
import { useDispatch } from "react-redux";


function TaskListOptions({
  milestone,
  data,
  setCurrentMilestone,
  groupTasks,
  setRenderer,
  renderer,
  currentMilestone,
  correspondingDetail,
  isButtonDisable,
}) {
  const dispatch = useDispatch();
  const [isMilestoneOptions, setIsMilestoneOptions] = useState(false);
  const [isGroupOptions, setIsGroupOptions] = useState(false);
  const [showEditMilestoneModal, setShowEditMilestoneModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
 

  const handleOptionsClick = () => {
    if (milestone) {
      setIsMilestoneOptions(!isMilestoneOptions);
      setIsGroupOptions(false);
    } else if (groupTasks) {
      setIsMilestoneOptions(!isMilestoneOptions);
      setIsGroupOptions(true);
    }
  };

  const handleEdit = () => {
    setIsMilestoneOptions(false);
    if (milestone) {
      setShowEditMilestoneModal(true);
    } else if (groupTasks) {
      setShowEditGroupModal(true);
    }
  };

  const currentMilestoneSelect = async (id) => {
    const responseData =
      await dispatch.projectDetails.projectMilestoneUpdataion({
        project_id: data?.project?.id,
        milestone_id: id,
        is_current_milestone: currentMilestone ? false : true,
      });

    if (responseData?.success === true) {
      setCurrentMilestone(id);
      setRenderer(!renderer);
      setIsMilestoneOptions(false);
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="dropdown relative">
          {!isButtonDisable && (
            <button
              className="dropdown-toggle transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              onClick={() => handleOptionsClick()}
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="14"
                height="4"
                viewBox="0 0 14 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 0.25C0.7875 0.25 0 1.0375 0 2C0 2.9625 0.7875 3.75 1.75 3.75C2.7125 3.75 3.5 2.9625 3.5 2C3.5 1.0375 2.7125 0.25 1.75 0.25ZM12.25 0.25C11.2875 0.25 10.5 1.0375 10.5 2C10.5 2.9625 11.2875 3.75 12.25 3.75C13.2125 3.75 14 2.9625 14 2C14 1.0375 13.2125 0.25 12.25 0.25ZM7 0.25C6.0375 0.25 5.25 1.0375 5.25 2C5.25 2.9625 6.0375 3.75 7 3.75C7.9625 3.75 8.75 2.9625 8.75 2C8.75 1.0375 7.9625 0.25 7 0.25Z"
                  fill="#C7CED9"
                  className="fill-current dots hover:text-blue-500"
                />
              </svg>
            </button>
          )}

          {isMilestoneOptions && (
            <div className="">
              <div
                className="fixed inset-0 z-10"
                onClick={() => setIsMilestoneOptions(!isMilestoneOptions)}
              ></div>
              <ul
                className="dropdown-menu min-w-max absolute bg-white text-base z-40 float-left py-1 list-none text-left rounded-xl shadow-2xl mt-1 m-0 bg-clip-padding border-none -left-24 top-5"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    className="dropdown-item text-sm py-2 px-4 pr-14  font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 border-b flex gap-2"
                    onClick={() => {
                      handleEdit();
                    }}
                    type="button"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.9"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.497 6.13598L15.4426 7.1948L12.7973 4.54465L13.8517 3.48584C14.0782 3.26973 14.433 3.26973 14.6595 3.48584L16.497 5.32853C16.6033 5.4355 16.663 5.58062 16.663 5.73194C16.663 5.88327 16.6033 6.02839 16.497 6.13536V6.13598ZM4.40652 13.6191L6.38581 15.6052L3.6296 16.3829L4.40652 13.6216V13.6191ZM17.4404 4.38451L15.603 2.5412C14.8502 1.8196 13.6673 1.8196 12.9146 2.5412L3.5977 11.8707C3.51652 11.9514 3.4571 12.0516 3.42505 12.1618L2.02536 17.1648C1.95982 17.3969 2.02342 17.6466 2.19184 17.8183C2.37579 17.9659 2.61347 18.0278 2.84544 17.9884L7.83993 16.5801C7.9512 16.5494 8.05299 16.4909 8.1359 16.4101L17.4404 7.08058C17.7985 6.72459 18 6.23911 18 5.73255C18 5.22598 17.7985 4.7405 17.4404 4.38451Z"
                        fill="#A3A8AE"
                      />
                    </svg>
                    Edit
                  </button>
                </li>

                <li>
                  <button
                    className="dropdown-item text-sm py-2 px-4 pr-14 font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 flex gap-2 border-b"
                    type="button"
                    onClick={() => {
                      setShowDeletionModal(true);
                      setIsMilestoneOptions(false);
                    }}
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9033 16.8037H2.8962C2.19382 16.8037 1.70215 16.2418 1.70215 15.6097V5.07394H12.0974V15.5394C12.0974 16.2418 11.5355 16.8037 10.9033 16.8037Z"
                        stroke="#A3A8AE"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8 4.65238V3.38809C12.8 2.89643 12.3786 2.475 11.8869 2.475H9.2881L9.00714 1.42143C8.86667 1.14048 8.65595 1 8.30476 1H5.49524C5.21429 1 4.93333 1.14048 4.79286 1.42143L4.51191 2.40476H1.9131C1.42143 2.40476 1 2.82619 1 3.31786V4.65238C1 4.86309 1.21071 5.07381 1.42143 5.07381H12.3786C12.6595 5.07381 12.8 4.86309 12.8 4.65238Z"
                        stroke="#A3A8AE"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.65234 7.6021V14.2747"
                        stroke="#A3A8AE"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.8999 7.60216V14.2748"
                        stroke="#A3A8AE"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.14771 7.60216V14.2748"
                        stroke="#A3A8AE"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Delete
                  </button>
                </li>
                {!isGroupOptions && (
                  <li>
                    <button
                      className="dropdown-item text-sm py-2 px-4 pr-14 font-medium w-full whitespace-nowrap bg-transparent text-gray-500 hover:bg-gray-100 flex gap-2 "
                      type="button"
                      onClick={() => {
                        currentMilestoneSelect(milestone._id);
                      }}
                    >
                      <svg
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.778 0.085C12.8462 0.130637 12.9022 0.192369 12.9409 0.264746C12.9796 0.337123 12.9999 0.417918 13 0.5V8C13 8.09985 12.9701 8.19741 12.9141 8.28011C12.8581 8.36281 12.7787 8.42685 12.686 8.464L12.683 8.465L12.677 8.468L12.654 8.477C12.5226 8.52934 12.3902 8.57934 12.257 8.627C11.993 8.722 11.626 8.85 11.21 8.977C10.394 9.229 9.331 9.5 8.5 9.5C7.653 9.5 6.952 9.22 6.342 8.975L6.314 8.965C5.68 8.71 5.14 8.5 4.5 8.5C3.8 8.5 2.862 8.73 2.063 8.977C1.70532 9.08869 1.35084 9.2104 1 9.342V15.5C1 15.6326 0.947321 15.7598 0.853553 15.8536C0.759785 15.9473 0.632608 16 0.5 16C0.367392 16 0.240215 15.9473 0.146447 15.8536C0.0526785 15.7598 0 15.6326 0 15.5V0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526784 0.367392 0 0.5 0C0.632608 0 0.759785 0.0526784 0.853553 0.146447C0.947321 0.240215 1 0.367392 1 0.5V0.782C1.226 0.703 1.496 0.612 1.79 0.522C2.606 0.272 3.67 0 4.5 0C5.34 0 6.024 0.277 6.621 0.519L6.664 0.537C7.286 0.788 7.828 1 8.5 1C9.2 1 10.138 0.77 10.937 0.523C11.3922 0.380471 11.8422 0.221702 12.286 0.047L12.305 0.04L12.309 0.038H12.31"
                          fill="#BDBDBD"
                        />
                      </svg>
                      {currentMilestone
                        ? "Unmark as current milestone"
                        : "Mark as current milestone"}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      {showEditMilestoneModal && (
        <EditMilestone
          showEditMilestoneModal={showEditMilestoneModal}
          setShowEditMilestoneModal={setShowEditMilestoneModal}
          correspondingMilestone={milestone}
          setIsMilestoneOptions={setIsMilestoneOptions}
          setRenderer={setRenderer}
          renderer={renderer}
          correspondingDetail={correspondingDetail}
        />
      
      )}

      {showDeletionModal && (
        <DeletionModal
          deleteMilestone={milestone}
          deleteGroup={groupTasks}
          setShowDeletionModal={setShowDeletionModal}
          setRenderer={setRenderer}
          renderer={renderer}
        />
      )}
      {showEditGroupModal && (
        <EditGroup
          showEditGroupModal={showEditGroupModal}
          setShowEditGroupModal={setShowEditGroupModal}
          correspondingGroup={groupTasks}
          setRenderer={setRenderer}
          renderer={renderer}
        />
      )}
    </div>
  );
}

export default TaskListOptions;

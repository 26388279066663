import React, { useState } from "react";

const DeleteModal = ({
  isDelete,
  confirmDelete,
  setIsDelete,
  editingItemid,
}) => {
  
  const handleConfirmDelete = () => {
    confirmDelete(isDelete);
  };

  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsDelete("");
    },300);
  };

  return (
    <>
     
        {isDelete === editingItemid && (
          <div id={`Note_${editingItemid}`} className="">
            <div
        className="fixed inset-0 bg-black opacity-5 z-50"
        onClick={() => handleClose()}
      />
      <div
        className={`fixed w-fit inset-1/2 top-56 z-50 flex items-center justify-center h-fit overflow-y-hidden ${
          isVisible ? " animate-slideIn" : "animate-slideOut"
        }`}
      >
              <div className="flex flex-col p-5 shadow-lg  bg-white rounded-lg">
                <div className="flex justify-end items-end pb-1 cursor-pointer  w-[350px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      handleClose();
                    }}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="black"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>

                <p className="text-sm px-5 p-2 text-center">
                  Do you want to delete this requirement?
                </p>

                <div className="flex p-5 justify-center items-center gap-8">
                  <button
                    onClick={() => handleConfirmDelete()}
                    className="p-1 px-4 border bg-green-600 text-sm text-white rounded-md hover:bg-green-700"
                  >
                    Confirm
                  </button>

                  <button
                    onClick={() => setIsDelete("")}
                    className="p-1 px-4 border rounded-md text-sm hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      
    </>
  );
};

export default DeleteModal;

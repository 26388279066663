import React, { startTransition, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TasksLists from "./TasksLists";
import TaskBoard from "./TaskBoard";
import AddTask from "./AddTask";
import { connect, useDispatch, useSelector } from "react-redux";
import MileStoneModal from "./MileStoneModal";
import Filter from "./Filters/Filter";
import TrashedTasks from "./Trash/Index";
import TaskStatus from "./Status/TaskStatus";


const Tasks = ({
  data,
  getTasksListData,
  isAddTaskShown,
  setAddTaskIsShown,
}) => {
  const [status, setStatus] = useState("task");
  const [showTaskFilterMenu, setShowTaskFilterMenu] = useState(false);
  const [showTimeLogFilterData, setShowTimeLogFilterData] = useState(false);
  const [currentMilestone, setCurrentMilestone] = useState("");
  const [onRender, setOnRender] = useState(false);
  const [milestoneNames, setMilestoneNames] = useState([]);
  const [showMilestone, setShowMilestone] = useState([]);
  const [isButtonDisable, setIsButtonDisable] = useState(false);

  const permissionsData = useSelector((state) => state.permissions.permission);

  useEffect(() => {
    const fetchData = () => {
      if (permissionsData?.length === 0) return;
      if (
        !permissionsData?.data?.permissions?.find(
          (item) => item.action === "Handle-Milestone-And-Tasks"
        )?.allowed
      ) {
        setIsButtonDisable(true);
      }
    };
    fetchData();
  }, [permissionsData]);

  const [filterMenu, setFilterMenu] = useState({
    milestone_id: [],
    milestone_name: [],
    assigned_to: [],
    assigned_to_name: [],
    task_status: [],
    is_task_finished: false,
    task_due_date: "",
  });
  const [timeFilterMenu, setTimeFilterMenu] = useState({
    user_id: "",
    user_name: [],
    start_date: "",
    end_date: "",
    is_bilable: "",
  });
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [timeLogFilteredData, setTimeLogFilteredData] = useState([]);
  const [showFilterTasks, setShowFilterTasks] = useState(false);
  const [filterCount, setFilteredCount] = useState(0);
  const [isOption, setIsOption] = useState(false);
  const [isTrashOpen, setIsTrashOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    if (data && data.project && data.project._id) {
      const fetchData = async () => {
        const res = await getTasksListData({ project_id: data.project._id });
        setTaskData(res.data);

        //filter by status
        const groupedFields = [
          ["milestone_id", "milestone_name"],
          ["assigned_to", "assigned_to_name"],
          ["is_task_finished", "task_due_date"],
        ];

        let count = 0;

        groupedFields.forEach((group) => {
          if (
            group.some(
              (key) =>
                (filterMenu[key] !== "" && filterMenu[key].length > 0) ||
                filterMenu[key] === true
            )
          ) {
            count++;
          }
        });

        const individualFields = ["task_status"];

        individualFields.forEach((key) => {
          if (filterMenu[key].length > 0) {
            count++;
          }
        });

        //filter by time
        const groupFields = [
          ["user_id", "user_name"],
          ["start_date", "end_date"],
        ];
        groupFields.forEach((group) => {
          if (
            group.some(
              (key) =>
                (timeFilterMenu[key] !== "" &&
                  timeFilterMenu[key].length > 0) ||
                timeFilterMenu[key] === true
            )
          ) {
            count++;
          }
        });

        const singleFields = ["is_bilable"];

        singleFields.forEach((key) => {
          if (
            timeFilterMenu[key] == true ||
            (timeFilterMenu[key] == false && timeFilterMenu[key] !== "")
          ) {
            count++;
          }
        });

        setFilteredCount(count);
      };
      fetchData();
    }
  }, [data, filterMenu, timeLogFilteredData]);

  const switchTaskView = () => {
    switch (status) {
      case "task":
        return (
          <TasksLists
            data={data}
            onRender={onRender}
            setTaskData={setTaskData}
            isAddTaskShown={isAddTaskShown}
            setAddTaskIsShown={setAddTaskIsShown}
            showFilterTasks={showFilterTasks}
            filteredData={filteredData}
            showMilestone={showMilestone}
            setShowMilestone={setShowMilestone}
            milestoneNames={milestoneNames}
            setMilestoneNames={setMilestoneNames}
            setFilteredData={setFilteredData}
            timeLogFilteredData={timeLogFilteredData}
            showTimeLogFilterData={showTimeLogFilterData}
            currentMilestone={currentMilestone}
            setCurrentMilestone={setCurrentMilestone}
            isButtonDisable={isButtonDisable}
          />
        );
      case "board":
        return (
          <TaskBoard
            data={data}
            mileStones={milestoneNames}
            isAddTaskShown={isAddTaskShown}
            setAddTaskIsShown={setAddTaskIsShown}
            currentMilestone={showMilestone}
          />
        );
      case "filters":
        return (
          <Filter
            data={data}
            setShowFilterTasks={setShowFilterTasks}
            filterMenu={filterMenu}
            setFilterMenu={setFilterMenu}
            timeFilterMenu={timeFilterMenu}
            setTimeFilterMenu={setTimeFilterMenu}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex items-center gap-1 mb-4">
        <header className="px-6 border-b md:flex grow">
          <button
            onClick={() => setStatus("task")}
            className={`${
              status == "task"
                ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                : "text-gray-500 border-white border-b-2"
            }  mr-8 font-title text-sm  pb-3 font-normal`}
          >
            Tasks
          </button>
          <button
            onClick={() => setStatus("board")}
            className={`${
              status == "board"
                ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                : "text-gray-500 border-white border-b-2"
            } mr-8 font-title text-sm  pb-3 font-normal`}
          >
            Board
          </button>
          <button
            onClick={() => setStatus("filters")}
            className={`${
              status == "filters"
                ? "text-secondary-blue-400 border-primary-navyBlue border-b-2"
                : "text-gray-500 border-white border-b-2"
            } mr-8 font-title text-sm  pb-3 font-normal`}
          >
            Filters
          </button>
        </header>

        {status !== "filters" && (
          <button
            onClick={() => setAddTaskIsShown(true)}
            className={`border border-primary-navyBlue rounded-md text-xs text-primary-navyBlue py-2 px-3 flex gap-2 items-center 
    ${isButtonDisable ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100"}`}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            disabled={isButtonDisable}
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                fill="#1768E5"
              />
            </svg>
            <p className="hidden sm:block"> Add Tasks</p>
          </button>
        )}

        {status === "task" && (
          <div className="flex gap-1">
            <button
              className={`border hover:bg-blue-800 border-primary-navyBlue bg-primary-navyBlue rounded-md text-xs text-white py-2 px-3 flex gap-2 items-center  ${
                isButtonDisable ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={() => setShowMilestoneModal(true)}
              disabled={isButtonDisable}
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.24989 0H5.75019V5.25H0.501221V6.75H5.75019V12H7.24989V6.75H12.4989V5.25H7.24989V0Z"
                  fill="#ffffff"
                />
              </svg>
              <p className="hidden sm:block"> Add Milestone</p>
            </button>
            <svg
              width="38"
              height="34"
              viewBox="0 0 38 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer z-10 hover:text-blue-500"
              onClick={() => setIsOption(!isOption)}
            >
              <rect
                x="0.496826"
                y="0.5"
                width="37"
                height="33"
                rx="5.5"
                fill="white"
              />
              <rect
                x="0.496826"
                y="0.5"
                width="37"
                height="33"
                rx="5.5"
                stroke="#E8E8E8"
              />
              <path
                d="M13.7468 15.25C12.7843 15.25 11.9968 16.0375 11.9968 17C11.9968 17.9625 12.7843 18.75 13.7468 18.75C14.7093 18.75 15.4968 17.9625 15.4968 17C15.4968 16.0375 14.7093 15.25 13.7468 15.25ZM24.2468 15.25C23.2843 15.25 22.4968 16.0375 22.4968 17C22.4968 17.9625 23.2843 18.75 24.2468 18.75C25.2093 18.75 25.9968 17.9625 25.9968 17C25.9968 16.0375 25.2093 15.25 24.2468 15.25ZM18.9968 15.25C18.0343 15.25 17.2468 16.0375 17.2468 17C17.2468 17.9625 18.0343 18.75 18.9968 18.75C19.9593 18.75 20.7468 17.9625 20.7468 17C20.7468 16.0375 19.9593 15.25 18.9968 15.25Z"
                fill="#3E3E3E"
                className="fill-current dots hover:text-blue-500"
              />
            </svg>
          </div>
        )}
        <div className="relative">
          <div
            onClick={() => {
              setIsOption(!isOption);
              setIsEditOpen(false);
            }}
            className={`fixed inset-0 bg-gray-500 opacity-5 z-[5] ${
              isOption ? "" : "hidden"
            }`}
          ></div>
          <div
            className={`flex flex-col items-center absolute font-medium text-xs gap-2 z-10 bg-white shadow-lg rounded-sm cursor-pointer py-2 w-40 -left-20 top-7  ${
              isOption
                ? "animate-slideIn"
                : "animate-slideOut pointer-events-none"
            } `}
          >
            <div
              className="pl-3 flex gap-2 justify-start w-full"
              onClick={() => {
                setIsTrashOpen(true);
                setIsOption(false);
              }}
            >
              <svg
                width="13"
                height="14"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9033 16.8035H2.8962C2.19382 16.8035 1.70215 16.2416 1.70215 15.6094V5.07373H12.0974V15.5392C12.0974 16.2416 11.5355 16.8035 10.9033 16.8035Z"
                  stroke="#A3A8AE"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.8 4.65238V3.38809C12.8 2.89643 12.3786 2.475 11.8869 2.475H9.2881L9.00714 1.42143C8.86667 1.14048 8.65595 1 8.30476 1H5.49524C5.21429 1 4.93333 1.14048 4.79286 1.42143L4.51191 2.40476H1.9131C1.42143 2.40476 1 2.82619 1 3.31786V4.65238C1 4.86309 1.21071 5.07381 1.42143 5.07381H12.3786C12.6595 5.07381 12.8 4.86309 12.8 4.65238Z"
                  stroke="#A3A8AE"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.65234 7.60205V14.2747"
                  stroke="#A3A8AE"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.8999 7.60205V14.2747"
                  stroke="#A3A8AE"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.14771 7.60205V14.2747"
                  stroke="#A3A8AE"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Trash</p>
            </div>
            <ul className="border w-full" />
            <button
              className={`flex gap-2 justify-start w-full pl-3 ${
                isButtonDisable
                  ? "cursor-not-allowed opacity-50"
                  : "hover:bg-gray-100"
              }`}
              onClick={() => {
                setIsEditOpen(true);
                setIsOption(false);
              }}
              disabled={isButtonDisable}
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.3034 0.622289C9.80966 0.00354213 8.16594 -0.15835 6.58014 0.157084C4.99435 0.472518 3.5377 1.25111 2.39441 2.39441C1.25111 3.5377 0.472518 4.99435 0.157084 6.58014C-0.15835 8.16594 0.00354212 9.80966 0.622289 11.3034C1.24103 12.7972 2.28885 14.074 3.63322 14.9723C4.97759 15.8705 6.55814 16.35 8.175 16.35H8.475V14.4375H8.175C6.9364 14.4375 5.72561 14.0702 4.69575 13.3821C3.66588 12.694 2.8632 11.7159 2.38921 10.5716C1.91521 9.42724 1.7912 8.16806 2.03284 6.95325C2.27448 5.73845 2.87092 4.62257 3.74675 3.74675C4.62257 2.87092 5.73845 2.27448 6.95325 2.03284C8.16806 1.7912 9.42724 1.91521 10.5716 2.38921C11.7159 2.8632 12.694 3.66588 13.3821 4.69575C14.0702 5.72561 14.4375 6.9364 14.4375 8.175V8.475H16.35V8.175C16.35 6.55814 15.8705 4.97759 14.9723 3.63322C14.074 2.28885 12.7972 1.24103 11.3034 0.622289ZM11.8805 6.47531L10.5309 5.12574L6.8625 8.79417L5.16281 7.09449L3.81324 8.44406L6.8625 11.4933L11.8805 6.47531ZM16.3591 10.8226C15.5848 10.5018 14.7328 10.4179 13.9108 10.5814C13.0888 10.7449 12.3338 11.1485 11.7411 11.7411C11.1485 12.3338 10.7449 13.0888 10.5814 13.9108C10.4179 14.7328 10.5018 15.5848 10.8226 16.3591C11.1433 17.1334 11.6864 17.7952 12.3833 18.2609C13.0801 18.7265 13.8997 18.975 14.7378 18.975C15.8612 18.9737 16.9382 18.5269 17.7326 17.7326C18.5269 16.9382 18.9737 15.8609 18.975 14.7375C18.975 13.8994 18.7265 13.0801 18.2609 12.3833C17.7952 11.6864 17.1334 11.1433 16.3591 10.8226ZM13.4458 12.8043C13.8281 12.5489 14.2774 12.4126 14.7372 12.4125C15.3537 12.4132 15.9447 12.6584 16.3807 13.0943C16.8166 13.5303 17.0618 14.1213 17.0625 14.7378C17.0624 15.1976 16.9261 15.6469 16.6707 16.0292C16.4152 16.4115 16.0521 16.7095 15.6272 16.8855C15.2024 17.0615 14.7349 17.1075 14.2839 17.0178C13.8329 16.9281 13.4186 16.7067 13.0935 16.3815C12.7683 16.0564 12.5469 15.6421 12.4572 15.1911C12.3675 14.7401 12.4135 14.2726 12.5895 13.8478C12.7655 13.4229 13.0635 13.0598 13.4458 12.8043Z"
                  fill="#98A2B2"
                />
              </svg>
              <p>Edit Status</p>
            </button>
          </div>
        </div>
      </div>

      <TrashedTasks
        setOnRender={setOnRender}
        onRender={onRender}
        setIsTrashOpen={setIsTrashOpen}
        isTrashOpen={isTrashOpen}
        isButtonDisable={isButtonDisable}
      />
      {isEditOpen && (
        <TaskStatus
          setIsEditOpen={setIsEditOpen}
          setIsOption={setIsOption}
          isOption={isOption}
          setOnRender={setOnRender}
          onRender={onRender}
        />
      )}

      <AddTask
        data={data}
        isAddTaskShown={isAddTaskShown}
        setAddTaskIsShown={setAddTaskIsShown}
        currentMilestone={currentMilestone}
        status={status}
        setOnRender={setOnRender}
        onRender={onRender}
      />
      {showMilestoneModal && (
        <MileStoneModal
          setModal={setShowMilestoneModal}
          setOnRender={setOnRender}
          onRender={onRender}
        />
      )}

      {showTaskFilterMenu && (
        <Filter
          data={data}
          showTaskFilterMenu={showTaskFilterMenu}
          setShowTaskFilterMenu={setShowTaskFilterMenu}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          showFilterTasks={showFilterTasks}
          setShowFilterTasks={setShowFilterTasks}
          filterMenu={filterMenu}
          setFilterMenu={setFilterMenu}
          setTimeLogFilteredData={setTimeLogFilteredData}
          showTimeLogFilterData={showTimeLogFilterData}
          setShowTimeLogFilterData={setShowTimeLogFilterData}
          timeLogFilteredData={timeLogFilteredData}
          timeFilterMenu={timeFilterMenu}
          setTimeFilterMenu={setTimeFilterMenu}
        />
      )}
      {switchTaskView()}
    </>
  );
};

// export default Tasks;

const mapState = (state) => ({
  tasksListData: state.projectDetails.tasksList.data,
});

const mapDispatch = (dispatch) => ({
  getTasksListData: dispatch.projectDetails.getTasksList,
});

export default connect(mapState, mapDispatch)(Tasks);

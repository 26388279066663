import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const calculateTimeDifference = (startTime, endTime) => {
  if (!startTime || !endTime) return { hours: 0, minutes: 0 };

  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  const startDate = new Date();
  startDate.setHours(startHours, startMinutes, 0);

  const endDate = new Date();
  endDate.setHours(endHours, endMinutes, 0);

  if (endDate < startDate) endDate.setDate(endDate.getDate() + 1);

  const diffMinutes = Math.floor((endDate - startDate) / 60000);
  return { hours: Math.floor(diffMinutes / 60), minutes: diffMinutes % 60 };
};

function LogTimeModal({
  isLogVisible,
  setIsLogVisible,
  task,
  setLogAdded,
  logAdded,
}) {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(true);
  const [currentDate, setCurrentDate] = useState("");
  const [isBillable, setIsBillable] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [spentHours, setSpentHours] = useState(0);
  const [spentMinutes, setSpentMinutes] = useState(0);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const usersData = useSelector((state) => state.auth.userInfoData);

  useEffect(() => {
    const now = new Date();
    const formattedTime = now.toTimeString().slice(0, 5);

    const startDate = new Date(now);
    startDate.setHours(now.getHours() - 0);

    const formattedStartTime = startDate.toTimeString().slice(0, 5);

    setCurrentDate(now.toISOString().split("T")[0]);
    setStartTime(formattedStartTime);
    setEndTime(formattedTime);
  }, []);

  useEffect(() => {
    if (!startTime || !endTime) {
      setSpentHours(0);
      setSpentMinutes(0);
      return;
    }

    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    const startDate = new Date();
    startDate.setHours(startHours, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHours, 0, 0);

    const { hours, minutes } = calculateTimeDifference(startTime, endTime);

    if (hours >= 9) {
      setStartTime(endTime);
    } else {
      setSpentHours(hours);
      setSpentMinutes(minutes);
    }
  }, [startTime, endTime]);

  const handleCheckboxClick = () => {
    setIsBillable(!isBillable);
  };

  const handleStartChange = (e) => {
    let selectedTime = e.target.value;
    const [endHours, endMinutes] = endTime.split(":").map(Number);
    const [selctedHours, selectedMinutes] = selectedTime.split(":").map(Number);
    if (selctedHours > endHours) {
      return;
    } else {
      setStartTime(selectedTime);
    }
  };

  const handleEndChange = (e) => {
    let selectedTime = e.target.value;
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [selctedHours, selectedMinutes] = selectedTime.split(":").map(Number);
    if (selctedHours < startHours) {
      return;
    } else {
      setEndTime(selectedTime);
    }
  };

  const handleSpentHours = (e) => {
    const hours = parseInt(e.target.value);
    setSpentHours(hours);

    if (endTime) {
      const [endHours, endMinutes] = endTime.split(":").map(Number);
      const newStartTime = new Date();
      newStartTime.setHours(endHours - hours, endMinutes);
      setStartTime(newStartTime.toTimeString().slice(0, 5));
    }
  };

  const handleSpentMinutes = (e) => {
    const minutes = parseInt(e.target.value);

    if (isNaN(minutes)) return;
    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      const [endHours, endMinutes] = endTime.split(":").map(Number);

      setSpentMinutes(minutes);

      const newStartTime = new Date();
      newStartTime.setHours(startHours, endMinutes - minutes, 0);

      setStartTime(newStartTime.toTimeString().slice(0, 5));
    }
  };


  // useEffect(() => {
  //   const today = new Date().toISOString().split("T")[0];
  //   setCurrentDate(today);

  //   const now = new Date();
  //   let hours = now.getHours();
  //   let minutes = now.getMinutes();

  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12 || 12;

  //   const formattedTime = `${String(hours).padStart(2, "0")}:${String(
  //     now.getMinutes()
  //   ).padStart(2, "0")} ${ampm}`;

  //   setStartTime(formattedTime);
  //   setEndTime(formattedTime);
  // }, []);

  // useEffect(() => {
  //   if (!startTime || !endTime) {
  //     setSpentHours(0);
  //     setSpentMinutes(0);
  //     return;
  //   }

  //   const { hours, minutes } = calculateTimeDifference(startTime, endTime);
  //   setSpentHours(hours);
  //   setSpentMinutes(minutes);
  // }, [startTime, endTime]);

  // const handleStartChange = (e) => {
  //   let selectedTime = e.target.value;
  //   const [hours, minutes] = selectedTime.split(":").map(Number);
  //   if (hours > 12) {
  //     const newHour = hours % 12 === 0 ? 12 : hours % 12;
  //     const formattedTime = `${newHour.toString().padStart(2, "0")}:${minutes
  //       .toString()
  //       .padStart(2, "0")}`;

  //     const formattedTimeWithZone = `${formattedTime} PM`;

  //     setStartTime(formattedTimeWithZone);
  //   } else {
  //     const timeZone = startTime?.split(" ")[1];
  //     const updatedTime = `${selectedTime} ${timeZone}`;
  //     setStartTime(updatedTime);
  //   }
  // };

  // const handleEndChange = (e) => {
  //   let selectedTime = e.target.value;
  //   const [hours, minutes] = selectedTime.split(":").map(Number);
  //   if (hours > 12) {
  //     const newHour = hours % 12 === 0 ? 12 : hours % 12;
  //     const formattedTime = `${newHour.toString().padStart(2, "0")}:${minutes
  //       .toString()
  //       .padStart(2, "0")}`;

  //     const formattedTimeWithZone = `${formattedTime} PM`;

  //     setEndTime(formattedTimeWithZone);
  //   } else {
  //     const timeZone = endTime?.split(" ")[1];
  //     const updatedTime = `${selectedTime} ${timeZone}`;
  //     setEndTime(updatedTime);
  //   }
  // };

  const SubmitLogTime = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch.time.AddLogTime({
        user_id: usersData ? usersData._id : null,
        project_id: task ? task?.project_id : null,
        task_id: task ? task?._id : null,
        task_description: description,
        task_start_time: startTime,
        task_end_time: endTime,
        task_time_spent: `${spentHours}:${spentMinutes}`,
        isBillable: isBillable,
        isBilled: false,
        date: currentDate,
      });
      if (response.timelog) {
        toast.success(response.message);
        setLogAdded(!logAdded);
        handleClose();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsLogVisible(false);
    }, 250);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 opacity-15 z-10 ${
          isLogVisible ? "" : "hidden"
        }`}
        onClick={() => handleClose()}
      />
      <div
        className={`fixed w-fit z-50 top-[15%] left-1/2 ${
          isVisible ? " animate-slideIn" : "animate-slideOut"
        }`}
      >
        <div className="bg-white pl-5 pr-6 py-5 shadow-md rounded-lg border ">
          <svg
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-7 top-7 cursor-pointer"
            onClick={handleClose}
          >
            <path
              d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
              fill="#1D1B20"
            />
          </svg>

          <h1 className="text-2xl font-semibold">Record Time Spent</h1>
          <ul className="mt-3 pl-4 flex flex-col items-start justify-start gap-1">
            <li className="flex items-end justify-start gap-4 mb-4">
              <p className="text-sm text-gray-500 mb-[2px]">Task Name</p>
              <p className="text-lg text-violet-800 font-semibold">
                {task ? task?.task_name : null}
              </p>
            </li>
            <li className="flex items-end justify-between gap-10 w-full">
              <div className="relative w-full">
                <p className="text-violet-800 mb-1">Date</p>
                <style>
                  {`
                     /* Hide the default calendar icon */
                     input[type="date"]::-webkit-calendar-picker-indicator {
                       display: none;
                    }
                     /* Remove the inner spin button on some browsers */
                     input[type="date"]::-webkit-inner-spin-button {
                       display: none;
                    }
                       input[type="date"]:focus {
                       outline: none; /* Removes default browser focus outline */
                    }
                  `}
                </style>
                <input
                  id="datepicker-title"
                  type="date"
                  className="bg-gray-50 border border-violet-800 text-gray-900 text-sm rounded-lg focus:ring-violet-800 focus:border-violet-800 block w-full pl-4 py-2 appearance-none pr-10 cursor-pointer"
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  onClick={(e) => e.target.showPicker()}
                />
                <div
                  className="absolute top-10 right-3 flex items-center cursor-pointer"
                  onClick={() =>
                    document.getElementById("datepicker-title").showPicker()
                  }
                >
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
              </div>
              <div className="flex items-center justify-start gap-3 mb-2">
                <input
                  type="checkbox"
                  className="w-6 h-4 rounded-sm cursor-pointer"
                  defaultChecked={isBillable}
                  onClick={handleCheckboxClick}
                  style={{ accentColor: "#6D28D9" }}
                />
                <p className="text-violet-800 whitespace-nowrap">
                  Mark as Billing
                </p>
              </div>
            </li>
            <li className="flex items-center gap-10">
              <div className="flex items-end gap-4">
                <div>
                  <p className="text-violet-800 mb-1">
                    Start Time <span className="text-xs">(24h format)</span>
                  </p>
                  <div className="relative">
                    <input
                      id="time-picker"
                      type="time"
                      className="w-32 pl-10  py-2 rounded-lg border border-violet-800 border-opacity-50 text-gray-900 text-sm appearance-none cursor-pointer focus:outline-none"
                      value={startTime}
                      onChange={handleStartChange}
                      onClick={(e) =>
                        e.target.showPicker && e.target.showPicker()
                      }
                    />
                    {/* <input
                      className="absolute right-4 top-[11px] w-7 text-sm text-center focus:border-transparent focus:ring-0 focus:outline-none"
                      value={startTime.split(" ")[1]}
                      onChange={(e) => {
                        const value = e.target.value.toUpperCase();
                        if (value === "AM" || value === "PM") {
                          setStartTime(`${startTime.split(" ")[0]} ${value}`);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                          e.preventDefault();
                          const currentPeriod = startTime.split(" ")[1];
                          const newPeriod =
                            currentPeriod === "AM" ? "PM" : "AM";
                          setStartTime(
                            `${startTime.split(" ")[0]} ${newPeriod}`
                          );
                        }
                      }}
                    /> */}
                  </div>
                </div>
                <div>
                  <p className="text-violet-800 mb-1">
                    End Time <span className="text-xs">(24h format)</span>
                  </p>
                  <div>
                    <div className="relative">
                      <input
                        id="time-picker"
                        type="time"
                        className="pl-10 py-2 rounded-lg border border-violet-800 border-opacity-50 text-gray-900 text-sm appearance-none cursor-pointer focus:outline-none"
                        value={endTime}
                        onChange={handleEndChange}
                        onClick={(e) =>
                          e.target.showPicker && e.target.showPicker()
                        }
                        min={startTime}
                      />
                      {/* <input
                        className="absolute right-4 top-[11px] w-7 text-sm text-center"
                        value={endTime.split(" ")[1]}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase();
                          if (value === "AM" || value === "PM") {
                            setEndTime(`${endTime.split(" ")[0]} ${value}`);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                            const currentPeriod = endTime.split(" ")[1];
                            const newPeriod =
                              currentPeriod === "AM" ? "PM" : "AM";
                            setEndTime(`${endTime.split(" ")[0]} ${newPeriod}`);
                          }
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-violet-800 mb-2">Spent Time</p>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-center justify-end">
                    <input
                      type="number"
                      className="w-16 h-11 rounded-lg border border-violet-800 border-opacity-50 text-gray-900 cursor-pointer focus:outline-none text-center"
                      value={spentHours}
                      onChange={(e) => handleSpentHours(e)}
                      min="0"
                      max="8"
                    />
                    <span className="text-[10px] text-gray-500">Hours</span>
                  </div>
                  <div className="flex flex-col items-center justify">
                    <input
                      type="number"
                      className="w-16 h-11 rounded-lg border border-violet-800 border-opacity-50 text-gray-900 cursor-pointer focus:outline-none text-center"
                      value={spentMinutes}
                      onChange={(e) => handleSpentMinutes(e)}
                      min="00"
                      max="59"
                    />
                    <span className="text-[10px] text-gray-500">Minutes</span>
                  </div>
                </div>
              </div>
            </li>
            <li className="w-full">
              <textarea
                rows={5}
                className="w-full border rounded-lg text-gray-700 text-sm border-violet-800 border-opacity-60 focus:outline-none p-2 mt-1"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Description..."
              />
            </li>
            <li className="flex items-center justify-end w-full mt-5">
              <div className="flex gap-5">
                <button
                  onClick={handleClose}
                  className="px-6 hover:bg-gray-200 text-sm py-2 rounded-lg shadow-md text-center border border-violet-800"
                >
                  Cancel
                </button>
                <button
                  className="bg-violet-800 border hover:bg-violet-900 border-violet-800 flex items-center justify-center text-white gap-2 px-6 py-2 rounded-lg
              shadow-md text-sm font-semibold"
                  onClick={SubmitLogTime}
                >
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6407 0.954736C11.8608 0.753569 12.1537 0.642356 12.4576 0.644563C12.7615 0.646771 13.0526 0.762226 13.2695 0.966567C13.4864 1.17091 13.6121 1.44815 13.6202 1.7398C13.6282 2.03144 13.5179 2.31468 13.3125 2.52974L7.07816 10.0147C6.97096 10.1256 6.84157 10.2145 6.69774 10.2763C6.55391 10.338 6.39858 10.3713 6.24105 10.3741C6.08352 10.3769 5.92702 10.3492 5.7809 10.2926C5.63479 10.236 5.50206 10.1517 5.39066 10.0447L1.25628 6.07574C1.14115 5.97274 1.0488 5.84854 0.98475 5.71054C0.9207 5.57254 0.886259 5.42357 0.883483 5.27252C0.880707 5.12147 0.909652 4.97142 0.968591 4.83134C1.02753 4.69126 1.11526 4.56401 1.22654 4.45718C1.33781 4.35035 1.47037 4.26613 1.61629 4.20955C1.76221 4.15297 1.9185 4.12518 2.07585 4.12785C2.2332 4.13051 2.38837 4.16358 2.53212 4.22506C2.67587 4.28655 2.80525 4.37521 2.91253 4.48574L6.18441 7.62524L11.611 0.987736L11.6407 0.954736Z"
                      fill="white"
                    />
                  </svg>
                  {isLoading ? "Loading.." : "Log Time"}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LogTimeModal;
